import React, { useState } from "react";
import Popup from "../../../Components/Popups/Popup";
import CustomTextfield from "../../Widgets/CustomTextfield";
import CustomForm from "../../../Components/CustomForm/CustomForm";
import { Button } from "@mui/material";
import Loading from "../../Widgets/Loading";
import ApiResult from "../../Widgets/ApiResult";
import {
  add_new_branch,
  update_branch,
} from "../../../Services/Manager/manager_services";
import { useSelector } from "react-redux";
import CustomSelector from "../../Widgets/CustomSelector";
import { getStatus } from "../../../Constants/DataaseValues";
import ShortNote from "Components/ShortNote/ShortNote";

function UpdateBranch({ closePopup, branch }) {
  const token = useSelector((state) => state.token);

  const [name, setName] = useState(branch.name);
  const [address, setAddress] = useState(branch.address);
  const [status, setStatus] = useState(branch.branch_status);
  const [email, setEmail]= useState(branch.email)

  const [isLoading, setIsLoading] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong, try again!");
  //   console.log(branch.status)

  const handelSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const param = {
        name: name,
        address: address,
        id: branch.id,
        status: status,
        email:email
      };
      const res = await update_branch(token, param);
      setIsLoading(false);
      closePopup(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 409 || err.response?.status === 401) {
        setErrorMsg(err.response?.data.msg);
      } else {
        setErrorMsg("Couldn't update branch, try again.");
      }
      setIsError(true);
    }
  };

  return (
    <div>
      {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        />
      )}
      <Popup title={"Update Branch"}>
        <CustomForm
          handleSubmit={(e) => handelSubmit(e)}
          button={
            <div>
              <Button
                type="submit"
                disabled={
                  String(name).trim() == "" ||
                  String(address).trim() == "" ||
                  (String(name).trim() === branch.name &&
                    String(address).trim() === branch.address &&
                status === branch.branch_status  && 
                email === branch.email
                )
                }
              >
                <p>Update</p>
              </Button>
              <Button onClick={() => closePopup(true)}>
                <p>Close</p>
              </Button>
            </div>
          }
        >
          <CustomTextfield
            label={"Branch Name"}
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder={"branch name"}
            required={true}
          />
          <CustomTextfield
            label={"Branch Address"}
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            placeholder={"branch address"}
            required={true}
          />
          <CustomSelector
            required={true}
            label={"Status"}
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            menuItems={[
              { id: 0, name: "Suspended" },
              { id: 1, name: "Active" },
            ]}
          />

          <div className="span-2">
            <ShortNote text={"The below email will be used to get new reservations"} />
            
            
          </div>
          <div className="span-2">
            
            
            <CustomTextfield
            label={"Branch Email"}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder={"branch emil"}
            // required={true}
          />
          </div>
         
        </CustomForm>
      </Popup>
    </div>
  );
}

export default UpdateBranch;
