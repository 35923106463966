import Loginpage from "./Scenes/LogInPage/Loginpage";
import "./index.css";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CompaniesPage from "./Scenes/Admin/CompaniesPage/CompaniesPage";
import Ads from "./Scenes/Admin/Ads/Ads";
import Users from "./Scenes/Admin/Users/Users";
import Services from "./Scenes/Admin/Services/Services";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch } from "react-redux";
import DashoboardTemplate from "./Components/Dashboard/DashoboardTemplate";
import Branches from "./Scenes/Manager/Branches/Branches";
import Items from "./Scenes/Manager/Items/Items";
import Category from "./Scenes/Manager/Category/Category";
import Menu from "./Scenes/Menu/Menu";
import Preloader from "./Scenes/Preloader/Preloader";
import { GlobalStyles } from "@mui/material";
import WorkInProgress from "./Scenes/Widgets/WorkInProgress";
import HandleOrders from "./Scenes/Cashier/HandleOrders/HandleOrders";
import TelegramIntegration from "./Scenes/TelegramIntegration/TelegramIntegration";
import CompanyServices from "Scenes/Manager/Services/CompanyServices";
import PaymentMethod from "Scenes/Manager/PaymenteMethod/PaymentMethod";
import NoMobileVersion from "Components/NoMobileVersion/NoMobileVersion";
import Reservation from "Scenes/Cashier/Reservation/Reservation";
import ManagerDashboard from "Scenes/Manager/Dashoboard/Dashboard";

function App() {
  const dispatch = useDispatch();
  const isAuth = Boolean(useSelector((state) => state.token));
  const user = useSelector((state) => state.user);
  const [isPreloaded, setIsPreloaded] = useState(false);

  useEffect(() => {
    // window.addEventListener("beforeunload", handleOnbeforeunload);
    handleOnbeforeunload();
  }, []);
  const handleOnbeforeunload = () => {
    console.log("app reloaded");
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GlobalStyles
          styles={{
            scrollbarColor: "#6b6b6b #2b2b2b",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: "#959595", //scroll box color
              borderRadius: 8,
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              minHeight: 24,
              backgroundColor: "#6b6b6b", //scroll bar color

              border: "2px solid #6b6b6b",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                border: "1px solid rgb(75, 91, 127)",
                backgroundColor: "#6b6b6f", //scroll bar color when hovered
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#2b2b2b",
            },
          }}
        />
        <Router>
          <NoMobileVersion />

          {!isPreloaded && (
            <Preloader
              setIsLoaded={() => {
                setIsPreloaded(true);
              }}
            />
          )}
          <Routes>
            <Route
              path="/"
              element={
                isAuth && user && user.role && user.role == 2 ? (
                  <Navigate to="/companies" />
                ) : isAuth && user && user.role && user.role == 1 ? (
                  <Navigate to="/manager-dashboard" />
                ) : isAuth && user && user.role && user.role == 0 ? (
                  <Navigate to="/handle-orders" />
                ) : isAuth && user && user.role && user.role == 3 ? (
                  <Navigate to="/companies" />
                ) : (
                  <div className="mm">
                    <Loginpage />
                  </div>
                )
              }
            />
            <Route
              path="/companies"
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <CompaniesPage />{" "}
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/ads"
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <Ads />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/users"
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <Users />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/services"
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <Services />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/roles"
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <WorkInProgress />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/company-activities"
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    {/* <Menu /> */}
                    <WorkInProgress />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            {/* //////////////////// MANAGERS */}
            
            <Route
              path={`/manager-dashboard`}
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <ManagerDashboard/>
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path={`/branches`}
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <Branches />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/items"
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <Items />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/categories"
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <Category />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/company-services"
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <CompanyServices />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/company-payment"
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <PaymentMethod />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/menu"
              element={
                isAuth ? (
                  // <DashoboardTemplate>
                  <Menu />
                ) : (
                  // </DashoboardTemplate>
                  <Navigate to="/" />
                )
              }
            />

            {/*///////////////////////////////////////////////////////////////// CASHIERS PAGES */}
            <Route
              path="/handle-orders"
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <HandleOrders />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path={"/reservations"}
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <Reservation />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path={"/cashier-dashboard"}
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <WorkInProgress />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path={"/cashier-orders"}
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <WorkInProgress />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path={"/manage-items"}
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <WorkInProgress />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path={"/telegram"}
              element={
                isAuth ? (
                  <DashoboardTemplate>
                    <TelegramIntegration />
                  </DashoboardTemplate>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
          </Routes>
        </Router>
      </LocalizationProvider>
    </>
  );
}

export default App;
