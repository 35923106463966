import MainBodyStructure from "../../../Components/MainBody/MainBodyStructure";
import { fetch_company_branches } from "../../../Services/Admin/admin_services";
import React, { useEffect, useState } from "react";
import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useSelector } from "react-redux";
import { fetch_all_users } from "../../../Services/Admin/admin_services";
import { getStatus } from "../../../Constants/DataaseValues";
import {
  add_new_item,
  fetch_company_items,
} from "../../../Services/Manager/manager_services";
import ApiResult from "../../Widgets/ApiResult";
import AddNewItem from "./AddNewItem";
import DeleteConfirm from "./DeleteConfirm";
import { URLs } from "../../../api/URLs";
import UpdateItem from "./UpdateItem";
import UpdateStatus from "../../../Components/UpdateStatus/UpdateStatus";
import ItemsFlex from "./ItemsFlex";
import PublishIcon from "@mui/icons-material/Publish";
import axios from "../../../api/axios";
import Loading from "../../Widgets/Loading";
function Items() {
  const token = useSelector((state) => state.token);
  const company_id = useSelector((state) => state.user.company_id);
  const [branches, setItem] = useState([]);

  //ADD NEW CUSTOMER VARIABLES
  const [isAddItemSuccessful, setIsAddItemSuccessful] = useState(false);
  const [isAddItemFormVisible, setIsAddItemFormVisible] = useState(false);
  const [updatingItem, setUpdatingItem] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);

  const [isFail, setIsFail] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong");

  const openAddNewForm = async (open, cancel) => {
    if (open) {
      setIsAddItemFormVisible(true);
    } else {
      setIsAddItemFormVisible(false);
      if (!cancel) {
        refreshData();
      }
    }
  };
  // UPDATE USER PROFIL
  const [isUpdatingItemSuccessful, setIsUpdateItemSuccessful] = useState(false);
  const [isUpdateItemFormVisible, setIsUpdateItemFormVisible] = useState(false);
  const [isDeleteSuccesful, setIsDeleteSuccesful] = useState(false);
  const [isDeletConfirmVisible, setIsDeleteConfirmVisible] = useState(false);

  const openUpdateForm = async (open, cancel) => {
    if (open) {
      setIsUpdateItemFormVisible(true);
    } else {
      setIsUpdateItemFormVisible(false);
      if (!cancel) {
        refreshData();
      }
    }
  };
  const openDeleteForm = async (open, cancel) => {
    if (open) {
      setIsDeleteConfirmVisible(true);
    } else {
      setIsDeleteConfirmVisible(false);
      if (!cancel) {
        refreshData();
      }
    }
  };

  //LOAD COMPANIES FROM STATE OR API
  const loadItems = async () => {
    setIsEmpty(false);
    try {
      const itemList = await fetch_company_items(token, company_id);
      console.log(itemList, "itemlsit");

      // const itemModeled = itemCell(itemList);
      setItem(itemList);
      // setItem(itemModeled);
      if (!itemList.length) {
        setIsEmpty(true);
      }
    } catch (err) {
      console.log(err, "error in fetch users function");
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 409 || err.response?.status === 401) {
        setErrorMsg(err.response?.data.msg);
      } else {
        setErrorMsg("Couldn't get items, try again.");
      }
      setIsFail(true);
      setItem(null);
    }
  };

  // REFRESH TABLE DATA
  const refreshData = () => {
    setItem([]);
    loadItems();
  };
  useEffect(() => {
    loadItems();
  }, []);

  useEffect(() => {
    if (isAddItemSuccessful || isUpdatingItemSuccessful || isDeleteSuccesful) {
      setItem([]);
      loadItems();
    }
  }, [isAddItemSuccessful, isUpdatingItemSuccessful || isDeleteSuccesful]);

  const handleUpdateItemStatus = (id) => {
    //  setItem( branches.map((item)=>{
    //     if(item.status.id == id){
    //       item.status.status = !item.status.status
    //     }
    //     return item
    //   }))
    console.log(id);
  };

  const [draft, setDraft] = useState([]);

  const handleDraft = (item) => {
    console.log(item, 123);
    setDraft([...draft, item]);
    setIsAddItemFormVisible(false);
  };
  console.log(draft, 99);

  const [isLoading, setIsLoading] = useState(false);

  const handlePublishDrafts = async () => {
    const fails = [];
    const success = [];
    setIsLoading(true);
    await Promise.all(
      draft.map(async (param) => {
        if (!param.withImage) {
          const paramNew = {
            name: param.name,
            category: param.category_id,
            branches: param.branches,
            image: param.image,
            variationTypes: param.variationTypes,
            addonTypes: param.addonTypes,
            description: param.description,
          };
          console.log(paramNew);

          await add_new_item(token, paramNew)
            .then((response) => {
              success.push(param);
            })
            .catch((err) => {
              fails.push(param);

              console.log(err);
              if (!err?.response) {
                setErrorMsg("No Server Response");
              } else if (
                err.response?.status === 409 ||
                err.response?.status === 401
              ) {
                setErrorMsg(err.response?.data.msg);
              } else {
                setErrorMsg("Add Item Failed");
              }
              setIsFail(true);
            });
        } else {
          const formData = new FormData();
          formData.append("file", param.imageData);
          formData.append("upload_preset", "ffblgmbh");
          await axios
            .post(
              "https://api.cloudinary.com/v1_1/dsskh3fao/image/upload",
              formData
            )
            .then(async (cld_response) => {
              const image = cld_response.data.url;
              const paramNew = {
                name: param.name,
                category: param.category_id,
                branches: param.branches,
                image: image,
                variationTypes: param.variationTypes,
                // variationTypes: variationTypes,
                addonTypes: param.addonTypes,
                description: param.description,
              };
              console.log(paramNew);

              await add_new_item(token, paramNew);
              success.push(param);
            })
            .catch((err) => {
              fails.push(param);

              console.log(err);
              if (!err?.response) {
                setErrorMsg("No Server Response");
              } else if (
                err.response?.status === 409 ||
                err.response?.status === 401
              ) {
                setErrorMsg(err.response?.data.msg);
              } else {
                setErrorMsg("Add Item Failed");
              }
              setIsFail(true);
            });
        }
      })
    );

    setDraft(fails);
    refreshData();
    setIsLoading(false);
  };

  const removeDraft = (draftItem) => {
    const newDraft = draft.filter((dft) => dft.id !== draftItem);
    setDraft(newDraft);
  };

  const handleUpdateDraftItem = (updatedDraft) => {
    const newDraft = draft.map((dft) => {
      if (dft.id == updatedDraft.id) {
        return updatedDraft;
      } else {
        return dft;
      }
    });
    setDraft(newDraft);
    setIsUpdateItemFormVisible(false);
  };
  return (
    <div>
      {isLoading && <Loading />}
      {isFail && (
        <ApiResult
          message={errorMsg}
          handleClose={() => {
            setIsFail(false);
          }}
          isSuccess={false}
        ></ApiResult>
      )}
      {isAddItemSuccessful && (
        <ApiResult
          message={`Item Added Succesfully`}
          handleClose={() => {
            setIsAddItemSuccessful(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isUpdatingItemSuccessful && (
        <ApiResult
          message={`Item Updated Succesfully`}
          handleClose={() => {
            setIsUpdateItemSuccessful(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}

      {isUpdateItemFormVisible && (
        <UpdateItem
          closePopup={(close) => openUpdateForm(false, close)}
          item={JSON.parse(JSON.stringify(updatingItem))}
          updateDraftHandler={handleUpdateDraftItem}
          originalImage={updatingItem.imageData}
        />
      )}
      {isDeletConfirmVisible && (
        <DeleteConfirm
          closePopup={(close) => openDeleteForm(false, close)}
          row={updatingItem}
          api={URLs.DELETE_ITEM}
        />
      )}
      {isAddItemFormVisible && (
        <AddNewItem
          closePopup={() => {
            setIsAddItemFormVisible(false);
          }}
          //  closePopup={(close) => openAddNewForm(false, close)}
          handleDraft={handleDraft}
          draftsId={draft.map((dtf) => dtf.id)}
        />
      )}
      <div className="h-screen overflow-hidden w-full">
        <div
          className="top-stick-section flex justify-between p-8 sticky bg-white z-10 w-full"
          style={{
            borderBottom: "3px solid  rgb(247, 246, 246)",
            // width:"calc( 100% - 4rem )",
            height: "7rem",
            // display:"block"
          }}
        >
          <div className="screen-title">
            <h4 className="text-black font-bold text-xl">Items</h4>
            <p>Items available at the company</p>
          </div>
          <div className="top-bar-buttons flex">
            <Button
              style={{
                background: "rgb(62, 62, 216)",
                color: "white",
                marginRight: "10px",
                paddingRight: ".8rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // gap: "5px",
                height: "fit-content",
              }}
              onClick={() => {
                openAddNewForm(true);
              }}
            >
              <AddIcon /> Add New Item
            </Button>
            <Button
              style={{
                background: "rgb(62, 62, 216)",
                disabled: "rgb(62, 62, 100)",
                color: "white",
                marginRight: "10px",
                paddingRight: ".8rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // gap: "5px",
                height: "fit-content",
              }}
              onClick={() => {
                handlePublishDrafts();
              }}
              sx={{
                "&.Mui-disabled": {
                  background: "#eaeaea !important",
                  color: "#c0c0c0",
                  
                },
              }}
              disabled={!draft.length}
            >
              <PublishIcon /> Publish
            </Button>
            <Button
              style={{
                background: "rgb(62, 62, 216)",
                color: "white",
                marginRight: "10px",
                // paddingLeft: "1rem",
                paddingRight: ".8rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // gap: "5px",
                height: "fit-content",
              }}
              onClick={() => {
                refreshData();
              }}
            >
              {<RefreshIcon />} REFRESH
            </Button>
          </div>
        </div>
        <ItemsFlex
          items={[...branches, ...draft]}
          reloadItems={() => {}}
          editFunction={(item) => {
            setUpdatingItem(item);
            openUpdateForm(true);
          }}
          deleteFunction={(item) => {
            setUpdatingItem(item);
            openDeleteForm(true);
          }}
          removeDraft={removeDraft}
        ></ItemsFlex>
      </div>
    </div>
  );
}

export default Items;

const header = (updateFunction, deleteFunction, reloadItems) => {
  return [
    {
      field: "id",
      headerName: "NO.",
      width: 300,
      headerClassName: "super-app-theme--header",
      // width: 75,
      align: "right",
      headerAlign: "right",
      flex: 0.7,
    },
    {
      field: "image",
      headerName: " ",
      headerClassName: "super-app-theme--header",
      width: 150,

      align: "center",
      padding: "10px",
      headerAlign: "center",
      renderCell: (params) => (
        <img
          className="table-image"
          //   src={e.image}
          src={
            String(params.value) != ""
              ? params.value
              : "https://res.cloudinary.com/dhe0yelid/image/upload/v1692110703/anbessa_8e7b499497.png"
          }
          alt=""
        />
      ),
    },
    {
      field: "name",
      headerName: "NAME",
      headerClassName: "super-app-theme--header",
      // width:200,
      flex: 1,

      align: "left",
      padding: "10px",
      headerAlign: "center",
    },
    // {
    //   field: "address",
    //   headerName: "ADDRESS",
    //   // width: 300,
    //   flex: 1,

    //   headerClassName: "super-app-theme--header",
    //   // flex: 1,
    //   // width: 200,
    //   align: "left",

    //   headerAlign: "center",
    // },
    {
      field: "status",
      headerName: "STATUS",
      headerClassName: "super-app-theme--header",
      width: 150,

      align: "left",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {/* <FormGroup>
            <FormControlLabel
              control={<Switch checked={true} onChange={() => {}} />}
              // label="Status"
            />
          </FormGroup> */}
          <UpdateStatus
            api={URLs.UPDATE_ITEM_STATUS}
            row={params.value}
            reloadData={reloadItems}
          />
        </>
      ),
    },
    {
      field: "action",
      headerName: "MANAGE",
      minWidth: 100,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            style={{
              color: "rgb(62, 62, 216)",
              marginRight: "10px",
              boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",

              padding: "0",
              width: "10px",
            }}
            onClick={() => {
              updateFunction(params.value);
            }}
          >
            <EditNoteOutlinedIcon />
          </Button>

          <Button
            style={{
              // border: "2px solid coral",
              color: "coral",
              // boxShadow: "2px 3px 2px 0px rgba(128, 126, 126, 0.4)",
              boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",

              padding: "0",
              width: "10px !important",
            }}
            onClick={() => {
              deleteFunction(params.value);
            }}
          >
            <ClearOutlinedIcon />
          </Button>
        </>
      ),
    },
  ];
};

const itemCell = (branches) => {
  console.log(branches);
  const newBranchModel = [];
  branches.map((e) => {
    newBranchModel.push({
      id: branches.indexOf(e) + 1,
      image: e.image,
      name: e.name,
      category: e.category,
      status: e,

      action: e,
    });
  });
  return newBranchModel;
};

{
  /* <MainBodyStructure
        isEmpty={isEmpty}
        title={"Items"}
        subtitle={"Currently available items."}
        buttons={[
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              openAddNewForm(true);
            }}
          >
            <AddIcon /> Add New Item
          </Button>,
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              // paddingLeft: "1rem",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              refreshData();
            }}
          >
            {<RefreshIcon />} REFRESH
          </Button>,
        ]}
        data={{
          header: header(
            (item) => {
              setUpdatingItem(item);

              openUpdateForm(true);
            },
            (item) => {
              setUpdatingItem(item);

              openDeleteForm(true);
            },
            handleUpdateItemStatus
          ),
          data: branches,
        }}
        // data={usersz?usersz.length?{header:header, data:itemCell(usersz)}: {header:header, data:[]}: {header:header, data:[]}}
      /> */
}
