import React, { useState } from "react";
import Popup from "../../Components/Popups/Popup";
import axios from "../../api/axios";
import ShortNote from "../../Components/ShortNote/ShortNote";
import { useSelector } from "react-redux";
import CustomTextfield from "../Widgets/CustomTextfield";
import { Button } from "@mui/material";
import ApiResult from "../Widgets/ApiResult";
import Loading from "../Widgets/Loading";

function UpdateTelergramIntegrationNumber({close, id, isManager}) {
  const [otp, setOtp] = useState("");
  const [number, setNumber] = useState("");
  const token = useSelector((state) => state.token);
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError]= useState(false)
 const [errorMsg, setErrorMsg]= useState("Network Error")

  const handleUpdate = async () => {
    const param = isManager? {
      otp: otp,
      manager_phone: number,
      branch_id:id
    }:
    {
      otp: otp,
      cashier_phone: number,
      branch_id:id
    }
    setIsLoading(false)
    await axios.post(
      "/api/update/telegram-number",
      JSON.stringify(param),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    ).then((res)=>{
        setIsLoading(false)
        close()
    }).catch((e)=>{
        setIsLoading(false)
        setIsError(true)
    })
  };
  return (
    <div>
        {isError && (
        <ApiResult
          message={errorMsg}
          handleClose={() => {
            setIsError(false);
          }}
        ></ApiResult>
      )}

      {isLoading&&
      <Loading/>
      }
      
      <Popup title={"Update Telegram Integration Number"}>
        <ShortNote
          text={
            "Find telegram bot by the link and start conversation and input your phone number and the short code given after conversation on the bot is started: https://t.me/qranbessatestbot"
          }
        ></ShortNote>
        <div className="p-2"></div>
        <CustomTextfield
          label={"Phone Number"}
          onChange={(e) => setNumber(e.target.value)}
          value={number}
          placeholder={"Phone number"}
          required={true}
        />
        <div className="p-2"></div>
        <CustomTextfield
          label={"Short Code"}
          onChange={(e) => setOtp(e.target.value)}
          value={otp}
          placeholder={"Short Code"}
          required={true}
        />
        <div className="flex justify-end">
        <Button onClick={() => close(true)}>
                <p>Close</p>
              </Button>
        <Button onClick={handleUpdate} disabled={String(otp).trim()===""|| String(number).trim()===""}>Update</Button></div>

      </Popup>
    </div>
  );
}

export default UpdateTelergramIntegrationNumber;
