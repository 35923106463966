import React from "react";

function ItemCard({ item, children, isDraft }) {
  return (
    <div
      className="flex border-grey rounded-md border w-fit p-4 h-28 justify-between gap-2"
      style={{ backgroundColor: isDraft ? "rgb(240, 240, 240)" : "white" }}
    >
      <div className="image w-20 h-full overflow-hidden mr-2 flex justify-center items-center rounded">
        <img src={item.image} alt="" className="w-full h-auto" />
      </div>
      <div className="info flex flex-col items-start justify-between">
        <p >{item.name}{item.status===-1?<span className="italic text-red-400">{`(draft)`}</span>:null}</p>
        <div className="actions flex items-center flex-1">{children}</div>
      </div>
    </div>
  );
}

export default ItemCard;
