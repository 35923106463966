import React, { useRef, useState } from "react";
import OrderCard from "./OrderCard";
import {
  clearCanceledOrders,
  updateOrderStatus,
} from "../../../../Services/Cashier/cashier_services";
import { useSelector } from "react-redux";
import ApiResult from "../../../Widgets/ApiResult";
import ClearCanceledOrders from "./ClearCanceledOrders";
import { useReactToPrint } from "react-to-print";

function OrdersTab({ orders, handleUpdateList, isLoading, tabLocation }) {
  const [isSuccess, setIsSuccess] = useState(false);
  var componentRef = useRef(null);

  const token = useSelector((state) => state.token);
  const isPrinting = useSelector((state) => state.isPrinting);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const updateOrder = async (id, status, ticketRef) => {
    isLoading(true);
    await updateOrderStatus(token, id, status)
      .then((response) => {
        handleUpdateList(response.msg);
        isLoading(false);
        setIsSuccess(true);
        componentRef = ticketRef;
        if (isPrinting == 1) {
          handlePrint();
        }
      })
      .catch((e) => {
        console.log(e, "error in cancel order");
        isLoading(false);
      });
  };
  const [clearSuccessful, setClearSuccessful] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong");
  const handleClearOrders = async () => {
    isLoading(true);

    await clearCanceledOrders(token)
      .then((response) => {
        isLoading(false);
        handleUpdateList(response.msg);
        setClearSuccessful(true);
      })
      .catch((e) => {
        isLoading(false);

        setIsFail(true);
      });
  };
  return (
    <div
      className="flex flex-col overflow-y-scroll"
      style={{ height: "strech" }}
    >
      {isSuccess && (
        <ApiResult
          message={"Order Updated"}
          handleClose={() => {
            setIsSuccess(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {clearSuccessful && (
        <ApiResult
          message={"Cleared Succussfully"}
          handleClose={() => {
            setClearSuccessful(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isFail && (
        <ApiResult
          message={"Failed to clear orders"}
          handleClose={() => {
            setIsFail(false);
          }}
        ></ApiResult>
      )}
      {tabLocation == 6 ? (
        <>
          <ClearCanceledOrders
            handleClearOrders={handleClearOrders}
            length={orders.length}
          />
        </>
      ) : null}
      {orders.map((e) => (
        <OrderCard
          order={e}
          updateOrder={updateOrder}
          tabLocation={tabLocation}
        />
      ))}
    </div>
  );
}

export default OrdersTab;
