import { Button } from "@mui/material";
import CustomForm from "Components/CustomForm/CustomForm";
import Popup from "Components/Popups/Popup";
import React from "react";

function DetailReservation({ reservation, closePopup }) {
  return (
    <div>
      <Popup title={"Detail"}>
        <CustomForm
          style={{ width: "100% !important" }}
          width={"100%"}
          // style={{ width:" 100%" }}
          button={
            <div>
              <Button onClick={() => closePopup(true)}>
                <p>Close</p>
              </Button>
            </div>
          }
        >
            <div className="flex flex-col">
          <p>{reservation.name}</p>

          <p>Phone:{reservation.phone} </p>
          <p>Number of guests: {reservation.guests}</p>
          <p>Date: {reservation.date}</p>
          <p>Time: {reservation.time}</p>
          <p>Message: {reservation.message}</p>
          </div>
        </CustomForm>
      </Popup>
    </div>
  );
}

export default DetailReservation;
