import { getRole, getStatus } from "../Constants/DataaseValues";

export class User {
  private id: number;
  private firstName: string;
  private lastName: string;
  private username: string;
  private role: string;
  private roleId: number;
  private status: string;
  private statusId: number;
  private companyName: string;
  private companyId: number;
  private branchName: string;
  private branchId: number;
  private phone: string;
  constructor(
    id: number,
    first_name: string,
    last_name: string,
    username: string,
    role: number,
    status: number,
    company_name: string,
    company_id: number,
    branch_name: string,
    branch_id: number,
    phone: string,
  
  ) {
    this.id = id;
    this.firstName = first_name;
    this.lastName = last_name;
    this.username = username;
    this.role = getRole[role];
    this.status = getStatus[status];
    this.companyName = company_name;
    this.companyId = company_id;
    this.branchName = branch_name;
    this.branchId = branch_id;
    this.phone = phone;
    this.roleId = role;
    this.statusId = status;
  }

  getName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
