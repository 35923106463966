import React from "react";
import "./CustomForm.css";

function CustomForm({ children, button, handleSubmit, width }) {
  return (
    <div>
      <form className="add-new-company-form" onSubmit={handleSubmit}>
        <div className="form-body">{children}</div>
        <div className="footer">
          {/* <Button type="submit"
            disabled={
            name.trim() =="" ||
              tin.trim() ==""
              ||
              branch_address.trim() ==""
              ||
              branch_name.trim() ==""
              ||
              !fieldValues
            }
            >Add</Button>
            <Button onClick={() => closePopup(true)}>
              <p>Close</p>
            </Button> */}
            <div className="buttons"> {button}</div>
         
        </div>
      </form>
    </div>
  );
}

export default CustomForm;
