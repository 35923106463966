import { Padding } from "@mui/icons-material";
import { Container } from "@mui/material";
import { ClockIcon } from "@mui/x-date-pickers";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
function MenuHeader({
  image,
  opening,
  closing,
  address,
  primaryColor,
  secondaryColor,
  tabLocation,
}) {
  return (
    <div
      className="menu-header"
      style={{ background: "orange", color: secondaryColor }}
    >
      <Container sx={{ backgroundColor: primaryColor }}>
        <div className="top" style={{ padding: "1rem 0" }}>
          <div className="left">
            <LocationOnIcon className="" /> {address} <span>|</span>
            <ClockIcon /> {String(opening).slice(0,5)}-{String(closing).slice(0,5)}
          </div>
          <div className="right">
            <SearchIcon />
          </div>
        </div>
        <hr
          style={{
            borderTop: `0.5px solid ${secondaryColor}`,
            borderBottom: "none",
            borderLeft: "none",
            borderRight: "none",
          }}
        />
        <div className="bottom h-28" style={{ padding: ".2rem 0" }}>
          <div
            className="left h-5/6"
            style={{ borderRadius: "10px", margin: "1rem 0px" }}
          >
            <img
              className="h-full"
              src={image}
              alt="company-logo"
              height={"70px"}
              style={{ borderRadius: "10px" }}
            />
          </div>
          <div className="right">
            <ul
              className="menu-links"
              style={{
                display: "flex",
                gap: "1.5rem",
              }}
            >
              <li
                style={{
                  textDecoration: tabLocation == "menu" && "underline" + secondaryColor,
                  textDecorationThickness: "2px",
                  // textUnderlineOffset: "8px",
                }}
              >
                MENU
              </li>
              <li>RESERVATION</li>
              <li>CART</li>
              <li>LOGIN</li>
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MenuHeader;
