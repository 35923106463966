import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  CircularProgress,
  Typography,
} from "@mui/material";
// import { DropzoneArea } from 'material-ui-dropzone';
import {
  add_company_category,
  add_new_item,
  fetch_company_category,
} from "../../../Services/Manager/manager_services";
import Loading from "../../Widgets/Loading";
import ApiResult from "../../Widgets/ApiResult";
import React, { useEffect, useState } from "react";
import Popup from "../../../Components/Popups/Popup";
import CustomTextfield from "../../Widgets/CustomTextfield";
import CustomForm from "../../../Components/CustomForm/CustomForm";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { fetch_company_branches } from "../../../Services/Admin/admin_services";
import "./AddNewItem.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { DropzoneArea } from "material-ui-dropzone";
import { Image } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import CustomSelector from "../../Widgets/CustomSelector";
import axios from "axios";
import ShortNote from "../../../Components/ShortNote/ShortNote";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import PricingTable from "./PricingTable";
import eat from "../../../Assets/Images/eat.png";

function AddNewItem({ closePopup, handleDraft, draftsId }) {
  const token = useSelector((state) => state.token);
  const companyId = useSelector((state) => state.user.company_id);

  const [name, setName] = useState("");
  const [fieldValues, setFieldValues] = useState(null);
  const [preview, setPreview] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong, try again!");

  const [variationLabel, setVariationLabel] = useState("Variations");
  const [addonLabel, setAddonLabel] = useState("Addons");
  const [description, setDescription] = useState("")

  // PRICING
  const [variationTypes, setVariationTypes] = useState([
    { id: 0, name: "Variation 1" },
  ]);
  const [addonTypes, setAddonTypes] = useState([]);



  // GET BRANCHES FROM STATE/REDUX
  const branchesFromState = useSelector((state) => state.branches);

  //LOAD COMPANIES FROM STATE OR API
  const loadBranch = async () => {
    try {
      if (branchesFromState.length) {
        console.log(branchesFromState, "Truth");
        setBranches(branchesFromState);
      } else {
        const branchList = await fetch_company_branches(token, companyId);

        setBranches(branchList);
      }

      // console.log(userslist,userp , "poisonwho")
    } catch (e) {
      console.log(e, "error in fetch users function");
    }
  };

  const handleImageChnange = (e) => {
    // console.log(e);
    if (e.target.files.length) {
      setPreview(e.target.files[0]);
      setFieldValues(URL.createObjectURL(e.target.files[0]));
    } else {
      setPreview("");
      setFieldValues(null);
    }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();

    const param = {
      id:draftsId.length? Math.max.apply(
        Math,
        draftsId
      )+1:1,
      name: name,
      category_id: categoryId,
      branches: selectedBranches,
      image: fieldValues,
      variationTypes: variationTypes,
      // variationTypes: variationTypes,
      addonTypes: addonTypes,
      description:description,
      status: -1,
      imageData: preview,
      withImage: true,
    };
    console.log(param, 1234);
    handleDraft(param);

    // try {
    //   setIsLoading(true);
    //   const formData = new FormData();
    //   formData.append("file", preview);
    //   formData.append("upload_preset", "ffblgmbh");
    //   axios
    //     .post(
    //       "https://api.cloudinary.com/v1_1/dsskh3fao/image/upload",
    //       formData
    //     )
    //     .then(async (cld_response) => {
    //       const image = cld_response.data.url;
    //       const param = {
    //         name: name,
    //         category: categoryId,
    //         branches: selectedBranches,
    //         image: image,
    //         variationTypes: variationTypes,
    //         // variationTypes: variationTypes,
    //         addonTypes: addonTypes,
    //       };
    //       console.log(param);

    //       const res = await add_new_item(token, param);
    //       setIsLoading(false);
    //       closePopup(false);
    //     })
    //     .catch((err) => {
    //       setIsLoading(false);

    //       console.log(err);
    //       if (!err?.response) {
    //         setErrorMsg("No Server Response");
    //       } else if (
    //         err.response?.status === 409 ||
    //         err.response?.status === 401
    //       ) {
    //         setErrorMsg(err.response?.data.msg);
    //       } else {
    //         setErrorMsg("Add Company Failed");
    //       }
    //       setIsError(true);
    //     });
    // } catch (err) {
    //   setIsLoading(false);

    //   console.log(err);
    //   if (!err?.response) {
    //     setErrorMsg("No Server Response");
    //   } else if (err.response?.status === 409 || err.response?.status === 401) {
    //     setErrorMsg(err.response?.data.msg);
    //   } else {
    //     setErrorMsg("Add Company Failed");
    //   }
    //   setIsError(true);
    // }
  };

  const handelSubmitWithoutImage = async (e) => {
    e.preventDefault();

    // const image = cld_response.data.url;
    const param = {
      id:draftsId.length? Math.max.apply(
        Math,
        draftsId
      )+1:1,
      name: name,
      category_id: categoryId,
      branches: selectedBranches,
      image:
        "http://res.cloudinary.com/dsskh3fao/image/upload/v1702385632/swslbomyhtixstjuzm1q.jpg",
      variationTypes: variationTypes,
      // variationTypes: variationTypes,
      addonTypes: addonTypes,
      status: -1,
      withImage: false,
      description:description,

    };

    handleDraft(param);
    // console.log(param);

    //  await add_new_item(token, param)
    //   .then((response) => {
    //     setIsLoading(false);
    //     closePopup(false);
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);

    //     console.log(err);
    //     if (!err?.response) {
    //       setErrorMsg("No Server Response");
    //     } else if (
    //       err.response?.status === 409 ||
    //       err.response?.status === 401
    //     ) {
    //       setErrorMsg(err.response?.data.msg);
    //     } else {
    //       setErrorMsg("Add Company Failed");
    //     }
    //     setIsError(true);
    //   });

    // res()
  };
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [selectedBranches, setSelectedBranches] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );

    // if (selectedBranches.indexOf(value[0]) > -1) {
    //   setSelectedBranches([...selectedBranches, ...value]);
    //   console.log(selectedBranches);
    // }
    console.log(value, "vales ");
    // if (
    //   value.includes("Check All") &&
    //   !isAllSelected &&
    //   selectedBranches.length !== branches.length
    // ) {
    //   console.log("all checked");
    //   setIsAllSelected(true);
    //   setSelectedBranches(branches.map((e) => e.name));
    // } else if (value.includes("Check All") && isAllSelected) {
    //   setIsAllSelected(false);
    //   console.log("all uncchecked");

    //   setSelectedBranches([]);
    // } else {
    //   const newSelects = [...value];
    //   console.log(newSelects, "one checked");

    //   if (newSelects.indexOf("Check All") > -1) {
    //     const remaining = newSelects.splice(newSelects.indexOf("Check All"), 1);
    //   }

    //   if (
    //     value.indexOf("Check All") > -1 &&
    //     selectedBranches.length == branches.length + 1
    //   ) {
    //     setIsAllSelected(false);
    //     setSelectedBranches([]);
    //   }
    //   setIsAllSelected(false);
    // }

    // get list of ids and map through new lsit and find whose index is -1 in the selected list
    if (value) {
      const selectedId = selectedBranches.map((f) => f.id);
      const valueId = value.map((f) => f.id);
      var newBranch = [];

      for (var i = 0; i < value.length; i++) {
        if (selectedId.indexOf(value[i].id) == -1) {
          newBranch.push(value[i]);
        }
      }
      // console.log(newBranch, "new branch");
      // add that branch with default value for present variaty types and addons
      if (newBranch.length) {
        for (var i = 1; i < variationTypes.length; i++) {
          newBranch[0].variation.push({
            id: variationTypes[i].id,
            // name: variationTypes[i],
            price: 0,
          });
        }
        for (var i = 0; i < addonTypes.length; i++) {
          newBranch[0].addon.push({
            id: addonTypes[i].id,
            name: addonTypes[i],
            price: 0,
          });
        }
        setSelectedBranches([...selectedBranches, newBranch[0]]);
      }

      // get list of ids and map throught old list find whose index is -1 in the new list
      var removedBranch = [];
      for (var i = 0; i < selectedId.length; i++) {
        if (valueId.indexOf(selectedId[i]) == -1) {
          removedBranch.push(selectedId[i]);
        }
      }
      console.log(removedBranch, "removed branch");
      var copy = [...selectedBranches];

      // remove that branch from the selected lsit
      if (removedBranch.length) {
        copy.splice(selectedId.indexOf(removedBranch[0]), 1);

        setSelectedBranches(copy);
      }
    }
  };

  const [branchPrice, setBranchPrice] = useState([]);

  // FETCH BRANCHES
  const [branchId, setBranchId] = useState("");
  const [branches, setBranches] = useState([]);

  const getBranches = async () => {
    try {
      if (branchesFromState.length) {
        console.log(branchesFromState, "Truth");
        setBranches(branchPriceModel(branchesFromState));
      } else {
        const branchesData = await fetch_company_branches(token, companyId);
        const modelled = branchPriceModel(branchesData);
        setBranches(modelled);
      }
    } catch (error) {
      setErrorMsg("Couldn't load company branches, try again.");
      setIsError(true);
    }
  };
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const getCategories = async () => {
    try {
      const categoryList = await fetch_company_category(token, companyId);
      setCategories(categoryList);
      console.log(categoryList, "category list");
    } catch (err) {
      setErrorMsg("Couldn't load company categories, try again.");
      setIsError(true);
    }
  };

  useEffect(() => {
    getBranches();
    getCategories();
  }, []);

  const handleAddVariation = () => {
    const updatedBranch = selectedBranches.map((e) => {
      console.log(
        Math.min.apply(
          Math,
          variationTypes.map((e) => e.id)
        )
      );
      console.log(variationTypes, "variationtypess");
      e.variation.push({
        id:
          Math.max.apply(
            Math,
            variationTypes.map((e) => e.id)
          ) + 1,
        // name: Math.max(variationTypes.map(e=>e.id))+1,
        name: variationLabel + " " + (e.variation.length + 1),
        price: 0,
      });
      return e;
    });
    setVariationTypes([
      ...variationTypes,
      {
        id:
          Math.max.apply(
            Math,
            variationTypes.map((e) => e.id)
          ) + 1,
        name: variationLabel + " " + (variationTypes.length + 1),
      },
    ]);
    setSelectedBranches(updatedBranch);
  };
  const handleRemoveVariation = (id) => {
    if (variationTypes.length > 1) {
      var updatedBranch = [...selectedBranches];

      updatedBranch.map((e) => {
        e.variation.splice(variationTypes.map((e) => e.id).indexOf(id), 1);
        return e;
      });
      var header = [...variationTypes];
      header.splice(variationTypes.map((e) => e.id).indexOf(id), 1);
      setVariationTypes(header);
      setSelectedBranches(updatedBranch);
    }
  };
  const handleAddAddon = (index) => {
    const updatedBranch = selectedBranches.map((e) => {
      e.addon.push({
        id: !addonTypes.length
          ? 0
          : Math.max.apply(
              Math,
              addonTypes.map((e) => e.id)
            ) + 1,

        name: addonLabel + " " + (e.variation.length + 1),
        price: 0,
      });
      return e;
    });
    setAddonTypes([
      ...addonTypes,
      {
        id: !addonTypes.length
          ? 0
          : Math.max.apply(
              Math,
              addonTypes.map((e) => e.id)
            ) + 1,
        name: addonLabel + " " + (addonTypes.length + 1),
      },
    ]);
    setSelectedBranches(updatedBranch);
  };
  const handleRemoveAddon = (id) => {
    var updatedBranch = [...selectedBranches];

    updatedBranch.map((e) => {
      e.addon.splice(addonTypes.map((e) => e.id).indexOf(id), 1);
      return e;
    });
    var header = [...addonTypes];
    header.splice(addonTypes.map((e) => e.id).indexOf(id), 1);
    setAddonTypes(header);
    setSelectedBranches(updatedBranch);
  };
  const editVariationPrice = (branch, index, price) => {
    console.log(selectedBranches);
    const updatedBranch = selectedBranches.map((e) => {
      if (e.id == branch) {
        e.variation.map((f) => {
          if (f.id == index) {
            f.price = price;
          }
          return f;
        });
      }

      return e;
    });
    setSelectedBranches(updatedBranch);
  };
  const editAddonPrice = (branch, index, price) => {
    const updatedBranch = selectedBranches.map((e) => {
      if (e.id == branch) {
        e.addon.map((f) => {
          // if (e.addon.indexOf(f) == index) {
          if (f.id == index) {
            f.price = price;
          }
          return f;
        });
      }

      return e;
    });
    setSelectedBranches(updatedBranch);
  };
  const editVariationName = (id, name) => {
    var header = [...variationTypes];
    header.map((e) => {
      if (e.id == id) e.name = name;
      return e;
    });
    setVariationTypes(header);
    // const updatedBranch = selectedBranches.map((e) => {
    //   e.variation.map((f) => {
    //     f.name = name;

    //     return f;
    //   });

    //   return e;
    // });
    // setSelectedBranches(updatedBranch);
  };
  const editAddonName = (id, name) => {
    var header = [...addonTypes];
    header.map((e) => {
      if (e.id == id) e.name = name;
      console.log(e);
      return e;
    });
    setAddonTypes(header);
    // const updatedBranch = selectedBranches.map((e) => {
    //   e.addon.map((f) => {
    //     f.name = name;

    //     return f;
    //   });

    //   return e;
    // });
    // setSelectedBranches(updatedBranch);
  };
  return (
    <div>
      {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        />
      )}
      <Popup title={"Add new item"} fullWidth>
        <CustomForm
          handleSubmit={(e) =>
            fieldValues ? handelSubmit(e) : handelSubmitWithoutImage(e)
          }
          button={
            <div>
              <Button
                type="submit"
                onClick={() => console.log(selectedBranches)}
                disabled={
                  String(name).trim() == "" ||
                  String(categoryId).trim() == "" ||
                  !selectedBranches.length
                }
              >
                <p>Add</p>
              </Button>
              <Button onClick={() => closePopup(true)}>
                <p>Close</p>
              </Button>
            </div>
          }
        >
          <CustomTextfield
            label={"Name"}
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder={"item name"}
            required={true}
          />

          <CustomSelector
            required={true}
            label={"Category"}
            value={categoryId}
            onChange={(e) => {
              setCategoryId(e.target.value);
            }}
            menuItems={categories.map((e) => ({ id: e.id, name: e.name }))}
          />

          <div className="span-2">

          <CustomTextfield
            multiline
            label={"Description"}
            value={description}
            onChange={(e) => {
              if (e.target.value.length < 400)
                setDescription(e.target.value);
            }}
            //  required
          />
          </div>
          <div className="span-2">
            <Box
              // {...getRootProps()}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"1rem"}
              border={`1px solid #D9D9D9`}
              // borderRadius={".5rem"}
              p="1rem"
              sx={{
                // "&:hover": { cursor: "pointer" }
                backgroundColor: "#FCFCFC",
                minHeight: 100,
              }}
            >
              <div className="upload-arrow-icon">
                <ArrowUpwardRoundedIcon
                  sx={{ fontSize: "2.5rem", fontWeight: "600" }}
                />
              </div>
              <label for="file-upload" class="custom-file-upload">
                {fieldValues ? "Change Image" : "Upload Image"}
              </label>
              <input
                height={""}
                id="file-upload"
                type="file"
                onChange={handleImageChnange}
                accept="image/png, image/jpeg, image/jpg"
                // value={preview.name}
              />
              {/* {value}
               */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {!fieldValues && (
                  <>
                    <p style={{ fontSize: ".8rem", color: "black" }}>
                      File format:{" "}
                      <span style={{ color: "grey" }}>
                        {"(JPEG, JPG and PNG)"}
                      </span>
                    </p>
                    <p style={{ fontSize: ".8rem", color: "black" }}>
                      Max size: <span style={{ color: "grey" }}>{"10Mb"}</span>
                    </p>
                  </>
                )}
                {fieldValues && (
                  <img
                    width={"140px"}
                    // height={"100px"}
                    src={fieldValues && fieldValues}
                    alt="priview"
                  />
                )}
              </div>
            </Box>
            {/* )}
            </Dropzone> */}
          </div>
          <div className="span-2">
            <ShortNote
              text={
                "Choose branches of the company this new Item will be available in and fill their respective prices."
              }
            />
          </div>
          <div className="text-field span-2">
            <FormControl
              fullWidth
              // margin="normal"
            >
              <InputLabel id="demo-multiple-checkbox">{"Branches"}</InputLabel>

              <Select
                id="demo-multiple-checkbox"
                multiple
                value={selectedBranches}
                // options={companies}
                // getOptionLabel={(option) => option.name}
                label={"Branches"}
                onChange={handleChange}
                placeholder={"Available in branches"}
                renderValue={(selected) =>
                  selected.map((e) => e.name).join(", ")
                }
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch}>
                    <Checkbox checked={selectedBranches.indexOf(branch) > -1} />
                    <ListItemText primary={branch.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <PricingTable
            editVariationLabel={(x) => {
              setVariationLabel(x);
            }}
            editAddonLabel={(x) => {
              setAddonLabel(x);
            }}
            editAddonName={editAddonName}
            editVariationName={editVariationName}
            removeVariation={(x) => handleRemoveVariation(x)}
            removeAddon={handleRemoveAddon}
            addAddon={handleAddAddon}
            addVariation={handleAddVariation}
            handleVariationPrice={editVariationPrice}
            handleAddonPrice={editAddonPrice}
            variationLabel={variationLabel}
            addonLabel={addonLabel}
            branches={selectedBranches}
            variationList={variationTypes}
            addonList={addonTypes}
            isUpdating={false}
          />
        </CustomForm>
      </Popup>
    </div>
  );
}

export default AddNewItem;

const branchPriceModel = (branch) => {
  const model = [];

  if (branch && branch.length) {
    branch.map((e) => {
      model.push({
        id: e.id,
        name: e.name,
        variation: [{ id: 0, name: "Variaty 1", price: 0 }],
        addon: [],
      });
    });
  }

  return model;
};

const variationList = [
  "Variation 1",
  "Variation 2",
  "Variation 1",
  "Variation 2",
];

const addonList = ["Addon 1", "Addon 2"];
