import React, { useRef } from "react";
import {
  orderMethod,
  paymentMethod,
} from "../../../../Constants/DataaseValues";
import { Button } from "@mui/material";

function OrderCard({ order, updateOrder, tabLocation }) {
  const componentRef = useRef();

  return (<>
    <div className="min-w-fit max-w-xl flex flex-col  mx-4 my-2 p-4 gap-2 border rounded text-sm">
    <div className="flex flex-col gap-2  p-4 "  ref={componentRef}>
      <div className=" border-t-2 border-blue-200 w-2/4 text-xs">
        <span className="">Order ID -</span> {order.id}
      </div>
      <div className="flex justify-between">
        <p>Requested by</p>
        <p>{order.customer_name}</p>
      </div>
      <div className="flex justify-between">
        <p>Phone</p>
        <p>{order.phone}</p>
      </div>
      <div className="flex justify-between">
        <p>Product Name</p>
        <div className="flex flex-col items-end">
          <div className="flex ">
            <p>
              {order.item_name}
              {"("}
              {order.variation_name}
              {") - ETB "}
              {order.variation_price}
            </p>
          </div>
          {order.addons.map((e) => {
            return (
              <>
                <p>
                  {e.name}
                  {" - ETB "}
                  {e.price}
                </p>
              </>
            );
          })}
        </div>
      </div>
      <div className="flex justify-between">
        <p>Quantity</p>
        <p>{order.quantity}</p>
      </div>
      <div className="flex justify-between">
        <p>Order Method</p>
        <p>
          {order.order_method}
          {"("}
          {order.order_location}
          {")"}
        </p>
      </div>
      <div className="flex justify-between">
        <p>Payement Method</p>
        <p>{paymentMethod[order.payment_method]}</p>
      </div>
      <div className="flex justify-between">
        <p>Special Instruction</p>
        <i className="text-red-500">{order.special_instruction}</i>
      </div>
      <div className="flex justify-between">
        <p>Total Payment</p>
        <p>
          {"ETB "}
          {order.variation_price +
            order.addons
              .map((f) => f.price)
              .reduce((partialSum, a) => partialSum + a, 0)}
        </p>
      </div>
      </div>
      {tabLocation == 0 ? (
        <div className="flex justify-end">
          <Button
            onClick={() => {
              updateOrder(order.id, 1, componentRef);
              
            }}
          >
            Add to Kitchen
          </Button>
          <Button onClick={() => updateOrder(order.id, 6)}>Cancel Order</Button>
        </div>
      ) : tabLocation == 1 ? (
        <div className="flex justify-end">
          <Button onClick={() => updateOrder(order.id, 7)}>
            Add to Completed
          </Button>
          <Button onClick={() => updateOrder(order.id, 6)}>Cancel Order</Button>
        </div>
      ) : tabLocation == 6 ? (
        <div className="flex justify-end">
          <Button onClick={() => updateOrder(order.id, 1)}>
            Restore to Kitchen
          </Button>
        </div>
      ) : (
        <div className="flex justify-end">
          <Button onClick={() => updateOrder(order.id, 6)}>Cancel Order</Button>
        </div>
      )}
    
    </div>
    </>
  );
}

export default OrderCard;
