import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import "./ShortNote.css"

function ShortNote({text}) {
  return (
    <div className='short-note'>
        <ErrorOutlineIcon/>
        <p>{text}</p>
    </div>
  )
}

export default ShortNote