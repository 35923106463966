import MainBodyStructure from "../../../Components/MainBody/MainBodyStructure";
import { fetch_company_branches } from "../../../Services/Admin/admin_services";
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useDispatch, useSelector } from "react-redux";
import { fetch_all_users } from "../../../Services/Admin/admin_services";
import { getStatus } from "../../../Constants/DataaseValues";
import AddNewBranch from "./AddNewBranch";
import ApiResult from "../../Widgets/ApiResult";
import UpdateBranch from "./UpdateBranch";
import { setBranches as brancher } from "../../../state";
import { Link } from "react-router-dom";
import { URLs } from "../../../api/URLs";

// import AddNewUser from "./AddNewUser";
// import ApiResult from "../../Widgets/ApiResult";
// import UpdateUserProfile from "./UpdateUserProfile";
function Branches() {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.user.company_id);

  const token = useSelector((state) => state.token);
  const company_id = useSelector((state) => state.user.company_id);
  const [branches, setBranches] = useState([]);

  //ADD NEW CUSTOMER VARIABLES
  const [isAddBranchSuccessful, setIsAddBranchSuccesful] = useState(false);
  const [isAddBranchFormVisible, setIsAddBranchFormVisible] = useState(false);
  const [updatingBranch, setUpdatingBranch] = useState(null);

  // GET BRANCHES FROM STATE/REDUX
  const branchesFromState = useSelector((state) => state.branches);

  const openAddNewForm = async (open, cancel) => {
    if (open) {
      setIsAddBranchFormVisible(true);
    } else {
      setIsAddBranchFormVisible(false);
      if (!cancel) {
        setIsAddBranchSuccesful(true);
      }
    }
  };
  // UPDATE USER PROFIL
  const [isUpdateBranchSuccesful, setIsUpdateBranchSuccesful] = useState(false);
  const [isUpdateBranchFormVisible, setIsUpdateBranchFormVisible] =
    useState(false);

  const openUpdateForm = async (open, cancel) => {
    if (open) {
      setIsUpdateBranchFormVisible(true);
    } else {
      setIsUpdateBranchFormVisible(false);
      if (!cancel) {
        setIsUpdateBranchSuccesful(true);
      }
    }
  };

  //LOAD COMPANIES FROM STATE OR API
  const loadBranch = async () => {
    try {
    

        const branchList = await fetch_company_branches(token, company_id);

        const branchModeled = branchCell(branchList);

        setBranches(branchModeled);

        dispatch(
          brancher({
            branches: branchList,
          })
        );
      

      // console.log(userslist,userp , "poisonwho")
    } catch (e) {
      console.log(e, "error in fetch users function");
    }
  };

  // REFRESH TABLE DATA
  const refreshData = () => {
    setBranches([]);
    loadBranch();
  };
  useEffect(() => {
    loadBranch();
  }, []);

  useEffect(() => {
    if (isAddBranchSuccessful || isUpdateBranchSuccesful) {
      setBranches([]);
      loadBranch();
    }
  }, [isAddBranchSuccessful, isUpdateBranchSuccesful]);
  return (
    <div>
      {isAddBranchSuccessful && (
        <ApiResult
          message={`Branch Added Succesfully`}
          handleClose={() => {
            setIsAddBranchSuccesful(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isUpdateBranchSuccesful && (
        <ApiResult
          message={`Branch Updated Succesfully`}
          handleClose={() => {
            setIsUpdateBranchSuccesful(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}

      {isUpdateBranchFormVisible && (
        <UpdateBranch
          closePopup={(close) => openUpdateForm(false, close)}
          branch={updatingBranch}
        />
      )}
      {isAddBranchFormVisible && (
        <AddNewBranch closePopup={(close) => openAddNewForm(false, close)} />
      )}
      <MainBodyStructure
        title={"Branches"}
        subtitle={"Currently registered branches of you company."}
        buttons={[
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              openAddNewForm(true);
            }}
          >
            <AddIcon /> Add New Branch
          </Button>,
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              // paddingLeft: "1rem",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              refreshData();
            }}
          >
            {<RefreshIcon />} REFRESH
          </Button>,
        ]}
        data={{
          header: header((user) => {
            setUpdatingBranch(user);

            openUpdateForm(true);
          }, company_id),
          data: branches,
        }}
        // data={usersz?usersz.length?{header:header, data:branchCell(usersz)}: {header:header, data:[]}: {header:header, data:[]}}
      />
    </div>
  );
}

export default Branches;

const header = (updateFunction, company) => {
  return [
    {
      field: "id",
      headerName: "NO.",
      width: 300,
      headerClassName: "super-app-theme--header",
      // width: 75,
      align: "right",
      headerAlign: "right",
      flex: 0.7,
    },
    {
      field: "name",
      headerName: "NAME",
      headerClassName: "super-app-theme--header",
      // width:200,
      flex: 1,

      align: "left",
      padding: "10px",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <a
                target="blank"
                href={`${URLs.MENU_ADDRESS}/menu?company=${company}&amp;branch=${params.value.branch_id}`}
            style={{
              color: "#4C9818",
              fontStyle: "italic",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            {params.value.name}
          </a>
        </>
      ),
    },
    {
      field: "address",
      headerName: "ADDRESS",
      // width: 300,
      flex: 1,

      headerClassName: "super-app-theme--header",
      // flex: 1,
      // width: 200,
      align: "left",

      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "STATUS",
      headerClassName: "super-app-theme--header",
      width: 150,

      align: "left",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <p style={{ color: params.value == "Suspended" ? "red" : "black" }}>
            {params.value}
          </p>
        </>
      ),
    },
    {
      field: "action",
      headerName: "MANAGE",
      minWidth: 100,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            style={{
              color: "rgb(62, 62, 216)",
              marginRight: "10px",
              boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",

              padding: "0",
              width: "10px",
            }}
            onClick={() => {
              updateFunction(params.value);
            }}
          >
            <EditNoteOutlinedIcon />
          </Button>

          {/* <Button
            style={{
              border: "2px solid coral",
              color: "coral",
              boxShadow: "2px 3px 2px 0px rgba(128, 126, 126, 0.4)",
              padding: "0",
              width: "10px !important",
            }}
            onClick={() => {
              buttonFunctions[3](params.value);
              buttonFunctions[2]();
            }}
          >
            <ClearOutlinedIcon />
          </Button> */}
        </>
      ),
    },
  ];
};

const branchCell = (branches) => {
  const newBranchModel = [];
  branches.map((e) => {
    newBranchModel.push({
      id: branches.indexOf(e) + 1,
      name: e,
      address: e.address,
      status: getStatus[e.branch_status],
      action: e,
    });
  });
  return newBranchModel;
};
