import React from "react";

function WorkInProgress() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        width={"50%"}
        // height={"100%"}
        src="https://img.freepik.com/free-vector/hand-drawn-construction-background_23-2147734520.jpg?t=st=1700044708~exp=1700045308~hmac=b4b79e586a4b7f0a9db17e5f008dc8f1754e7837526adce56e1b1c7ce6f16249"
      />
    </div>
  );
}

export default WorkInProgress;
