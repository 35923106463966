import { Button } from "@mui/material";
import MainBodyStructure from "Components/MainBody/MainBodyStructure";
import { fetch_reservations } from "Services/Cashier/cashier_services";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DetailReservation from "./DetailReservation";
import VisibilityIcon from "@mui/icons-material/Visibility";
function Reservation() {
  const token = useSelector((state) => state.token);
  const [reservation, setReservation] = useState([]);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);

  const getReservations = async () => {
    await fetch_reservations(token)
      .then((res) => {
        setReservation(rows(res));
        if (!res.length) {
          setIsEmpty(true);
        }
      })
      .catch((e) => {
        // TO:DO handle errors
        console.log(e, 444);
      });
  };

  useEffect(() => {
    getReservations();
  }, []);
  return (
    <div>
      {/* <div
        className="top-stick-section flex justify-between p-8 sticky bg-white z-10 w-full border"
        style={{
          borderBottom: "3px solid  rgb(247, 246, 246)",
          height: "7rem",
        }}
      >
        <div className="screen-title">
          <h4 className="text-black font-bold text-xl">Reservations</h4>
          <p>Requested Reservations</p>
        </div>
      </div> */}
      {isDetailVisible ? (
        <DetailReservation
          reservation={selectedRow}
          closePopup={() => setIsDetailVisible(false)}
        />
      ) : null}
      <MainBodyStructure
        isEmpty={isEmpty}
        title={"Reservations"}
        subtitle={"Currently requested reservations."}
        buttons={[]}
        data={{
          header: header((row) => {
            setSelectedRow(row);
            setIsDetailVisible(true);
          }),
          data: reservation,
        }}
        // data={usersz?usersz.length?{header:header, data:branchCell(usersz)}: {header:header, data:[]}: {header:header, data:[]}}
      />
    </div>
  );
}

export default Reservation;

const header = (showDetail) => {
  return [
    {
      field: "id",
      headerName: "NO.",
      width: 300,
      headerClassName: "super-app-theme--header",
      // width: 75,
      align: "right",
      headerAlign: "right",
      flex: 0.7,
    },
    {
      field: "name",
      headerName: "NAME",
      headerClassName: "super-app-theme--header",
      // width:200,
      flex: 1,

      align: "left",
      padding: "10px",
      headerAlign: "center",
    },
    {
      field: "phone",
      headerName: "PHONE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      align: "left",

      headerAlign: "center",
    },
    {
      field: "timestamp",
      headerName: "RESERVED DATE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      align: "left",

      headerAlign: "center",
    },

    {
      field: "guests",
      headerName: "RESERVED FOR",
      flex: 1,
      headerClassName: "super-app-theme--header",
      align: "left",

      headerAlign: "center",
    },
    {
      field: "datetime",
      headerName: "RESERVED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      align: "left",

      headerAlign: "center",
    },

    {
      field: "action",
      headerName: "MANAGE",
      minWidth: 100,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            style={{
              color: "rgb(62, 62, 216)",
              marginRight: "10px",
              boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",

              padding: "0",
              width: "10px",
            }}
            onClick={() => {
              showDetail(params.value);
            }}
          >
            {/* <EditNoteOutlinedIcon /> */}
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
  ];
};

const rows = (row1) => {
  const modeledRow = [];
  row1.map((e) => {
    modeledRow.push({
      id: row1.indexOf(e) + 1,
      name: e.name,
      phone: e.phone,
      timestamp: e.timestamp,
      guests: e.guests,
      datetime: e.date + "/" + e.time,
      action: e,
    });
  });
  return modeledRow;
};
