import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import CustomForm from "Components/CustomForm/CustomForm";
import Popup from "Components/Popups/Popup";
import ShortNote from "Components/ShortNote/ShortNote";
import ApiResult from "Scenes/Widgets/ApiResult";
import CustomTextfield from "Scenes/Widgets/CustomTextfield";
import Loading from "Scenes/Widgets/Loading";
import { updateChapaKey } from "Services/Manager/manager_services";
import React, { useState } from "react";
import { useSelector } from "react-redux";

function SecretKeyOption({ secretKeyApi, closePopup }) {
  const token = useSelector((state) => state.token);

  const [secretKey, setSecretKey] = useState(secretKeyApi);
  const [ownKey, setOwnKey] = useState(secretKeyApi);
  const [isLoading, setIsLoading] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong, try again!");

  const handelSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    await updateChapaKey(token, ownKey ? secretKey : null)
      .then((res) => {
        setIsLoading(false);
        closePopup();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        if (!err?.response) {
          setErrorMsg("No Server Response");
        } else if (
          err.response?.status === 409 ||
          err.response?.status === 401
        ) {
          setErrorMsg(err.response?.data.msg);
        } else {
          setErrorMsg("Error, try again.");
        }
        setIsError(true);
      });
  };
 
  return (
    <div>
      {isLoading && <Loading full={true} />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        />
      )}
      <Popup title={"Update Gateway Key"}>
        <CustomForm
          handleSubmit={(e) => handelSubmit(e)}
          button={
            <div>
              <Button
                type="submit"
                disabled={
                  secretKey === secretKeyApi ||
                  (ownKey && secretKey.length < 8)
                }
              >
                <p>Update</p>
              </Button>
              <Button onClick={() => closePopup(true)}>
                <p>Close</p>
              </Button>
            </div>
          }
        >
          <div className="span-2">
            <ShortNote
              text={
                "If no secret key is provided, all payment will go through QR Anbessa account."
              }
            />
          </div>

          <div className="span-2">
            <div className="flex gap-4 items-center justify-start  w-full">
              <p>Own Chapa Merchant Account:</p>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={ownKey}
                      onChange={(e) => {
                        if(ownKey){
                            setSecretKey("")
                        }
                        
                        setOwnKey(!ownKey);
                      }}
                    />
                  }
                />
              </FormGroup>

              <CustomTextfield
                label={"Secret Key"}
                value={secretKey}
                onChange={(e) => setSecretKey(e.target.value)}
                required={ownKey}
                // type={"password"}
                disabled={!ownKey}
              />
            </div>
          </div>
        </CustomForm>
      </Popup>
    </div>
  );
}

export default SecretKeyOption;
