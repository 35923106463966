import React, { useEffect, useState } from "react";
import "./ApiResult.css";
import CloseIcon from "@mui/icons-material/Close";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CheckIcon from "@mui/icons-material/Check";

function ApiResult({ message, children, handleClose, isSuccess }) {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(()=>{
    setTimeout(()=>{
      closeWidget()
    },5000)
  },[])
  useEffect(()=>{
    setTimeout(
      ()=>{
        setIsVisible(true)
      },70
    )
  },[])
  const closeWidget=()=>{
    setIsVisible(false)
    setTimeout(
      ()=>{
    handleClose();},400)

  }
  return (
    <>
      {/* {(isVisible || visible) && ( */}
      <div className={isVisible?"api-result":"api-result invisible"}>
        <div className="msg">
          <div className="icon">{isSuccess ? <CheckIcon style={{ color: "green" }}/> : <ReportProblemIcon  style={{ color: "red" }}/>}</div>
          <p className="message">{message}</p>

        </div>
        <CloseIcon onClick={closeWidget} className="cursor" />

      </div>
      {/* )} */}
    </>
  );
}

export default ApiResult;
