import { URLs } from "../../api/URLs";
import axios from "../../api/axios";

export const fetch_items = async (token, params) => {
  const response = await axios
    .get(URLs.FETCH_ITEMS, {
      params: { company: params.company, branch: params.branch },
      headers: { "Content-Type": "application/json", "x-auth-token": token },
      withCredentials: true,
    })
    .then((res) => {
      console.log(res?.data?.companies, "stuff");
      return res?.data?.msg
    //   .map(
    //     (e) =>
    //       new Company(
    //         e.id,
    //         e.name,
    //         e.tin,
    //         e.image,
    //         e.opening_hour,
    //         e.closing_hour,
    //         e.branches.map((f) => new Branch(f.id, f.name, f.address, f.status))
    //       )
    //   );
    })
    .catch((error) => {
      console.log(error, "error getting company and branches");
      throw error;
    });
  return response;
};
