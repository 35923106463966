import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import CustomForm from "Components/CustomForm/CustomForm";
import Popup from "Components/Popups/Popup";
import ApiResult from "Scenes/Widgets/ApiResult";
import CustomTextfield from "Scenes/Widgets/CustomTextfield";
import Loading from "Scenes/Widgets/Loading";
import { update_service } from "Services/Admin/admin_services";
import React, { useState } from "react";
import { useSelector } from "react-redux";

function UpdateService({ closePopup, service }) {
  const token = useSelector((state) => state.token);

  const [name, setName] = useState(service.name);
  const [locationType, setLocationType] = useState(service.location_type);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong, try again!");

  const handelSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const param = {
        name: name,
        id: service.id,
        locationType:locationType
      };
      await update_service(token, param);
      setIsLoading(false);
      closePopup(false);
    } catch (err) {
      setIsLoading(false);
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 409 || err.response?.status === 401) {
        setErrorMsg(err.response?.data.msg);
      } else {
        setErrorMsg("Couldn't add branch, try again.");
      }
      setIsError(true);
    }
  };

  return (
    <div>
      {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        />
      )}
      <Popup title={"Add new category"}>
        <CustomForm
          handleSubmit={(e) => handelSubmit(e)}
          button={
            <div>
              <Button
                type="submit"
                disabled={
                  String(name).trim() === "" &&
                  String(name).trim() === service.name&&
                  String(locationType).trim() === "" &&
                  String(locationType).trim() === service.location_type
                }
              >
                <p>Update</p>
              </Button>
              <Button onClick={() => closePopup(true)}>
                <p>Close</p>
              </Button>
            </div>
          }
        >
          <CustomTextfield
            label={"Name"}
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder={"service name"}
            required={true}
            span
          />
           <CustomTextfield
              label={"Location Type"}
              onChange={(e) => setLocationType(e.target.value)}
              value={locationType}
              placeholder={"location type"}
              required={true}
              span
            />
          
        </CustomForm>
      </Popup>
    </div>
  );
}

export default UpdateService;
