import { URLs } from "../../api/URLs";
import axios from "../../api/axios";
import { Company } from "../../Models/Company.ts";
import { User } from "../../Models/User.ts";
import qs from "qs";
import { Branch } from "../../Models/Branch.ts";
export const fetch_companies = async (token, offset, forSales) => {
  const response = await axios
    .get(forSales ? URLs.FETCH_COMPANIES_FOR_SALES : URLs.FETCH_COMPANIES, {
      params: { offset: offset },
      headers: { "Content-Type": "application/json", "x-auth-token": token },
      withCredentials: true,
    })
    .then((res) => {
      console.log(res?.data?.companies, "stuff");
      return res?.data?.companies.map(
        (e) =>
          new Company(
            e.id,
            e.name,
            e.tin,
            e.image,
            e.opening_hour,
            e.closing_hour,
            e.primary_color,
            e.secondary_color,
            
            e.branches.map((f) => new Branch(f.id, f.name, f.address, f.status, f.isPrinting)),
            e.isSalesManaged,
            e.is_vat,
            e.service_charge
          )
      );
    })
    .catch((error) => {
      console.log(error, "error getting company and branches");
      throw error;
    });
  return response;
};
export const delete_company = async (token, id, isSales) => {
  const response = await axios
    .delete(isSales? URLs.DELETE_COMPANY_FOR_SALES:URLs.DELETE_COMPANY, {
      params: { id: id },
      headers: { "Content-Type": "application/json", "x-auth-token": token },
      withCredentials: true,
    })
    .then((res) => {
      console.log(res, "delete succes");

      return res?.data?.msg.map(
        (e) =>
          new Company(
            e.id,
            e.name,
            e.tin,
            e.image,
            e.opening_hour,
            e.closing_hour
          )
      );
    })
    .catch((error) => {
      throw error;
    });
  return response;
};

export const update_company_profile = async (token) => {
  try {
    const response = await axios.get(
      URLs.FETCH_ADMIN_DATA,
      // JSON.stringify({ email, password }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    );

    const companies = response?.data?.company;
    //   dispatch(
    //     setCompanies({
    //       companies: [...companies],
    //     })
    //   );
    return companies;
  } catch (error) {
    return error;
  }
};

export const fetch_all_users = async (token, isSales) => {
  const response = await axios
    .get(isSales? URLs.FETCH_ALL_USERS_FOR_SALES: URLs.FETCH_ALL_USERS, {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
      withCredentials: true,
    })
    .then((res) => {
      // return res?.data?.msg.map((e) => new
      //   Company(e.id, e.name, e.tin, e.image, e.opening_hour, e.closing_hour)
      // );
      console.log(res, "axios call fetch users");
      return res?.data?.msg.map(
        (e) =>
          new User(
            e.id,
            e.first_name,
            e.last_name,
            e.username,
            e.role,
            e.status,
            e.company_name,
            e.company_id,
            e.branch_name,
            e.branch_id,
            e.phone
          )
      );
    })
    .catch((error) => {
      throw error;
    });
  return response;
};

export const add_new_users = async (token, params, isSales) => {
  console.log(params, "params in axios");
  const response = await axios
    .post(
     isSales? URLs.ADD_NEW_USER_FOR_SALES: URLs.ADD_NEW_USER,
      JSON.stringify({
        first_name: params.first_name,
        last_name: params.last_name,
        username: params.username,
        phone: params.phone,
        company_id: params.company_id,
        branch_id: params.branch_id,
        password: params.password,
        role: params.role,
      }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error add user");

      throw error;
    });
  return response;
};
export const update_users = async (token, params, isSales) => {
  console.log(params, "params in axios");
  const response = await axios
    .post(isSales? URLs.UPDATE_USER_PROFILE_FOR_SALES:
      URLs.UPDATE_USER_PROFILE,
      JSON.stringify({
        id: params.id,
        first_name: params.first_name,
        last_name: params.last_name,
        username: params.username,
        phone: params.phone,
        company_id: params.company_id,
        branch_id: params.branch_id,
        password: params.password,
        role: params.role,
        status: params.status,
      }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error add user");

      throw error;
    });
  return response;
};
export const check_username = async (token, username) => {
  const response = await axios
    .post(
      URLs.CHECK_USERNAME,
      JSON.stringify({
        username: username,
      }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      // return res?.data?.msg.map((e) => new
      //   Company(e.id, e.name, e.tin, e.image, e.opening_hour, e.closing_hour)
      // );
      return res?.data?.msg;
    })
    .catch((error) => {
      throw error;
    });
  return response;
};
export const fetch_company_branches = async (token, id) => {
  const response = await axios
    .get(URLs.FETCH_COMPANY_BRANCHS, {
      params: { id: id },
      headers: { "Content-Type": "application/json", "x-auth-token": token },
      withCredentials: true,
    })
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      throw error;
    });
  return response;
};

///////////////////// SERVICES

export const fetch_services = async (token) => {
  const response = await axios
    .get(URLs.FETCH_SERVICES, {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
      withCredentials: true,
    })
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      throw error;
    });
  return response;
};

export const add_new_service = async (token, params) => {
  const response = await axios
    .post(
      URLs.ADD_SERVICE,
      JSON.stringify({ name: params.name, locationType: params.locationType }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      throw error;
    });
  return response;
};
export const update_service = async (token, params) => {
  const response = await axios
    .post(
      URLs.UPDATE_SERVICE,
      JSON.stringify({
        id: params.id,
        name: params.name,
        locationType: params.locationType,
      }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      throw error;
    });
  return response;
};

export const update_service_status = async (token, params) => {
  const response = await axios
    .get(
      URLs.UPDATE_SERVICE_STATUS,
      JSON.stringify({ id: params.id, status: params.status }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      throw error;
    });
  return response;
};
export const delete_service = async (token, params) => {
  const response = await axios
    .get(URLs.DELETE_SERVICE, JSON.stringify({ id: params.id }), {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
      withCredentials: true,
    })
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      throw error;
    });
  return response;
};
