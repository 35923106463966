import UpdateStatus from "Components/UpdateStatus/UpdateStatus";
import { URLs } from "api/URLs";
import React from "react";

function ServiceCard({ title, branches, reloadData, service_id }) {
  return (
    <div className="service-card rounded border-gray-200 border flex flex-col px-4 py-4 w-1/3">
      <p className="font-bold">{title}</p>
      <hr className="w-full my-2" />
      <div className="flex flex-wrap">
        {branches.map((branch) => (
          <div className="flex justify-start items-center pr-8">
            <UpdateStatus
            isService={true}
            branch_id={branch.branch_id}
            service_id={service_id}
              api={
                branch.status
                  ? URLs.DEACTIVATE_SERVICE
                  : URLs.ACTIVATE_SERVICE
              }
              row={branch}
              reloadData={reloadData}
            />
            <p>{branch.branch_name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServiceCard;
