import React, { useEffect, useState } from "react";
import StatCard from "./StatCard";
import {
  Analytics,
  AttachMoney,
  Category,
  LocationCity,
  Money,
  MoneyOffRounded,
  Person,
  TableBar,
} from "@mui/icons-material";
import TwoStatCard from "./TwoStatCard";
import { useSelector } from "react-redux";
import Loading from "Scenes/Widgets/Loading";
import ApiResult from "Scenes/Widgets/ApiResult";
import { get_analytics, get_branch_for_analytics } from "Services/Manager/manager_services";
import CustomSelector from "Scenes/Widgets/CustomSelector";
import FourStatCard from "./FourStatCard";

function ManagerDashboard() {
  const token = useSelector((state) => state.token);
  const [time, setTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error, try again!");
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(0);
  const [data, setData] = useState(null);

  const getAnalytics = async () => {
    setIsLoading(true)
    await get_analytics({ token, time, branch_id: selectedBranch })
      .then((response) => {
        console.log(response);
        setData(response);
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
      });
    setIsLoading(false)
      
  };
  // FETCH BRANCHES
  const getBranches = async () => {
    setIsLoading(true)
    await get_branch_for_analytics({ token })
      .then((response) => {
        console.log(response,12);
        setBranches(response);
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
      });
    setIsLoading(false)
  };
  useEffect(() => {
    getBranches()
    getAnalytics();
  }, []);
  useEffect(() => {
    getAnalytics();
  }, [time, selectedBranch]);
  return (
    <div>
      {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        />
      )}
      <div
        className="top-stick-section flex justify-between p-8 sticky bg-white z-10 w-full border"
        style={{
          borderBottom: "3px solid  rgb(247, 246, 246)",
          height: "7rem",
        }}
      >
        <div className="screen-title">
          <h4 className="text-black font-bold text-xl">Dashboard</h4>
          <p>Analytics and order data</p>
        </div>
      </div>
      <div className="p-4">
        <div className="filter flex-1 flex justify-start items-center gap-2">
          <div
            className={
              "rounded-lg cursor-pointer p-2 text-sm border-2 border-l-indigo-800 " +
              (time == 7 ? "bg-blue-700 text-white" : null)
            }
            onClick={() => setTime(7)}
          >
            This Week
          </div>
          <div
            className={
              "rounded-lg cursor-pointer p-2 text-sm border-2 border-l-indigo-800 " +
              (time == 30 ? "bg-blue-700 text-white" : null)
            }
            onClick={() => setTime(30)}
          >
            This Month
          </div>
          <div
            className={
              "rounded-lg cursor-pointer p-2 text-sm border-2 border-l-indigo-800 " +
              (time == 365 ? "bg-blue-700 text-white" : null)
            }
            onClick={() => setTime(365)}
          >
            This Year
          </div>
          <div
            className={
              "rounded-lg cursor-pointer p-2 text-sm border-2 border-l-indigo-800 " +
              (time == 0 ? "bg-blue-700 text-white" : null)
            }
            onClick={() => setTime(0)}
          >
            All Time
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="filter  flex justify-start items-center gap-2 ">
          <p>Filter by branch:</p>
        <CustomSelector
          
                required={true}
                label={"Branch"}
                value={selectedBranch}
                onChange={(e) => {
                  setSelectedBranch(e.target.value);
                }}
                menuItems={[
                  { id: 0, name: "All" },
                  ...branches.map((e) => ({ id: e.id, name: e.name }))]}
              />
        </div>
      </div>
      <div className="flex gap-6 flex-wrap p-4">
        <StatCard
          name={"Total Orders"}
          amount={data?.totalOrder}
          Icon={<Analytics sx={{ fontSize: "5rem", color:"lightblue" }} />}
        />
        {/* <StatCard name={"Total Sells"} amount={123} Icon={<Analytics sx={{fontSize:"5rem"}}/>}/>  */}
        <TwoStatCard
          amount={data?.totalChapaSales + data?.totalCashSales}
          name={"Total Sells"}
          subName2={"Chapa Pay"}
          subName1={"Cash Pay"}
          amount1={data?.totalChapaSales}
          amount2={data?.totalCashSales}
          Icon={<AttachMoney sx={{ fontSize: "5rem", color:"lightgreen" }} />}
        />

        <StatCard
          name={"Total Customer"}
          amount={data?.totalCustomer}
          Icon={<Person sx={{ fontSize: "5rem", color:"coral" }} />}
        />

        <StatCard
          name={"Total Branch"}
          amount={branches?.length}
          Icon={<LocationCity sx={{ fontSize: "5rem", color:"lightyellow" }} />}
        />
         <FourStatCard
         
          subName4={"Compeleted"}
          subName3={"Canceled"}
          amount4={data?.compeleted}
          amount3={data?.canceled}
          subName2={"In Kitchen"}
          subName1={"New"}
          amount1={data?.newOrder}
          amount2={data?.kitchen}
          Icon={<Category sx={{ fontSize: "5rem", color:"lightblue" }} />}
        />
        <StatCard
          name={"Total Reservations"}
          amount={data?.reservation}
          Icon={<TableBar sx={{ fontSize: "5rem", color:"lightgray" }} />}
        />
      </div>
    </div>
  );
}

export default ManagerDashboard;
