import { Button, Dialog, GlobalStyles } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./Popup.css";
function Popup({ title, children, onClick, buttonChild, fullWidth }) {
  const boxRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 30);
  }, []);

  // useEffect(()=>{
  //   if(closePopup){
  //     handleSetInvisile(false)
  //   }
  // },[closePopup])
  // const handleSetInvisile=()=>{

  //       setIsVisible(false)

  // }
  return (
    // <div className="popup-container">
    //   <div className={"popup"}>
    //   {/* <div className={isVisible?"popup":"popup-invisible"}> */}
    //     <div className="title">
    //       <h3>{title}</h3>
    //     </div>
    //     <div className="body">{children}</div>

    //   </div>
    // </div>

    <Dialog
      open
      fullWidth={fullWidth}
      
      maxWidth={"md"}
    >
      
      <div  className="title font-semibold">
        <h3>{title}</h3>
      </div>
      <div className={"popup"}>{children}</div>
    </Dialog>
  );
}

export default Popup;
