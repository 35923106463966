import React from 'react'
import "./NoMobileVersion.css"

function NoMobileVersion() {
  return (
    <div
    className='--nomobileversion'
    >Sorry, the dashboards aren't yet accessible on a mobile device. Please login using an office desktop.</div>
  )
}

export default NoMobileVersion