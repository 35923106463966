import React from 'react' 

function NavbarMenuButton({title, Icon, color, backgroundColor, border}) { 
  return (
    <div className='nav-buttons-div' style={{color: color, backgroundColor:backgroundColor, borderRight:border}}>
        {/* <div className='icon'>{icon}</div> */}
        <Icon/>
        <div className='text'><p>{title} </p></div>

    </div>
  )
}

export default NavbarMenuButton