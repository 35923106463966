import React from "react";
import "./Buttons.css";
import { Link } from "react-router-dom";

function PrimaryButton({ onClickHandler, margin, children, backgroundColor, foregroundColor, width}) {
  return (
    <div
      //   className="primary-button"
      style={{ margin: ` 0 ${margin != null ? margin : "0px"}` }}
    >
      <Link to="/">
        <button className={`btn primary-button ` } onClick={onClickHandler}
          style={{backgroundColor:backgroundColor&& `${backgroundColor.toString()}`,
        color:foregroundColor&& foregroundColor}}
        
        >
          {children}
        </button>
      </Link>
    </div>
  );
}

export default PrimaryButton;
