import React, { useState } from "react";
import Popup from "../../../Components/Popups/Popup";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import Loading from "../../Widgets/Loading";
import ApiResult from "../../Widgets/ApiResult";
import { delelte_row } from "../../../Services/Manager/manager_services";

function DeleteConfirm({ closePopup, row, api }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error, try again!");
  const token = useSelector((state) => state.token);
  const handleDeletePressed = async () => {
    setIsLoading(true);

    try {
      const res = await delelte_row(token, row.id, api);
      console.log(res, "deleted");
      setIsLoading(false);
      closePopup(false);
    } catch (err) {
      console.log(err, "delete error");
      setIsLoading(false);
      //   closePopup(false);
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else {
        setErrorMsg("Deleting Failed");
      }
      setIsError(true);
    }
  };
  return (
    <>
      {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => setIsError(false)}
          isSuccess={false}
        ></ApiResult>
      )}
      <Popup title={"Careful!"}>
        <p>Are you sure you want to delete this cateogory? </p>
        <br />
        <p> {`   -${row.name}`}</p>
        <div className="footer">
          <Button onClick={handleDeletePressed}>Delete</Button>
          <Button onClick={() => closePopup(true)}>
            <p>Close</p>
          </Button>
        </div>
      </Popup>
    </>
  );
}

export default DeleteConfirm;
