import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetch_services } from "../../../Services/Admin/admin_services";
import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import MainBodyStructure from "../../../Components/MainBody/MainBodyStructure";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import ApiResult from "../../Widgets/ApiResult";
import { URLs } from "api/URLs";
import UpdateStatus from "Components/UpdateStatus/UpdateStatus";
import AddNewService from "./AddNewService";
import UpdateService from "./UpdateService";

function Services() {
  const token = useSelector((state) => state.token);
  const [services, setServices] = useState([]);
  const [isAddFormVisible, setIsAddFormVisible] = useState(false);
  const [isAddSuccessful, setIsAddSuccessful] = useState(false);
  const [updatingService, setUpdatingService] = useState(null);
  const [isUpdateFormVisible, setIsUpdateFormVisible] = useState(false);
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong, try again.");
  const [isEmpty, setIsEmpty] = useState(false);

  const getServices = async () => {
    try {
      const servicesData = await fetch_services(token);
      if (servicesData.length) {
        setServices(servicesTableRows(servicesData));
      } else {
        setIsEmpty(true);
      }
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 409 || err.response?.status === 401) {
        setErrorMsg(err.response?.data.msg);
      } else {
        setErrorMsg("Couldn't get services");
      }
      setIsError(true);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  const openUpdateForm = async (open, cancel) => {
    if (open) {
      setIsUpdateFormVisible(true);
    } else {
      setIsUpdateFormVisible(false);
      if (!cancel) {
        setIsUpdateSuccessful(true);
      }
    }
  };
  // REFRESH TABLE DATA
  const refreshData = () => {
    setServices([]);
    getServices();
  };
  return (
    <div>
      {isUpdateFormVisible ? (
        <UpdateService
          service={updatingService}
          closePopup={() => {
            setIsUpdateFormVisible(false);
            refreshData();
          }}
        />
      ) : null}
      {isError && (
        <ApiResult
          message={errorMsg}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        ></ApiResult>
      )}
      {isAddFormVisible ? (
        <AddNewService
          closePopup={() => {
            setIsAddFormVisible(false);
            refreshData();
          }}
        />
      ) : null}
      <MainBodyStructure
        isEmpty={isEmpty}
        title={"Services"}
        subtitle={"Available service options to serve item through."}
        buttons={[
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              // openAddNewForm(true);
              setIsAddFormVisible(true);
            }}
          >
            <AddIcon /> Add New Service
          </Button>,
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              // paddingLeft: "1rem",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              refreshData();
            }}
          >
            {<RefreshIcon />} REFRESH
          </Button>,
        ]}
        data={{
          header: header((service) => {
            setUpdatingService(service);
            openUpdateForm(true);
          }, refreshData),
          data: services,
        }}
      />
    </div>
  );
}

export default Services;

const servicesTableRows = (services) => {
  const row = [];
  services.map((e) => {
    row.push({
      id: services.indexOf(e) + 1,
      name: e.name,
      location:e.location_type,
      status: e,
      action: e,
    });
  });
  return row;
};

const header = (updateFunction, updateStatus) => {
  return [
    {
      field: "id",
      headerName: "NO.",
      width: 300,
      headerClassName: "super-app-theme--header",
      // width: 75,
      align: "right",
      headerAlign: "right",
      flex: 0.7,
    },
    {
      field: "name",
      headerName: "NAME",
      headerClassName: "super-app-theme--header",
      // width:200,
      flex: 1,

      align: "left",
      padding: "10px",
      headerAlign: "center",
    },
    {
      field: "location",
      headerName: "LOCATION",
      headerClassName: "super-app-theme--header",
      // width:200,
      flex: 1,

      align: "left",
      padding: "10px",
      headerAlign: "center",
    },

    {
      field: "status",
      headerName: "STATUS",
      headerClassName: "super-app-theme--header",
      width: 150,

      align: "left",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {
            <UpdateStatus
              api={URLs.UPDATE_SERVICE_STATUS}
              row={params.value}
              reloadData={updateStatus}
            />
          }
        </>
      ),
    },

    {
      field: "action",
      headerName: "ACTION",
      minWidth: 100,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            style={{
              color: "rgb(62, 62, 216)",
              marginRight: "10px",
              boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",

              padding: "0",
              width: "10px",
            }}
            onClick={() => {
              updateFunction(params.value);
            }}
          >
            <EditNoteOutlinedIcon />
          </Button>
        </>
      ),
    },
  ];
};
