import React from "react";
import eat from "../../Assets/Images/eat.png";
import "./ItemCard.css";
function ItemCard({ name, price, image , onClick}) {
  return (
    <div className="item-card" onClick={onClick}>
      <img className="item-image" src={image ? image: eat} width={"100px"} />
      <div
        className="text "
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "0 1rem",
          height: "80%",
          fontWeight: "bold",
          fontSize:"1.2rem",
          alignItems:"center",
          textAlign:"left"
        }}
      >
        {" "}
        <div><p>{name}</p>
        <p style={{fontSize:".9rem", color:"grey"}}>Br {price}</p></div>
        
      </div>
    </div>
  );
}

export default ItemCard;
