import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { clearCanceledOrders } from "../../../../Services/Cashier/cashier_services";
import { useSelector } from "react-redux";

function ClearCanceledOrders({handleClearOrders, length}) { 
  
  return (
    <div className="px-4">
      <Button
        disabled={length==0}
        onClick={handleClearOrders}
        sx={{
          color: "red",
        }}
      >
        Clear Orders
      </Button>
    </div>
  );
}

export default ClearCanceledOrders;
