import React, { useEffect, useState } from "react";
import MainBodyStructure from "../../../Components/MainBody/MainBodyStructure";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useSelector } from "react-redux";
import { fetch_all_users } from "../../../Services/Admin/admin_services";
import AddNewUser from "./AddNewUser";
import ApiResult from "../../Widgets/ApiResult";
import UpdateUserProfile from "./UpdateUserProfile";

function Users() {
  const token = useSelector((state) => state.token);
  const user = useSelector(state=>state.user)
  const [users, setUsers] = useState([]);

  //ADD NEW CUSTOMER VARIABLES
  const [isAddUserSuccesful, setIsAddUserSuccessful] = useState(false);
  const [isAddUserFormVisible, setIsAddUserFormVisible] = useState(false);
  const [updatingUser, setUpdatingUser] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false)

  const openAddNewForm = async (open, cancel) => {
    if (open) {
      setIsAddUserFormVisible(true);
    } else {
      setIsAddUserFormVisible(false);
      if (!cancel) {
        setIsAddUserSuccessful(true);
      }
    }
  };
  // UPDATE USER PROFIL
  const [isUpdateUserSuccesful, setIsUpdateUserSuccessful] = useState(false);
  const [isUpdateUserFormVisible, setIsUpdateUserFormVisible] = useState(false);

  const openUpdateForm = async (open, cancel) => {
    if (open) {
      setIsUpdateUserFormVisible(true);
    } else {
      setIsUpdateUserFormVisible(false);
      if (!cancel) {
        setIsUpdateUserSuccessful(true);
      }
    }
  };

  //LOAD COMPANIES FROM STATE OR API
  const loadUsers = async () => {
    try {
      setIsEmpty(false)
      const userslist = await fetch_all_users(token, user.role==3);
      if(!userslist.length){setIsEmpty(true)}
      const userp = userModel(userslist);
      setUsers(userp);

      // console.log(userslist,userp , "poisonwho")
    } catch (e) {
      console.log(e, "error in fetch users function");
    }
  };

  // REFRESH TABLE DATA
  const refreshData = ()=>{
    setUsers([]);
    loadUsers();
  }
  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    if (isAddUserSuccesful || isUpdateUserSuccesful) {
      setUsers([]);
      loadUsers();
    }
  }, [isAddUserSuccesful, isUpdateUserSuccesful]);
  return (
    <div>
      {isAddUserSuccesful && (
        <ApiResult
          message={`User Added Succesfully`}
          handleClose={() => {
            setIsAddUserSuccessful(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isUpdateUserSuccesful && (
        <ApiResult
          message={`User Updated Succesfully`}
          handleClose={() => {
            setIsUpdateUserSuccessful(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isAddUserFormVisible && (
        <AddNewUser closePopup={(close) => openAddNewForm(false, close)} />
      )}
      {isUpdateUserFormVisible && (
        <UpdateUserProfile
          closePopup={(close) => openUpdateForm(false, close)}
          user={updatingUser}
        />
      )}
      <MainBodyStructure
        // handleSetOffset={(b) => handleSetOffset(b)}
        // offset={offset}
        title={"Users"}
        subtitle={"Currently registered users."}
        isEmpty={isEmpty}
        buttons={[
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              openAddNewForm(true);
            }}
          >
            <AddIcon /> Add new user
          </Button>,
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              // paddingLeft: "1rem",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              refreshData();
            }}
          >
            {<RefreshIcon />} REFRESH
          </Button>,
        ]}
        data={{
          header: header((user) => {
            setUpdatingUser(user);

            openUpdateForm(true);
          }),
          data: users,
        }}
        // data={usersz?usersz.length?{header:header, data:userModel(usersz)}: {header:header, data:[]}: {header:header, data:[]}}
      />
    </div>
  );
}

export default Users;

const header = (updateFunction) => {
  return [
    {
      field: "id",
      headerName: "NO.",
      width: 300,
      headerClassName: "super-app-theme--header",
      // width: 75,
      align: "right",
      headerAlign: "right",
      flex: 0.7,
    },
    {
      field: "fullname",
      headerName: "FULL NAME",
      headerClassName: "super-app-theme--header",
      // width:200,
      flex: 1,

      align: "left",
      padding: "10px",
      headerAlign: "center",
    },
    {
      field: "username",
      headerName: "USERNAME",
      // width: 300,
      flex: 1,

      headerClassName: "super-app-theme--header",
      // flex: 1,
      width: 200,
      align: "left",

      headerAlign: "center",
    },
    {
      field: "company",
      headerName: "COMPANY",
      headerClassName: "super-app-theme--header",
      // width: 150,
      flex: 1,

      align: "left",

      headerAlign: "center",
    },
    {
      field: "branch",
      headerName: "BRANCH",
      headerClassName: "super-app-theme--header",
      // width: 150,
      flex: 1,

      align: "left",

      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "STATUS",
      // width: 150,
      flex: 0.7,

      headerClassName: "super-app-theme--header",
      align: "left",

      headerAlign: "center",
      renderCell: (params) => (
        <>
          <p style={{ color: params.value == "Suspended" ? "red" : "black" }}>
            {params.value}  
          </p>
        </>
      )
    },
    {
      field: "role",
      headerName: "ROLE",
      // width: 150,
      headerClassName: "super-app-theme--header",
      align: "left",
      flex: 0.7,

      headerAlign: "center",
      
    },
    {
      field: "action",
      headerName: "ACTION",
      minWidth: 100,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            style={{
              color: "rgb(62, 62, 216)",
              marginRight: "10px",
              boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",

              padding: "0",
              width: "10px",
            }}
            onClick={() => {
              updateFunction(params.value);
            }}
          >
            <EditNoteOutlinedIcon />
          </Button>

          {/* <Button
          style={{
            border: "2px solid coral",
            color: "coral",
            boxShadow: "2px 3px 2px 0px rgba(128, 126, 126, 0.4)",
            padding: "0",
            width: "10px !important",
          }}
          onClick={() => {
            buttonFunctions[3](params.value);
            buttonFunctions[2]();
          }}
        >
          <ClearOutlinedIcon />
        </Button> */}
        </>
      ),
    },
  ];
};
// header: [
//   " ",
//   "COMPANY NAME",
//   "TIN",
//   "OPENING HOUR",
//   "CLOSING HOUR",
//   "ACTION",
// ],
// const data= [...companies_model];

const userModel = (users) => {
  const newUsersModel = [];
  users.map((e) => {
    newUsersModel.push({
      id: users.indexOf(e) + 1,
      fullname: e.getName(),
      username: e.username,
      company: e.companyName,
      branch: e.branchName,
      status: e.status,
      role: e.role,
      action: e,
    });
  });
  return newUsersModel;
};
const userss = [
  {
    id: "1",
    fullname: "Samuel Petros",
    username: "sampk",
    company: "QR",
    status: "Active",
    role: "Admin",
    action: "1",
  },
  {
    id: "2 ",
    fullname: "Samuel Petros",
    username: "sampk",
    company: "QR",
    status: "Active",
    role: "Admin",
    action: "1",
  },
  {
    id: "3",
    fullname: "Samuel Petros",
    username: "sampk",
    company: "QR",
    status: "Active",
    role: "Admin",
    action: "1",
  },
  {
    id: "4",
    fullname: "Samuel Petros",
    username: "sampk",
    company: "QR",
    status: "Active",
    role: "Admin",
    action: "1",
  },
  {
    id: "5",
    fullname: "Samuel Petros",
    username: "sampk",
    company: "QR",
    status: "Active",
    role: "Admin",
    action: "1",
  },
  {
    id: "6",
    fullname: "Samuel Petros",
    username: "sampk",
    company: "QR",
    status: "Active",
    role: "Admin",
    action: "1",
  },
  {
    id: "7",
    fullname: "Samuel Petros",
    username: "sampk",
    company: "QR",
    status: "Active",
    role: "Admin",
    action: "1",
  },
  {
    id: "8",
    fullname: "Samuel Petros",
    username: "sampk",
    company: "QR",
    status: "Active",
    role: "Admin",
    action: "1",
  },
  {
    id: "1",
    fullname: "Samuel Petros",
    username: "sampk",
    company: "QR",
    status: "Active",
    role: "Admin",
    action: "1",
  },
  {
    id: "1",
    fullname: "Samuel Petros",
    username: "sampk",
    company: "QR",
    status: "Active",
    role: "Admin",
    action: "1",
  },
  {
    id: "1",
    fullname: "Samuel Petros",
    username: "sampk",
    company: "QR",
    status: "Active",
    role: "Admin",
    action: "1",
  },
];
