import React, { useEffect, useState } from "react";
import "./MainBodyStructure.css";
import Loading from "../../Scenes/Widgets/Loading";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { lightBlue } from "@mui/material/colors";
function MainBodyStructure({
  title,
  subtitle,
  buttons,
  data,
  handleSetOffset,
  offset,
  isEmpty
}) {
  const [table, setTable] = useState(null);
  const [noData, setNoData] = useState(false);
  const loadTable = () => {
    setTable(data);
  };

  useEffect(() => {
    loadTable();
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      if (!table) {
        setNoData(true);
      } else {
        if (!table.data) {
          setNoData(true);
        } else {
          if (table.data.length == []) {
            setNoData(true);
          }
        }
      }
      setNoData(true);
    }, 15000);
  }, []);

  const rows = [
    { id: 1, col1: "Hello", col2: "World" },
    { id: 2, col1: "DataGridPro", col2: "is Awesome" },
    { id: 3, col1: "MUI", col2: "is Amazing" },
  ];

  const columns = [
    { field: "col1", headerName: "Column 1", width: 150 },
    { field: "col2", headerName: "Column 2", width: 150 },
  ];
  return (
    <>
      <div className="main-body-str">
        <div className="main-body-header">
          <div className="texts">
            <h2 className="text-xl font-bold">{title}</h2>
            <p>{subtitle}</p>
          </div>
          <div className="buttons">
            {buttons.length != 0 &&
              buttons.map((e) => <div className="main-body-btn">{e}</div>)}
          </div>
        </div>
        {table != null && table.data && table.data.length || isEmpty ? (
          <div className="main-body-table">
            <DataGrid
              getRowHeight={() => 'auto'}
              draggable
              // autoHeight
              rows={table.data}
              className="table"
              columns={table.header}
              isRowSelectable={() => false}
              sx={{
                // display:"flex !important",
                // flexDirection:"column-reverse",
                boxShadow: 2,
                borderRadius: 0,
                backgroundColor: " rgb(228, 237, 240, 0.3)",
                "& .MuiDataGrid-cell:hover": {
                  color: "blue",
                },
                "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
                  borderRight: `1px solid lightblue`,
                },
                "& .MuiDataGrid-cell": {
                  outline: " none !important;",
                  padding: " 20px",
                  width: "fit-content",
                  alignItems: "start"
                  // height:"400px !important"
                },
                "& .MuiDataGrid": {
                  borderRadius: "0",
                },
                cells: { borderRight: "1px solid red" },
                ".MuiDataGrid-cell:last-child": { borderRight: "none" },
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        ) :
          <div className="main-body-table">
            <Loading light={true} />
          </div>
          // noData ? (
          //   <div className="main-body-table">No Data Recieved</div>
          // ) : table != null && table.data  ?  (
          //   <div className="main-body-table">
          //     <Loading light={true} />
          //   </div>
          // ) :(
          //   <div className="main-body-table">No Data Recieved</div>
          // )

        }

        {/* {table != null ? 
        table.length ==0 ? <div className="--flex-center-center --fill-parent"> <i> Empty table.</i></div>:
        (
          <div className="main-body-table">
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <td>
                      <h5 className="padding-left">NO</h5>
                    </td>
                    {table.header.map((e) => (
                      <td>
                        <h5>{e}</h5>
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {table.data.map((e) => (
                    <tr>
                      <td className="padding-left">
                        <p>{table.data.indexOf(e) + 1}</p>{" "}
                      </td>
                      {Object.keys(e).map((f) => (
                        <td>
                          <p>{e[f]}</p>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="paginator">
                <p onClick={()=>handleSetOffset(false)} className="--pointer">previous</p>
                <h5>{offset+1}</h5>
                <p onClick={()=>handleSetOffset(true)} className="--pointer">next</p>
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )} */}
      </div>
      {/* ) : (
        <Loading />
      )} */}
    </>
  );
}

export default MainBodyStructure;
