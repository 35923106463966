import React, { useEffect, useState } from "react";
import "./AddNewCompany.css";
import Popup from "../../../Components/Popups/Popup";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { URLs } from "../../../api/URLs";
import axios from "../../../api/axios";
import ApiResult from "../../Widgets/ApiResult";
import Loading from "../../Widgets/Loading";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { setCompanies } from "../../../state";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomTextfield from "../../Widgets/CustomTextfield";
import { HexColorPicker } from "react-colorful";
import CustomSelector from "Scenes/Widgets/CustomSelector";
import UpdateStatus from "Components/UpdateStatus/UpdateStatus";
import ShortNote from "Components/ShortNote/ShortNote";

dayjs.extend(utc);

function UpdateCompanyProfile({
  closePopup,
  name_p,
  tin_p,
  open_p,
  close_p,
  image_fieldValues,
  image_preview,
  id,
  primary_color,
  secondary_color,
  isSalesManaged,
  isPrinting,
  is_vat,
  service_charge,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);

  const [name, setName] = useState(name_p);
  const [tin, settin] = useState(tin_p);
  const [isSalesManagedState, setIsSalesManagedState] =
    useState(isSalesManaged);
  const [newIsPrintingValue, setNewIsPrintingValue] = useState(isPrinting);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error, try again!");
  const [opening_hour, setOpeningHour] = useState(
    dayjs().hour(open_p.split(":")[0]).minute(open_p.split(":")[1])
  );
  const [closing_hour, setClosingingHour] = useState(
    dayjs().hour(close_p.split(":")[0]).minute(close_p.split(":")[1])
  );
  const [fieldValues, setFieldValues] = useState(null);
  const [preview, setPreview] = useState(image_preview);
  const [primaryColor, setPrimaryColor] = useState(String(primary_color));
  const [secondaryColor, setSecondaryColor] = useState(String(secondary_color));
  const [isVat, setIsVat] = useState(is_vat);
  const [serviceCharge, setServiceCharge] = useState(service_charge);

  console.log(is_vat, service_charge);
  const handleImageChnange = (e) => {
    console.log(e);
    if (e.target.files.length) {
      setPreview(e.target.files[0]);
      setFieldValues(URL.createObjectURL(e.target.files[0]));
    } else {
      setPreview(image_preview);
      setFieldValues(null);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const open = opening_hour.unix();
      const close = closing_hour.unix();
      setIsLoading(true);
      if (fieldValues) {
        const formData = new FormData();
        formData.append("file", preview);
        formData.append("upload_preset", "ffblgmbh");
        axios
          .post(
            "https://api.cloudinary.com/v1_1/dsskh3fao/image/upload",
            formData
          )
          .then(async (cld_response) => {
            const image = cld_response.data.url;
            console.log(primaryColor, secondaryColor, 798);
            const response = await axios.post(
              user.role == 3
                ? URLs.UPDATE_COMPANY_PROFILE_FOR_SALES
                : URLs.UPDATE_COMPANY_PROFILE,
              JSON.stringify({
                name,
                tin,
                open,
                close,
                image,
                id,
                primaryColor,
                secondaryColor,
                isSalesManaged: isSalesManagedState,
                isPrinting:
                  isPrinting == newIsPrintingValue ? "-1" : newIsPrintingValue,
              }),
              {
                headers: {
                  "Content-Type": "application/json",
                  "x-auth-token": token,
                },
                withCredentials: true,
              }
            );

            setName("");
            settin("");

            setIsLoading(false);
            closePopup(false);
          })
          .catch((err) => {
            setIsLoading(false);

            console.log(err);
            if (!err?.response) {
              setErrorMsg("No Server Response");
            } else if (
              err.response?.status === 409 ||
              err.response?.status === 401
            ) {
              setErrorMsg(err.response?.data.msg);
            } else {
              setErrorMsg("Add Company Failed");
            }
            setIsError(true);
          });
      } else {
        const image = image_preview;
        await axios
          .post(
            user.role == 3
              ? URLs.UPDATE_COMPANY_PROFILE_FOR_SALES
              : URLs.UPDATE_COMPANY_PROFILE,
            JSON.stringify({
              name,
              tin,
              open,
              close,
              image,
              id,
              primaryColor,
              secondaryColor,
              isSalesManaged: isSalesManagedState,
              isPrinting:
                isPrinting == newIsPrintingValue ? "-1" : newIsPrintingValue,
              is_vat: isVat,
              service_charge: serviceCharge,
            }),
            {
              headers: {
                "Content-Type": "application/json",
                "x-auth-token": token,
              },
              withCredentials: true,
            }
          )
          .then((response) => {
            const companies = response?.data?.msg;
            console.log(response);

            setName("");
            settin("");

            setIsLoading(false);
            closePopup(false);
          })
          .catch((err) => {
            setIsLoading(false);

            console.log(err);
            if (!err?.response) {
              setErrorMsg("No Server Response");
            } else if (
              err.response?.status === 409 ||
              err.response?.status === 401
            ) {
              setErrorMsg(err.response?.data.msg);
            } else {
              setErrorMsg("Update Company Failed");
            }
            setIsError(true);
          });
      }
    } catch (err) {
      setIsLoading(false);

      console.log(err);
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 409 || err.response?.status === 401) {
        setErrorMsg(err.response?.data.msg);
      } else {
        setErrorMsg("Update Company Failed");
      }
      setIsError(true);
    }
  };
  const handleCloseErrorMsg = () => {
    setIsError(false);
  };

  useEffect(() => {
    setPrimaryColor(primary_color);
    setSecondaryColor(secondary_color);
  }, []);
  // console.log(isPrinting, 123);
  return (
    <div className="add-new-company">
      {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={handleCloseErrorMsg}
          isSuccess={false}
        ></ApiResult>
      )}
      <Popup title={"Update Company Profile"}>
        <form className="add-new-company-form" onSubmit={handleSubmit}>
          <div className="form-body">
            <CustomTextfield
              label={"Name"}
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Company Name"
            />
            {/* <div className="text-field">
              <label htmlFor="name">Name</label>
              <input
                style={{ height: "20px" }}
                
                required
                placeholder="Company Name"
                id="name"
                name="name"
              />
            </div> */}
            {/* <div className="text-field">
              <label htmlFor="tin">Tin</label>
              <input
                style={{ height: "20px" }}
                value={tin}
                required
                onChange={(e) => settin(e.target.value)}
                type="name"
                placeholder="Company Tin"
                id="tin"
                name="tin"
              /> */}
            <CustomTextfield
              label={"Tin"}
              value={tin}
              required
              onChange={(e) => settin(e.target.value)}
              placeholder="Company Tin"
            />
            {/* </div> */}
            <div className="text-field">
              <label htmlFor="opening">Opening Hour</label>

              <TimePicker
                id="opening"
                onChange={(e) => setOpeningHour(e)}
                defaultValue={opening_hour}
                height={"100px"}
                sx={{
                  "*": { borderRadius: ".7rem" },
                  backgroundColor: "white",
                  borderRadius: ".7rem",
                }}
              />
            </div>
            <div className="text-field">
              <label htmlFor="closing">Closing Hour</label>

              <TimePicker
                id="closing"
                onChange={(e) => setClosingingHour(e)}
                defaultValue={closing_hour}
                borderRadius={".7rem"}
                sx={{
                  "*": { borderRadius: ".7rem" },
                  backgroundColor: "white",
                  borderRadius: ".7rem",
                }}
              />
            </div>
            <div className="span-2">
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={"1rem"}
                border={`2px dashed lightblue`}
                borderRadius={".5rem"}
                p="1rem"
                sx={{
                  // "&:hover": { cursor: "pointer" }
                  backgroundColor: "white",
                  minHeight: 100,
                }}
              >
                <label for="file-upload" class="custom-file-upload">
                  {fieldValues ? "Change Image" : "Upload New Image"}
                </label>
                <input
                  id="file-upload"
                  height={""}
                  type="file"
                  onChange={(e) => handleImageChnange(e)}
                  accept="image/png, image/jpeg, image/jpg"
                />
                <div>
                  {fieldValues ? (
                    <img
                      width={"140px"}
                      src={fieldValues && fieldValues}
                      alt="priview"
                    />
                  ) : (
                    <img width={"140px"} src={image_preview} alt="priview" />
                  )}
                </div>
              </Box>
            </div>
            <div className="span-2">
              <div
                className="w-full h-40 font-bold text-2xl flex justify-center items-center"
                style={{ backgroundColor: primaryColor, color: secondaryColor }}
              >
                {name}
              </div>
            </div>
            <div className="text-field center">
              {/* <div className="value" style={{ padding: "1rem 0" }}>
                Primary Color
              </div> */}
              <CustomTextfield
                label={"Primary Color"}
                value={primaryColor}
                required
                onChange={(e) => setPrimaryColor(e.target.value)}
                placeholder="Primary Color"
              />
              <br />
              <HexColorPicker color={primaryColor} onChange={setPrimaryColor} />
            </div>
            <div className="text-field center">
              {/* <div className="value" style={{ padding: "1rem 0" }}>
                Secondary Color
              </div> */}
              <CustomTextfield
                label={"Secondary Color"}
                value={secondaryColor}
                required
                onChange={(e) => setSecondaryColor(e.target.value)}
                placeholder="Secondary Color"
              />
              <br />

              <HexColorPicker
                color={secondaryColor}
                onChange={setSecondaryColor}
              />
            </div>
            {user.role === 2 ? (
              <>
                <div className="span-2">
                  <ShortNote
                    text={
                      "Turn on and off the below toggle to give/deny access of this company to the marketing personals."
                    }
                  />
                </div>{" "}
                <div className="flex gap-4 items-center justify-center">
                  <p>Printing enabled:</p>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            newIsPrintingValue == 1 || newIsPrintingValue == 0
                          }
                          onChange={() => {
                            setNewIsPrintingValue(
                              newIsPrintingValue == 1 || newIsPrintingValue == 0
                                ? 2
                                : 1
                            );
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </div>
                <div className="flex gap-4 items-center justify-center">
                  <p>Managed by marketing team:</p>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isSalesManagedState}
                          onChange={() => {
                            setIsSalesManagedState(!isSalesManagedState);
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </>
            ) : null}

            <div className="flex gap-2 items-center justify-center">
              <p className=" mr-5">ToT</p>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isVat}
                      onChange={() => {
                        setIsVat(!isVat);
                      }}
                    />
                  }
                />
              </FormGroup>
              <p>VAT</p>
            </div>
            <CustomTextfield
              label={"Service Charge"}
              value={serviceCharge}
              required
              onChange={(e) => setServiceCharge(e.target.value)}
              placeholder="Service Charge"
            />
          </div>

          <div className="footer">
            <Button
              type="submit"
              disabled={
                name_p == name.trim() &&
                tin_p == tin.trim() &&
                `${dayjs().minute(open_p.split(":")[1]).minute()}` ==
                  `${opening_hour.minute()}` &&
                `${dayjs().hour(open_p.split(":")[0]).hour()}` ==
                  `${opening_hour.hour()}` &&
                `${dayjs().minute(close_p.split(":")[1]).minute()}` ==
                  `${closing_hour.minute()}` &&
                `${dayjs().hour(close_p.split(":")[0]).hour()}` ==
                  `${closing_hour.hour()}` &&
                primaryColor === primary_color &&
                secondaryColor === secondary_color &&
                !fieldValues &&
                isSalesManaged === isSalesManagedState &&
                isPrinting === newIsPrintingValue &&
                is_vat===isVat &&
                serviceCharge === service_charge
              }
            >
              Update
            </Button>
            <Button onClick={() => closePopup(true)}>
              <p>Close</p>
            </Button>
          </div>
        </form>
      </Popup>
    </div>
  );
}

export default UpdateCompanyProfile;
