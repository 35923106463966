import React from "react";
import ItemCard from "./ItemCard";
import UpdateStatus from "../../../Components/UpdateStatus/UpdateStatus";
import { URLs } from "../../../api/URLs";
import { Button } from "@mui/material";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

function ItemsFlex({ items,  reloadItems, editFunction, deleteFunction, removeDraft }) {
  return (
    <div className=" overflow-auto items-start" style={{height:"calc(100dvh - 7rem)"}}>
      <div className="flex gap-4 p-4 flex-wrap justify-start">
      
      {items.map((item) => (
        <>
          <ItemCard item={item} isDraft={item.status===-1}>
            {
            <UpdateStatus
              api={URLs.UPDATE_ITEM_STATUS}
              row={item}
              reloadData={reloadItems}
            />}
            <Button
              style={{
                color: "rgb(62, 62, 216)",
                marginRight: "10px",
                boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",
                padding: "0",
                width: "10px",
              height:"fit-content",
              backgroundColor:"white"

              }}
              onClick={()=>editFunction(item)}
            >
              <EditNoteOutlinedIcon />
            </Button>
            <Button
              style={{
                color: "coral",
                boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",
                padding: "0",
                width: "10px !important",
              height:"fit-content",
              backgroundColor:"white"

              }}
              onClick={()=>item.status===-1?removeDraft(item.id):deleteFunction(item)}
            >
              <ClearOutlinedIcon />
            </Button> 
          </ItemCard>
        </>
      ))}
      </div>
    </div>
  );
}

export default ItemsFlex;
