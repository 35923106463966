import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import {
  add_new_item,
  fetch_company_category,
  update_item,
} from "../../../Services/Manager/manager_services";
import Loading from "../../Widgets/Loading";
import ApiResult from "../../Widgets/ApiResult";
import React, { useEffect, useState } from "react";
import Popup from "../../../Components/Popups/Popup";
import CustomTextfield from "../../Widgets/CustomTextfield";
import CustomForm from "../../../Components/CustomForm/CustomForm";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { fetch_company_branches } from "../../../Services/Admin/admin_services";
import "./AddNewItem.css";
import CustomSelector from "../../Widgets/CustomSelector";
import axios from "axios";
import ShortNote from "../../../Components/ShortNote/ShortNote";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import PricingTable from "./PricingTable";
import { image } from "@cloudinary/url-gen/qualifiers/source";
function UpdateItem({ closePopup, item, updateDraftHandler, originalImage }) {
  const token = useSelector((state) => state.token);
  const companyId = useSelector((state) => state.user.company_id);

  const [name, setName] = useState(item.name);
  const [fieldValues, setFieldValues] = useState(null);
  const [preview, setPreview] = useState(item.image);

  const [isLoading, setIsLoading] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong, try again!");

  const [variationLabel, setVariationLabel] = useState("Variations");
  const [addonLabel, setAddonLabel] = useState("Addons");

  // PRICING
  const [variationTypes, setVariationTypes] = useState([]);
  const [addonTypes, setAddonTypes] = useState([]);

  const [description, setDescription] = useState(item.description);

  // console.log(addonTypes)

  // GET BRANCHES FROM STATE/REDUX
  const branchesFromState = useSelector((state) => state.branches);

  //LOAD COMPANIES FROM STATE OR API
  const loadBranch = async () => {
    try {
      if (branchesFromState.length) {
        console.log(branchesFromState, "Truth");
        setBranches(branchesFromState);
      } else {
        const branchList = await fetch_company_branches(token, companyId);

        setBranches(branchList);
      }

      // console.log(userslist,userp , "poisonwho")
    } catch (e) {
      console.log(e, "error in fetch users function");
    }
  };

  const handleImageChnange = (e) => {
    // console.log(e);
    if (e.target.files.length) {
      setPreview(e.target.files[0]);
      setFieldValues(URL.createObjectURL(e.target.files[0]));
    } else {
      setPreview(item.image);
      setFieldValues(null);
    }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    if (item.status == -1) {
      const param = {
        id: item.id,
        name: name,
        category_id: categoryId,
        branches: selectedBranches,
        image: fieldValues,
        variationTypes: variationTypes,
        // variationTypes: variationTypes,
        addonTypes: addonTypes,
        status: -1,
        imageData: preview,
        withImage: true,
        description: description,
      };

      updateDraftHandler(param);
    } else {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", preview);
        formData.append("upload_preset", "ffblgmbh");
        axios
          .post(
            "https://api.cloudinary.com/v1_1/dsskh3fao/image/upload",
            formData
          )
          .then(async (cld_response) => {
            const image = cld_response.data.url;
            const param = {
              id: item.id,
              name: name,
              category: categoryId,
              branches: selectedBranches,
              image: image,
              variationTypes: variationTypes,
              // variationTypes: variationTypes,
              addonTypes: addonTypes,
              description: description,
            };
            console.log(param);

            const res = await update_item(token, param);
            setIsLoading(false);
            closePopup(false);
          })
          .catch((err) => {
            setIsLoading(false);

            console.log(err);
            if (!err?.response) {
              setErrorMsg("No Server Response");
            } else if (
              err.response?.status === 409 ||
              err.response?.status === 401
            ) {
              setErrorMsg(err.response?.data.msg);
            } else {
              setErrorMsg("Add Company Failed");
            }
            setIsError(true);
          });
      } catch (err) {
        setIsLoading(false);

        console.log(err);
        if (!err?.response) {
          setErrorMsg("No Server Response");
        } else if (
          err.response?.status === 409 ||
          err.response?.status === 401
        ) {
          setErrorMsg(err.response?.data.msg);
        } else {
          setErrorMsg("Add Company Failed");
        }
        setIsError(true);
      }
    }
  };

  const handelSubmitWithoutImage = async (e) => {
    e.preventDefault();
    if (item.status == -1) {
      const param = {
        id: item.id,
        name: name,
        category_id: categoryId,
        branches: selectedBranches,
        image: item.image,
        variationTypes: variationTypes,
        // variationTypes: variationTypes,
        addonTypes: addonTypes,
        status: -1,
        imageData: originalImage,
        withImage: item.withImage,
        description: description,
      };
      console.log(originalImage, 22);

      updateDraftHandler(param);
    } else {
      try {
        setIsLoading(true);

        const param = {
          id: item.id,
          name: name,
          category: categoryId,
          branches: selectedBranches,
          image: preview,
          variationTypes: variationTypes,
          // variationTypes: variationTypes,
          addonTypes: addonTypes,
          description: description,
        };
        console.log(param);

        const res = await update_item(token, param);
        setIsLoading(false);
        closePopup(false);
      } catch (err) {
        setIsLoading(false);

        console.log(err);
        if (!err?.response) {
          setErrorMsg("No Server Response");
        } else if (
          err.response?.status === 409 ||
          err.response?.status === 401
        ) {
          setErrorMsg(err.response?.data.msg);
        } else {
          setErrorMsg("Add Company Failed");
        }
        setIsError(true);
      }
    }
  };
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [selectedBranches, setSelectedBranches] = useState([...item.branches]);

  const instalizeBranches = () => {
    var newAddonTypes = [];
    var newVariationTypes = [];
    if (item.branches.length) {
      // item.branches.map((branch) => {
      //   branch.addon.map(add=>{
      //      newAddonTypes.push({
      //     id: add.id,
      //     name: add.name,
      //   });
      //   })

      // });
      if (item.status == -1) {
        item.addonTypes.map((add) => {
          newAddonTypes.push({
            id: add.id,
            name: add.name,
          });
        });

        item.variationTypes.map((variety) => {
          newVariationTypes.push({
            id: variety.id,
            name: variety.name,
          });
        });
      } else {
        item.branches[0].addon.map((add) => {
          newAddonTypes.push({
            id: add.id,
            name: add.name,
          });
        });

        item.branches[0].variation.map((variety) => {
          newVariationTypes.push({
            id: variety.id,
            name: variety.name,
          });
        });
      }
    }
    setAddonTypes(newAddonTypes);
    setVariationTypes(newVariationTypes);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value) {
      const selectedId = selectedBranches.map((f) => f.id);
      const valueId = value; //.map((f) => f.id);
      var newBranch = [];

      console.log(value, "touced br");

      for (var i = 0; i < value.length; i++) {
        if (selectedId.indexOf(valueId[i]) == -1) {
          //if (selectedId.indexOf(value[i].id) == -1) {
          newBranch.push(
            JSON.parse(JSON.stringify(branches)).filter(
              (e) => e.id == valueId[i]
            )[0]
          );
        }
      }

      if (newBranch.length) {
        for (var i = 0; i < selectedBranches[0].variation.length; i++) {
          newBranch[0].variation.push({
            id:
              Math.max.apply(
                Math,
                selectedBranches[0].variation.map((e) => e.id)
              ) +
              (i + 1),
            price: 0,
            name: selectedBranches[0].variation[i].name,
          });
        }

        for (var i = 0; i < selectedBranches[0].addon.length; i++) {
          newBranch[0].addon.push({
            id:
              Math.max.apply(
                Math,
                selectedBranches[0].addon.map((e) => e.id)
              ) +
              (i + 1),
            name: addonTypes[i],
            price: 0,
            name: selectedBranches[0].addon[i].name,
          });
        }
        setSelectedBranches([...selectedBranches, newBranch[0]]);
      }

      var removedBranch = [];
      for (var i = 0; i < selectedId.length; i++) {
        if (valueId.indexOf(selectedId[i]) == -1) {
          removedBranch.push(selectedId[i]);
        }
      }
      console.log(removedBranch, "removed branch");
      var copy = JSON.parse(JSON.stringify(selectedBranches));

      if (removedBranch.length && selectedBranches.length > 1) {
        copy.splice(selectedId.indexOf(removedBranch[0]), 1);

        setSelectedBranches(copy);
      }
    }
  };

  const [branchPrice, setBranchPrice] = useState([]);

  // FETCH BRANCHES
  const [branchId, setBranchId] = useState("");
  const [branches, setBranches] = useState([]);

  const getBranches = async () => {
    try {
      if (branchesFromState.length) {
        setBranches(branchPriceModel(branchesFromState));
      } else {
        const branchesData = await fetch_company_branches(token, companyId);
        const modelled = branchPriceModel(branchesData);
        setBranches(modelled);
      }
    } catch (error) {
      setErrorMsg("Couldn't load company branches, try again.");
      setIsError(true);
    }
  };
  console.log(selectedBranches, branches, "selectedBz");

  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(item.category_id);
  const getCategories = async () => {
    try {
      const categoryList = await fetch_company_category(token, companyId);
      setCategories(categoryList);
    } catch (err) {
      setErrorMsg("Couldn't load company categories, try again.");
      setIsError(true);
    }
  };
  useEffect(() => {
    getBranches();
    getCategories();
    instalizeBranches();

    // handleChange({target:{items}})
  }, []);
  const handleAddVariation = () => {
    const updatedBranch = selectedBranches.map((e) => {
      e.variation.push({
        id:
          Math.max.apply(
            Math,
            variationTypes.map((e) => e.id)
          ) + 1,
        // name: Math.max(variationTypes.map(e=>e.id))+1,
        name: variationLabel + " " + (e.variation.length + 1),
        price: 0,
      });
      return e;
    });
    setVariationTypes([
      ...variationTypes,
      {
        id:
          Math.max.apply(
            Math,
            variationTypes.map((e) => e.id)
          ) + 1,
        name: variationLabel + " " + (variationTypes.length + 1),
      },
    ]);
    setSelectedBranches(updatedBranch);
  };
  const handleRemoveVariation = (id) => {
    if (variationTypes.length > 1) {
      var updatedBranch = [...selectedBranches];

      updatedBranch.map((e) => {
        e.variation.splice(variationTypes.map((e) => e.id).indexOf(id), 1);
        return e;
      });
      var header = [...variationTypes];
      header.splice(variationTypes.map((e) => e.id).indexOf(id), 1);
      setVariationTypes(header);
      setSelectedBranches(updatedBranch);
    }
  };
  const handleAddAddon = (index) => {
    const updatedBranch = selectedBranches.map((e) => {
      e.addon.push({
        id: !addonTypes.length
          ? 0
          : Math.max.apply(
              Math,
              addonTypes.map((e) => e.id)
            ) + 1,

        name: addonLabel + " " + (e.variation.length + 1),
        price: 0,
      });
      return e;
    });
    setAddonTypes([
      ...addonTypes,
      {
        id: !addonTypes.length
          ? 0
          : Math.max.apply(
              Math,
              addonTypes.map((e) => e.id)
            ) + 1,
        name: addonLabel + " " + (addonTypes.length + 1),
      },
    ]);
    setSelectedBranches(updatedBranch);
  };
  const handleRemoveAddon = (id) => {
    var updatedBranch = [...selectedBranches];

    updatedBranch.map((e) => {
      e.addon.splice(addonTypes.map((e) => e.id).indexOf(id), 1);
      return e;
    });
    var header = [...addonTypes];
    header.splice(addonTypes.map((e) => e.id).indexOf(id), 1);
    setAddonTypes(header);
    setSelectedBranches(updatedBranch);
  };
  const editVariationPrice = (branch, index, price) => {
    console.log(selectedBranches);
    const updatedBranch = selectedBranches.map((e) => {
      if (e.id == branch) {
        e.variation.map((f) => {
          if (f.id == index) {
            f.price = price;
          }
          return f;
        });
      }

      return e;
    });
    setSelectedBranches(updatedBranch);
  };
  const editAddonPrice = (branch, index, price) => {
    const updatedBranch = selectedBranches.map((e) => {
      if (e.id == branch) {
        e.addon.map((f) => {
          // if (e.addon.indexOf(f) == index) {
          if (f.id == index) {
            f.price = price;
          }
          return f;
        });
      }

      return e;
    });
    setSelectedBranches(updatedBranch);
  };
  const editVariationName = (id, name) => {
    var header = [...variationTypes];
    header.map((e) => {
      if (e.id == id) e.name = name;
      return e;
    });
    setVariationTypes(header);
    // const updatedBranch = selectedBranches.map((e) => {
    //   e.variation.map((f) => {
    //     f.name = name;

    //     return f;
    //   });

    //   return e;
    // });
    // setSelectedBranches(updatedBranch);
  };
  const editAddonName = (id, name) => {
    var header = [...addonTypes];
    header.map((e) => {
      if (e.id == id) e.name = name;
      console.log(e);
      return e;
    });
    setAddonTypes(header);
    // const updatedBranch = selectedBranches.map((e) => {
    //   e.addon.map((f) => {
    //     f.name = name;

    //     return f;
    //   });

    //   return e;
    // });
    // setSelectedBranches(updatedBranch);
  };
  return (
    <div>
      {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        />
      )}
      <Popup title={"Add new item"} fullWidth>
        <CustomForm
          handleSubmit={(e) =>
            fieldValues ? handelSubmit(e) : handelSubmitWithoutImage(e)
          } //handelSubmit(e)}
          button={
            <div>
              <Button
                type="submit"
                onClick={() => console.log(selectedBranches)}
                disabled={
                  String(name).trim() == "" ||
                  String(categoryId).trim() == "" ||
                  !selectedBranches.length
                }
              >
                <p>Update</p>
              </Button>
              <Button onClick={() => closePopup(true)}>
                <p>Close</p>
              </Button>
            </div>
          }
        >
          <CustomTextfield
            label={"Name"}
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder={"item name"}
            required={true}
          />

          <CustomSelector
            required={true}
            label={"Category"}
            value={categoryId}
            onChange={(e) => {
              setCategoryId(e.target.value);
            }}
            menuItems={categories.map((e) => ({ id: e.id, name: e.name }))}
          />
          <div className="span-2">
            <CustomTextfield
              multiline
              label={"Description"}
              value={description}
              onChange={(e) => {
                if (e.target.value.length < 400) setDescription(e.target.value);
              }}
              //  required
            />
          </div>
          <div className="span-2">
            <Box
              // {...getRootProps()}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"1rem"}
              border={`1px solid #D9D9D9`}
              // borderRadius={".5rem"}
              p="1rem"
              sx={{
                // "&:hover": { cursor: "pointer" }
                backgroundColor: "#FCFCFC",
                minHeight: 100,
              }}
            >
              <div className="upload-arrow-icon">
                <ArrowUpwardRoundedIcon
                  sx={{ fontSize: "2.5rem", fontWeight: "600" }}
                />
              </div>
              <label for="file-upload" class="custom-file-upload">
                {"Change Image"}
              </label>
              <input
                height={""}
                id="file-upload"
                type="file"
                onChange={handleImageChnange}
                accept="image/png, image/jpeg, image/jpg"
                // value={preview.name}
              />
              {/* {value}
               */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {!fieldValues && (
                  <>
                    <p style={{ fontSize: ".8rem", color: "black" }}>
                      File format:{" "}
                      <span style={{ color: "grey" }}>
                        {"(JPEG, JPG and PNG)"}
                      </span>
                    </p>
                    <p style={{ fontSize: ".8rem", color: "black" }}>
                      Max size: <span style={{ color: "grey" }}>{"10Mb"}</span>
                    </p>
                  </>
                )}
                {fieldValues ? (
                  <img
                    width={"140px"}
                    // height={"100px"}
                    src={fieldValues && fieldValues}
                    alt="priview"
                  />
                ) : (
                  <img
                    width={"140px"}
                    // height={"100px"}
                    src={item.image}
                    alt="priview"
                  />
                )}
              </div>
            </Box>
            {/* )}
            </Dropzone> */}
          </div>
          <div className="span-2">
            <ShortNote
              text={
                "Choose branches of the company this new Item will be available in and fill their respective prices."
              }
            />
          </div>
          <div className="text-field span-2">
            {branches.length && (
              <FormControl
                fullWidth
                // margin="normal"
              >
                <InputLabel id="demo-multiple-checkbox">
                  {"Branches"}
                </InputLabel>

                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={
                    selectedBranches.length
                      ? JSON.parse(JSON.stringify(selectedBranches)).map(
                          (e) => {
                            return e.id;
                          }
                        )
                      : []
                  }
                  label={"Branches"}
                  onChange={handleChange}
                  placeholder={"Available in branches"}
                  renderValue={(selected) =>
                    selected
                      .map((e) => branches.filter((f) => f.id == e)[0].name)
                      .join(", ")
                  }
                >
                  {branches
                    .map((e) => {
                      return e.id; //{id:e.id,name:e.name}
                    })
                    .map((branch) => (
                      <MenuItem key={branch} value={branch}>
                        <Checkbox
                          checked={
                            selectedBranches
                              .map((br) => br.id)
                              .indexOf(branch) > -1
                          }
                        />
                        <ListItemText
                          primary={
                            branches.filter((f) => f.id == branch)[0].name
                          }
                        />
                      </MenuItem>
                      // <MenuItem key={branch.id} value={branch}>
                      //   <Checkbox checked={selectedBranches.map(br=>br.id).indexOf(branch.id) > -1} />
                      //   <ListItemText primary={branch.name} />
                      // </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </div>

          <PricingTable
            editVariationLabel={(x) => {
              setVariationLabel(x);
            }}
            editAddonLabel={(x) => {
              setAddonLabel(x);
            }}
            editAddonName={editAddonName}
            editVariationName={editVariationName}
            removeVariation={(x) => handleRemoveVariation(x)}
            removeAddon={handleRemoveAddon}
            addAddon={handleAddAddon}
            addVariation={handleAddVariation}
            handleVariationPrice={editVariationPrice}
            handleAddonPrice={editAddonPrice}
            variationLabel={variationLabel}
            addonLabel={addonLabel}
            branches={selectedBranches}
            variationList={variationTypes}
            addonList={addonTypes}
            isUpdating={true}
          />
        </CustomForm>
      </Popup>
    </div>
  );
}

export default UpdateItem;

const branchPriceModel = (branch) => {
  const model = [];

  if (branch && branch.length) {
    branch.map((e) => {
      model.push({
        id: e.id,
        name: e.name,
        variation: [],
        addon: [],
      });
    });
  }

  return model;
};
