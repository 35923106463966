import React, { useEffect, useState } from "react";
import { fetch_items } from "../../Services/Menu/menu_services";
import { useSelector } from "react-redux";
import "./Menu.css";
import ItemCard from "./ItemCard";
import MenuHeader from "./MenuHeader";
import MenuTab from "./Tabs/MenuTab";
import WorkInProgress from "../Widgets/WorkInProgress";
import Loading from "../Widgets/Loading";
function Menu() {
  const token = useSelector((state) => state.token);
  const [items, setItems] = useState([]);
  const [company, setCompany] = useState(null);

  const [tabLocation, setTabLocation] = useState("menu");
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getItems = async () => {
    setIsLoading(true);
    const params = {
      company: getQueryVariable("company"),
      branch: getQueryVariable("branch"),
    };

    try {
      const data = await fetch_items(token, params);
      const itemsData = data.items;
      const companyData = data.company;
      setItems(itemsData);
      setCompany(companyData);

      // console.log(itemsData, "menudata");

      if (data.length) {
        console.log(data, "menuuuuuu");
        setIsEmpty(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);

      console.log(e, "error in fetch items function");
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <>
      {/* nav bar */}
      {isEmpty && <WorkInProgress />}
      {!getQueryVariable("company") && <WorkInProgress />}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="menu-header">
            {company && (
              <MenuHeader
                image={company.image}
                opening={company.opening_hour}
                closing={company.closing_hour}
                address={company.branch_address}
                primaryColor={"orange"}
                secondaryColor={"whitesmoke"}
                tabLocation={tabLocation}
              />
            )}
          </div>
          {tabLocation == "menu" && <MenuTab items={items} />}
        </>
      )}
    </>
  );
}

export default Menu;

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  // console.log(query); //"app=article&act=news_content&aid=160990"
  var vars = query.split("&");
  // console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    // console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}
