import { add_company_category } from "Services/Manager/manager_services";
import Loading from "Scenes/Widgets/Loading";
import ApiResult from "Scenes/Widgets/ApiResult";
import React, { useState } from "react";
import Popup from "Components/Popups/Popup";
import CustomTextfield from "Scenes/Widgets/CustomTextfield";
import CustomForm from "Components/CustomForm/CustomForm";
import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ShortNote from "Components/ShortNote/ShortNote";
import { change_password } from "Services/Common/common_services";
import {
  turn_off_printer,
  turn_on_printer,
} from "Services/Cashier/cashier_services";
import { setIsPrinting } from "state";

function SettingsForCashier({ closePopup, isPrinting }) {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.token);

  const [newIsPrintingValue, setNewIsPrintingValue] = useState(isPrinting);

  const [isLoading, setIsLoading] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong, try again!");

  const handelSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      if (newIsPrintingValue == 0) {
        await turn_off_printer(token)
          .then((res) => {
            dispatch(
              setIsPrinting({
                isPrinting: newIsPrintingValue,
              })
            );

            setIsLoading(false);
            closePopup(false);
          })

          .catch((err) => {
            setIsLoading(false);
            console.log(err);
            if (!err?.response) {
              setErrorMsg("No Server Response");
            } else if (
              err.response?.status === 409 ||
              err.response?.status === 401
            ) {
              setErrorMsg(err.response?.data.msg);
            } else {
              setErrorMsg("Error, try again.");
            }
            setIsError(true);
          });
      } else {
        await turn_on_printer(token)
          .then((res) => {
            dispatch(
              setIsPrinting({
                isPrinting: newIsPrintingValue,
              })
            );
            setIsLoading(false);
            closePopup(false);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
            if (!err?.response) {
              setErrorMsg("No Server Response");
            } else if (
              err.response?.status === 409 ||
              err.response?.status === 401
            ) {
              setErrorMsg(err.response?.data.msg);
            } else {
              setErrorMsg("Error, try again.");
            }
            setIsError(true);
          });
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 409 || err.response?.status === 401) {
        setErrorMsg(err.response?.data.msg);
      } else {
        setErrorMsg("Error, try again.");
      }
      setIsError(true);
    }
  };
  console.log(isPrinting, 999);
  return (
    <div>
      {isLoading && <Loading full={true} />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        />
      )}
      <Popup title={"Settings"}>
        <CustomForm
          handleSubmit={(e) => handelSubmit(e)}
          button={
            <div>
              <Button
                type="submit"
                disabled={isPrinting === newIsPrintingValue || isPrinting === 2}
              >
                <p>Update</p>
              </Button>
              <Button onClick={() => closePopup(true)}>
                <p>Close</p>
              </Button>
            </div>
          }
        >
          {isPrinting === 2 ? (
            <div className="span-2">
              <ShortNote
                text={
                  "Printing is not enabeled for your company, please contact your admin to get the privilage."
                }
              />
            </div>
          ) : (
            <div className="span-2">
              <div className="flex gap-4 items-center justify-center">
                <p>Is printing orders:</p>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={newIsPrintingValue == 1}
                        onChange={() => {
                          setNewIsPrintingValue(
                            newIsPrintingValue == 1 ? 0 : 1
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              </div>
            </div>
          )}
        </CustomForm>
      </Popup>
    </div>
  );
}
export default SettingsForCashier;
