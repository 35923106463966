import React, { useEffect, useState } from 'react'
import Popup from '../../../Components/Popups/Popup'
import { Button, Dialog } from '@mui/material'
import { Close } from '@mui/icons-material'
import CustomSelector from '../../Widgets/CustomSelector'
import CustomMultipleSelect from '../../Widgets/CustomMultipleSelect'
import CustomTextfield from '../../Widgets/CustomTextfield'

function ItemPopup({ item, handleOnClose, isOpen }) {
  const [price, setPrice] = useState(Math.min.apply(Math, item.variations.map(e => e.price)))
  const [quantity, setQuantity] = useState(1)
  const [variation, setVariation] = useState(item.variations.filter(e => e.price == Math.min.apply(Math, item.variations.map(e => e.price)))[0].id)
  const [addonPrice, setAddonPrice] = useState(0)
  const [selectedAddon, setSelectedAddon] = useState([])
  const [specialInstruction, setSpecialIntruction] = useState("")
  useEffect(() => {
    // console.log(item.variations.filter(e => e.price === Math.min.apply(Math, item.variations.map(e => e.price)))[0].name)
    // setPrice(Math.min.apply(Math, item.variations.map(e => e.price)))
    // setVariation(item.variations.filter(e => e.price === Math.min.apply(Math, item.variations.map(e => e.price)))[0].name)
  }, [])

  const handleChangeAddon = (newList) => {
    setSelectedAddon(newList)
    setAddonPrice(newList.map(e => e.price).reduce((partialSum, a) => partialSum + a, 0))
    console.log(newList.map(e => e.price).reduce((partialSum, a) => partialSum + a, 0))
  }
  return (
    <Dialog
      closeOnEscape={true}
      open={isOpen}
      fullWidth
    PaperProps={{ sx: { height: "fit-content", borderRadius:"8px !important" } }}
    >
      <div className="w-full flex flex-col py-4 bg-color-whites">
        <div className='pt-1 pb-4 px-4'>
          <div className='flex flex-row justify-between'> {item.item_name} <Close onClick={handleOnClose} className='cursor-pointer' /></div>
        </div>
        <hr className='pb-2' />
        <div className='px-4 w-auto h-auto max-h-96 flex flex-col justify-center items-center overflow-hidden'>
          <img className='h-full' src={item.image}></img>

        </div>
        <div className='w-full justify-center items-center flex flex-col'>
          <div className="text-2xl font-medium p-4">
            {item.item_name}
          </div>
          <div>{
            (price + addonPrice) * quantity
          } Br.</div>
          <div className='my-4 flex font-semibold items-center select-none justify-center bg-gray-100 w-full'>
            <div onClick={() => { if (quantity > 1) setQuantity(quantity - 1) }} className='bg-gray-300 rounded-full w-8 h-8 flex justify-center items-center cursor-pointer'>- </div>
            <div className='p-4 flex'> {quantity}</div>
            <div onClick={() => setQuantity(quantity + 1)} className='bg-gray-300 rounded-full w-8 h-8 flex justify-center items-center cursor-pointer'>+ </div>
          </div>

        </div>
        <div className="px-4">
          <CustomSelector
            required={true}
            label={"Select Options"}
            value={variation}
            onChange={(e) => {
              console.log(e.target.value)
              setVariation(e.target.value);
              setPrice(item.variations.filter((variation) => { if (variation.id == e.target.value) { return variation } })[0].price)
            }}
            menuItems={item.variations.map((e) => ({ id: e.id, name: e.name }))}
          />
        </div>
        {
          item.addons.length ?
            <div className="px-4 my-4 w-full">
              {/* <CustomSelector
            required={true}
            label={"Select Options"}
            value={addon}
            onChange={(e) => {
              setAddon(e.target.value);
              setAddonPrice(item.addons.filter((addon) => { if (addon.id == e.target.value) { return addon } })[0].price)
            }}
            menuItems={[ {id:0, name:"--- Select addon ---"},...item.addons.map((e) => ({ id: e.id, name: e.name }))]}
          /> */}
              <CustomMultipleSelect
                fullWidth={true}
                required
                placeholder={"Select Addon"}
                selected={selectedAddon}
                options={item.addons}
                setSelected={handleChangeAddon}
              />
            </div> : null}
        <div className="text-lg font-small pb- flex w-full justify-center">
          Special Instruction
        </div>
        <div className="px-4 w-full">
          <CustomTextfield
            multiline
            label={"Special instruction"}
            value={specialInstruction}
            onChange={(e) => {
              if(e.target.value.length<400)
              setSpecialIntruction(e.target.value)}}
          //  required
          />
        </div>
        <div className="py-4 px-4 pb-0 flex justify-end gap-2">
          <Button
           onClick={handleOnClose}
          // onClick={() => console.log(selectedBranches)}
          >
            <p>Cancel</p>
          </Button>
          <Button
            type="submit"
          // onClick={() => console.log(selectedBranches)}
          >
            <p>Add to order</p>
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default ItemPopup