import React from "react";

function FourStatCard({
  subName1,
  subName2,
  amount1,
  amount2,
  subName3,
  subName4,
  amount3,
  amount4,
  Icon,
}) {
  return (
    <div className=" rounded-lg shadow-lg bg-slate-100 h-36 w-96 flex justify-around items-center">
      <div className="flex flex-col">
        <div className=" h-fit flex gap-2">
          <div className="flex flex-col">
            <p>{subName1}</p>
            <p className="text-lg font-bold">{amount1}</p>
          </div>
          <div className=" w-1 bg-gray-400"></div>
          <div className="flex flex-col">
            <p>{subName2}</p>
            <p className="text-lg font-bold">{amount2}</p>
          </div>
        </div>
        <div className=" h-fit flex gap-2">
          <div className="flex flex-col">
            <p>{subName3}</p>
            <p className="text-lg font-bold">{amount3}</p>
          </div>
          <div className="flex-1 w-1 bg-gray-400"></div>
          <div className="flex flex-col">
            <p>{subName4}</p>
            <p className="text-lg font-bold">{amount4}</p>
          </div>
        </div>
      </div>
      {Icon}
    </div>
  );
}

export default FourStatCard;
