import React, { useState } from "react";
import "./Pricing.css";
import { Button } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled, alpha } from "@mui/material/styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import OptionsMenu from "../../../Components/OptionsMenu/OptionsMenu";
import { fakedata } from "../../../Constants/DataaseValues";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function PricingTable({
  branches,
  handleRemoveBranch,
  handleEditVariation,
  handleEditAddon,
  editAddonLabel,
  editVariationLabel,
  editVariationName,
  editAddonName,
  addVariation,
  addAddon,
  removeVariation,
  removeAddon,
  handleVariationPrice,
  handleAddonPrice,
  variationLabel,
  addonLabel,
  variationList,
  addonList,
  isUpdating,
}) {
  // const []
  const [editingVariationName, setEditingVariationName] = useState({
    id: null,
    name: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClicked = (x) => {
    console.log(x.currentTarget.id);
  };
  return (
    <div className="pricing-table span-2">
      {/* <div className="branch-name-title">Branch Name</div>
      <div className="variations-column"></div>
      <div className="addon-column"></div> */}

      <table>
        <thead>
          <tr>
            <th
              rowSpan={"2"}
              style={{
                backgroundColor: "#E3E3E3",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <h5>Branch Name </h5>
            </th>

            <th
              colspan={String(variationList.length)}
              style={{ backgroundColor: "#FFD874" }}
            >
              {/* <th colspan={String(branches[0].variation.length)}> */}
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                  // width: "250px"
                  position: "relative",
                }}
              >
                <input
                  disabled={isUpdating}
                  required
                  value={variationLabel}
                  type="string"
                  onChange={(v) => {
                    if (v.target.value.indexOf(" ") !== -1) {
                    } else {
                      editVariationLabel(v.target.value);
                    }
                  }}
                  className="input-table-header"
                  style={{
                    margin: "0 0 0 10px",

                    // width: "100px"
                    fontWeight: "bold",
                  }}
                />
               {isUpdating?null: <Button
                  style={{
                    background: "rgb(62, 62, 216)",
                    color: "white",
                    marginRight: "10px",
                    // paddingLeft: "1rem",
                    paddingRight: ".8rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "5px",
                    height: "30px",
                    minWidth: "fit-content",
                    fontSize: ".8rem",
                    // position: "absolute",
                    right: "0",
                  }}
                  onClick={addVariation}
                >
                  + Add {variationLabel}
                </Button>}
              </div>
            </th>
            <th
              colspan={String(addonList.length ? addonList.length : 1)}
              style={{ backgroundColor: "#DEEBFA" }}
            >
              {/* <th colspan={String((branches.length && branches[0].addon.length) ? branches[0].addon.length:1)}> */}
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                  // width: "200px",
                  position: "relative !important",
                }}
              >
                <input
                  disabled={isUpdating}

                  required
                  value={addonLabel}
                  type="string"
                  onChange={(v) => {
                    console.log(v.target.value.indexOf(" "));
                    if (v.target.value.indexOf(" ") !== -1) {
                    } else {
                      editAddonLabel(v.target.value);
                    }
                  }}
                  className="input-table-header"
                  style={{
                    // padding: "0 !important",
                    // marging: "0",
                    // width: "100px"
                    fontWeight: "bold",
                    margin: "0 0 0 10px",
                  }}
                />
                {/* {addonLabel} */}
                {isUpdating?null: <Button
                  style={{
                    background: "rgb(62, 62, 216)",
                    color: "white",
                    marginRight: "10px",
                    // paddingLeft: "1rem",
                    paddingRight: ".8rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "5px",
                    height: "30px",
                    fontSize: ".8rem",
                    position: "absolute !important",
                    right: "0",
                    minWidth: "fit-content",
                  }}
                  onClick={addAddon}
                >
                  + Add {addonLabel}
                </Button>}
              </div>
            </th>
          </tr>
          <tr>
            {variationList.map((variation_item) => (
              // {branches[0].variation.map((e) => (
              <th
                style={{
                  backgroundColor:
                    editingVariationName.id !== variation_item.id
                      ? "#FFF3D5"
                      : "#ffffff",
                }}
              >
                <h5
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* {e} */}
                  <input
                  disabled={isUpdating}

                    min={0}
                    required
                    value={
                      editingVariationName.id == variation_item.id
                        ? editingVariationName.name
                        : variation_item.name
                    }
                    // disabled={editingVariationName.id !== variation_item.id}
                    type="string"
                    onChange={(v) =>
                      editVariationName(
                        variation_item.id,
                        v.target.value
                      )
                      // {
                      //   setEditingVariationName({
                      //     id: variation_item.id,
                      //     name: v.target.value,
                      //   });
                      // }
                    }
                    className="input-table-header"
                    style={{
                      padding: "0 !important",
                      marging: "0",
                    }}
                  />
                  {/* <MoreVertIcon /> */}
                  {/* {editingVariationName.id != variation_item.id && <> */}
                  {/* <MoreVertIcon
                    id={"demo-customized-button" + variation_item.id}
                    aria-controls={
                      open
                        ? "demo-customized-menu" + variation_item.id
                        : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    // endIcon={<KeyboardArrowDownIcon />}
                    style={{ cursor: "pointer" }}
                  ></MoreVertIcon>
                  <StyledMenu
                    key={`${variation_item.id}`}
                    id={`${variation_item.id}`}
                    // id={"demo-customized-menu" + variation_item.id}
                    MenuListProps={{
                      "aria-labelledby":
                        "demo-customized-button" + variation_item.id,
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={(event) => {
                      handleClose(event);
                    }}
                    onClick={(ev) => {
                      // console.log(ev.currentTarget.getAttribute('data-id'));
                      handleMenuClicked(ev);
                    }}
                  >
                    <MenuItem
                      id={String(variation_item.id)}
                      key={String(variation_item.id)}
                      data-id={variation_item.id}
                      onClick={(f) => {
                        // handleMenuClicked(f)
                        // console.log(f.currentTarget.getAttribute('data-id'));
                        handleClose(f);
                        setEditingVariationName({
                          id: variation_item.id,
                          name: variation_item.name,
                        });
                      }}
                      disableRipple
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      disableRipple
                      style={{ color: "red" }}
                      onClick={() => {
                        handleClose();
                        removeVariation(variation_item.id);
                      }}
                    >
                      Delete
                    </MenuItem>
                  </StyledMenu> */}
                  {/* </> */}
                  {/* } */}
                  {/* {editingVariationName.id == variation_item.id && (
                    <SaveOutlinedIcon
                      onClick={() => {
                        editVariationName(
                          variation_item.id,
                          editingVariationName.name
                        );
                        setEditingVariationName({
                          id: null,
                          name: "",
                        });
                      }}
                      sx={{ color: "green" }}
                    />
                  )} */}
                 { isUpdating?null: <DeleteOutlineOutlinedIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => removeVariation(variation_item.id)}
                  />}
                </h5>
              </th>
            ))}

            {addonList.map((e) => (
              <th style={{ backgroundColor: "#F9FCFF" }}>
                <h5
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <input
                    disabled={isUpdating}
                    id={addonList.indexOf(e)}
                    min={0}
                    required
                    value={e.name}
                    type="string"
                    onChange={(v) => editAddonName(e.id, v.target.value)}
                    className="input-table-header"
                    style={{
                      padding: "0 !important",
                      marging: "0",
                    }}
                  />

                 { isUpdating?null: <DeleteOutlineOutlinedIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => removeAddon(e.id)}
                  />}
                </h5>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {branches.map((e) => (
            <tr>
              <td className="padding-left">
                <p style={{ fontWeight: "500" }}>{e.name}</p>
              </td>
              {e.variation.map((variaty) => (
                <td className="padding-left" style={{ color: "blue" }}>
                  {
                    <input
                      value={variaty.price}
                      onChange={(v) =>
                        handleVariationPrice(
                          e.id,
                          variaty.id,
                          v.target.value
                        )
                      }
                      className="input-table"
                      type="number"
                      style={{
                        // border: "1px solid red",
                        padding: "0 !important",
                        marging: "0",
                        // width: "20px",
                        // "&.hover":"none"
                      }}
                    />
                  }
                </td>
              ))}
              {e.addon.map((anaddon) => (
                <td className="padding-left" style={{ color: "blue" }}>
                  {
                    <input
                      min={0}
                      required
                      value={anaddon.price}
                      type="number"
                      onChange={(v) =>
                        handleAddonPrice(
                          e.id,
                          anaddon.id,
                          // e.addon.id,
                          v.target.value
                        )
                      }
                      className="input-table"
                      style={{
                        padding: "0 !important",
                        marging: "0",
                      }}
                    />
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {/* {
        fakedata.map(e=>{
          <>
          <h1>{e.blog_name}</h1>
          
          
          </>
        })
      } */}
    </div>
  );
}

export default PricingTable;

// <MoreVertIcon
//                     id="demo-customized-button"
//                     aria-controls={open ? "demo-customized-menu" : undefined}
//                     aria-haspopup="true"
//                     aria-expanded={open ? "true" : undefined}
//                     variant="contained"
//                     disableElevation
//                     onClick={handleClick}
//                     endIcon={<KeyboardArrowDownIcon />}
//                     style={{ cursor: "pointer" }}
//                   >
//                     {/* Options */}
//                   </MoreVertIcon>
//                   <StyledMenu
//                     id="demo-customized-menu"
//                     MenuListProps={{
//                       "aria-labelledby": "demo-customized-button",
//                     }}
//                     anchorEl={anchorEl}
//                     open={open}
//                     onClose={handleClose}
//                   >
//                     <MenuItem onClick={handleClose} disableRipple>
//                       {/* <EditIcon /> */}
//                       Edit
//                     </MenuItem>
//                     <MenuItem
//                       disableRipple
//                       style={{ color: "red" }}
//                       onClick={() => {
//                         handleClose();
//                         removeAddon(addonList.indexOf(e));
//                         console.log(e)

//                       }}
//                     >
//                       {/* <FileCopyIcon /> */}
//                       Delete
//                     </MenuItem>
//                     {/* <Divider sx={{ my: 0.5 }} />
//                     <MenuItem onClick={handleClose} disableRipple>
//                       <ArchiveIcon />
//                       Archive
//                     </MenuItem>
//                     <MenuItem onClick={handleClose} disableRipple>
//                       <MoreHorizIcon />
//                       More
//                     </MenuItem> */}
//                   </StyledMenu>
