import { URLs } from "../../api/URLs";
import axios from "../../api/axios";

export const change_password = async (token, params) => {
    const response = await axios
      .post(
        URLs.CHANGE_PASSWORD,
        JSON.stringify({
          oldPassword: params.oldPassword,
          newPassword:params.newPassword
        }),
        {
          headers: { "Content-Type": "application/json", "x-auth-token": token },
          withCredentials: true,
        }
      )
      .then((res) => {
        return res?.data?.msg;
      })
      .catch((error) => {
        console.log(error, "error changing password")
        throw error;
      });
    return response;
  };