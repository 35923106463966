import React from 'react'
import Navbar from '../Navbar/Navbar'

function DashoboardTemplate({children}) {
  return (
    <div className="App">
    <div className="sidebar">
      <Navbar />
    </div>
    <div className="mainBody">
      {children}
    </div>
  </div>
  )
}

export default DashoboardTemplate