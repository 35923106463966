import { Settings } from "@mui/icons-material";
import UpdateStatus from "Components/UpdateStatus/UpdateStatus";
import { getPaymentMethodStatus } from "Services/Manager/manager_services";
import { URLs } from "api/URLs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SecretKeyOption from "./SecretKeyOption";

function PaymentMethod() {
  const [paymentMethods, setPaymentMethods] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const token = useSelector((state) => state.token);
  const [chapaSettingVisible, setChapaSettingVisible] = useState(false);

  const getPaymentMethods = async () => {
    const [payments] = await getPaymentMethodStatus(token);
    setPaymentMethods(payments);
    console.log(payments, 888);
    setIsLoading(false);
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);
  return (
    <div>
      {chapaSettingVisible ? (
        <SecretKeyOption
          closePopup={() => {
            setChapaSettingVisible(false);
            getPaymentMethods();
          }}
          secretKeyApi={paymentMethods.chapa_secret_key}
        />
      ) : null}
      <div
        className="top-stick-section flex justify-between p-8 sticky bg-white z-10 w-full border"
        style={{
          borderBottom: "3px solid  rgb(247, 246, 246)",
          height: "7rem",
        }}
      >
        <div className="screen-title">
          <h4 className="text-black font-bold text-xl">Payment Methods</h4>
          <p>Currently Active and Inactive Payment Methods</p>
        </div>
      </div>
      {!isLoading ? (
        <div className="flex">
          <div className="flex border-grey rounded-md border w-fit p-4 h-28 justify-between gap-2 items-center m-2">
            <div className="image w-20 h-full overflow-hidden mr-2 flex justify-center items-center rounded">
              <p>Cash Payments</p>
            </div>
            <div className="info flex items-start justify-between">
              <p>
                <UpdateStatus
                  api={URLs.UPDATE_COMPANY_PAYMENT_METHODS}
                  row={{ id: 0, status: paymentMethods.cash_payment }}
                  reloadData={getPaymentMethods}
                />
              </p>
            </div>
          </div>
          <div className="flex border-grey rounded-md border w-fit p-4 h-28 justify-between gap-2 items-center m-2">
            <div className="image w-20 h-full overflow-hidden mr-2 flex justify-center items-center rounded">
              <p>Chapa Payments</p>
            </div>
            <div className="info flex items-start justify-between">
              <p>
                <UpdateStatus
                  api={URLs.UPDATE_COMPANY_PAYMENT_METHODS}
                  row={{ id: 1, status: paymentMethods.chapa_payment }}
                  reloadData={getPaymentMethods}
                />
              </p>
            </div>
            <div className="info flex items-start justify-start cursor-pointer">
              <p onClick={() => setChapaSettingVisible(true)}>
                <Settings />
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default PaymentMethod;
