import React, { useState } from "react";
import Popup from "../../../Components/Popups/Popup";
import CustomTextfield from "../../Widgets/CustomTextfield";
import CustomForm from "../../../Components/CustomForm/CustomForm";
import { Button } from "@mui/material";
import Loading from "../../Widgets/Loading";
import ApiResult from "../../Widgets/ApiResult";
import { add_new_branch } from "../../../Services/Manager/manager_services";
import { useSelector } from "react-redux";

function AddNewBranch({ closePopup }) {
  const token = useSelector((state) => state.token);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong, try again!");

  const handelSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const param = {
        name: name,
        address: address 
      };
      const res = await add_new_branch(token, param);
      setIsLoading(false);
      closePopup(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 409 || err.response?.status === 401) {
        setErrorMsg(err.response?.data.msg);
      } else {
        setErrorMsg("Couldn't add branch, try again.");

      }
      setIsError(true);
     
    }
  };  

  return (
    <div>
        {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        />
      )}
      <Popup title={"Add new branch"}>
        <CustomForm
          handleSubmit={(e) => handelSubmit(e)}

          button={
            <div>
              <Button type="submit"
              disabled={
                String(name).trim() == "" ||
                String(address).trim() == ""  
                 
              }
              >
                <p>Add</p>
              </Button>
              <Button onClick={() => closePopup(true)}>
                <p>Close</p>
              </Button>
            </div>
          }
        >
          <CustomTextfield
            label={"Branch Name"}
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder={"branch name"}
            required={true}
          />
          <CustomTextfield
            label={"Branch Address"}
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            placeholder={"branch address"}
            required={true}
          />
        </CustomForm>
      </Popup>
    </div>
  );
}

export default AddNewBranch;
