import React from "react";

function StatCard({ name,amount, Icon, two }) {
  return (
    <div className=" rounded-lg shadow-lg bg-slate-100 h-36 w-64 flex justify-around items-center">
      <div className="flex flex-col">
        <p>{name}</p>
        <p className="text-lg font-bold">{amount}</p>
      </div>
      {Icon}
    </div>
  );
}

export default StatCard;
