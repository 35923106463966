import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import React, { useState } from "react";
import {
  updateCompanyService,
  update_row_status,
} from "../../Services/Manager/manager_services";
import ApiResult from "../../Scenes/Widgets/ApiResult";
import { useSelector } from "react-redux";

function UpdateStatus({
  api,
  row,
  reloadData,
  isService,
  service_id,
  branch_id,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(row.status);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState("Something went wrong");
  const token = useSelector((state) => state.token);

  const handleChangeStatus = async () => {
    setIsLoading(true);
    setStatus(!status);
    if (isService) {
      await updateCompanyService(token, api, branch_id, service_id)
        .then((response) => {
          reloadData(row.id);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsError(true);
          setIsLoading(false);
          setStatus(status);
        });
    } else {
      await update_row_status(token, row.id, api, !status)
        .then((response) => {
          reloadData(row.id);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsError(true);
          setIsLoading(false);
          setStatus(status);
        });
    }
  };

  console.log(row.status, 333, status)

  return (
    <>
      {isError && (
        <ApiResult
          message={`${errMsg}`}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        />
      )}

      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={status}
              onChange={() => {
                handleChangeStatus();
              }}
              disabled={isLoading || row.status === -1}
            />
          }
        />
      </FormGroup>
    </>
  );
}

export default UpdateStatus;
