import React from "react";

function TwoStatCard({
  name,
  subName1,
  subName2,
  amount1,
  amount2,
  Icon,
  amount
}) {
  return (
    <div className=" rounded-lg shadow-lg bg-slate-100 h-36 w-96 flex justify-around items-center">
      <div className="flex flex-col">
        {" "}
        <p className="  text-center">{name}</p>
        <p className="text-lg font-bold text-center mb-2">{amount}</p>
        <div className=" h-fit flex gap-2">
          <div className="flex flex-col">
            <p>{subName1}</p>
            <p className="text-lg font-bold">{amount1}</p>
          </div>
          <div className="flex-1 w-1 bg-gray-400"></div>
          <div className="flex flex-col">
            <p>{subName2}</p>
            <p className="text-lg font-bold">{amount2}</p>
          </div>
        </div>
      </div>
      {Icon}
    </div>
  );
}

export default TwoStatCard;
