import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetch_orders } from "../../../Services/Cashier/cashier_services";
import OrdersTab from "./Widgets/OrdersTab";
import ApiResult from "../../Widgets/ApiResult";
import Loading from "../../Widgets/Loading";
import useSound from "use-sound";
import beep from "../../../Assets/Sounds/beep.mp3";
import { Button } from "@mui/material";
function HandleOrders() {
  const [playSound] = useSound(beep, { forceSoundEnabled: true });

  const token = useSelector((state) => state.token);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong");
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [tabLocation, setTabLocation] = useState(0);
  const [isLoading, setIsLoading] = useState(false); 
  const getOrders = async () => {
    setIsLoading(true);
    await fetch_orders(token)
      .then((response) => {
        setAllOrders(response.msg);
        setIsLoading(false);
        if (response.msg && response.msg.filter((e) => e.status == 0).length) {
          playSound();
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setIsNetworkError(true);
      });
  };
  const getOrdersInTheBackground = async () => {
    await fetch_orders(token)
      .then((response) => {
        setAllOrders(response.msg);
        console.log(response.msg.filter((e) => e.status == 0).length);
        if (response.msg && response.msg.filter((e) => e.status == 0).length) {
          playBeep()
        }
      })
      .catch((e) => {
        setIsNetworkError(true);
      });
  };

  useEffect(() => {
    getOrdersInTheBackground();
    const interval = setInterval(() => getOrdersInTheBackground(), 10000);

   
    return () => {
      clearInterval(interval);
    };
  }, []);
 

   const playBeep=()=>{
    document.getElementById("backgroundMusic").play().catch((error) => {
      document.addEventListener('click', () => {
        document.getElementById("backgroundMusic").play()
      }, { once: true } )
  })}
  

  return (
    <div className="h-screen flex flex-col item">
      <audio id="backgroundMusic">
        <source src={beep} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      
      {isLoading && <Loading />}
      {isNetworkError && (
        <ApiResult
          message={"Network Error"}
          handleClose={() => {
            setIsNetworkError(false);
          }}
        ></ApiResult>
      )}
      <div className="tabs flex text-sm p-4 justify-stretch w-192 font-semibold cursor-pointer">
        <div
          onClick={() => setTabLocation(0)}
          className="border p-4 text-center rounded-tl-lg"
          style={{ background: tabLocation == 0 ? "lightblue" : "white" }}
        >
          New Order {" ("}
          {allOrders.filter((orders) => orders.status == 0).length}
          {")"}
        </div>
        <div
          onClick={() => setTabLocation(1)}
          className="border p-4 text-center "
          style={{ background: tabLocation == 1 ? "lightyellow" : "white" }}
        >
          Pending Order{" ("}
          {allOrders.filter((orders) => orders.status == 1).length}
          {")"}
        </div>
        <div
          onClick={() => setTabLocation(7)}
          className="border p-4 text-center "
          style={{ background: tabLocation == 7 ? "lightgreen" : "white" }}
        >
          Completed Order{" ("}
          {allOrders.filter((orders) => orders.status == 7).length}
          {")"}
        </div>
        <div
          onClick={() => setTabLocation(6)}
          className="border p-4 text-center  rounded-tr-lg"
          style={{ background: tabLocation == 6 ? "lightcoral" : "white" }}
        >
          Canceled Order{" ("}
          {allOrders.filter((orders) => orders.status == 6).length}
          {")"}
        </div>
      </div>
      <OrdersTab
        orders={allOrders.filter((orders) => orders.status == tabLocation)}
        handleUpdateList={(newlist) => setAllOrders(newlist)}
        isLoading={(value) => setIsLoading(value)}
        tabLocation={tabLocation}
      />
    </div>
  );
}

export default HandleOrders;
