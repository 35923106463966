import React, { useEffect, useState } from "react";
import ServiceCard from "./ServiceCard";
import { getCompanyServices } from "Services/Manager/manager_services";
import { useSelector } from "react-redux";

function CompanyServices() {
  const [services, setServices] = useState([]);
  useEffect(() => {
    getServices();
  }, []);
  const token = useSelector((state) => state.token);
  // getting all available services and company state of the service
  const getServices = async () => {
    await getCompanyServices(token)
      .then((res) => {
        console.log(res, "services");
        setServices(res);
      })
      .catch((e) => console.log(e, "error in get services"));
  };

  const reloadData = () => {
    setServices([]);
    getServices();
  };
  // togle state of service by adding and subtracting row in branch service mapping
  return (
    <>
      <div
        className="top-stick-section flex justify-between p-8 sticky bg-white z-10 w-full border"
        style={{
          borderBottom: "3px solid  rgb(247, 246, 246)",
          height: "7rem",
        }}
      >
        <div className="screen-title">
          <h4 className="text-black font-bold text-xl">Services</h4>
          <p>Order Method types</p>
        </div>
      </div>

      <div
        className=" overflow-auto items-start p-4 flex flex-wrap gap-2 justify-start"
        style={{ maxHeight: "calc(100dvh - 7rem)" }}
      >
        {services && services.length
          ? services.map((service) => (
              <ServiceCard
                title={service.name}
                branches={service.branches}
                reloadData={reloadData}
                service_id={service.id}
              />
            ))
          : null}
      </div>
    </>
  );
}

export default CompanyServices;
