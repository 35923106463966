import { update_company_category } from "../../../Services/Manager/manager_services";
import Loading from "../../Widgets/Loading";
import ApiResult from "../../Widgets/ApiResult";
import React, { useState } from "react";
import Popup from "../../../Components/Popups/Popup";
import CustomTextfield from "../../Widgets/CustomTextfield";
import CustomForm from "../../../Components/CustomForm/CustomForm";
import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useSelector } from "react-redux";

function UpdateCategory({ closePopup, category }) {
  const token = useSelector((state) => state.token);

  const [name, setName] = useState(category.name);
  const [status, setStatus] = useState(category.status == 0 ? false : true);

  console.log(category.status, status);

  const [isLoading, setIsLoading] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong, try again!");

  const handelSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const param = {
        name: name,
        id: category.id,
        status: getTinyInt(status)
      };
      await update_company_category(token, param);
      setIsLoading(false);
      closePopup(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 409 || err.response?.status === 401) {
        setErrorMsg(err.response?.data.msg);
      } else {
        setErrorMsg("Couldn't add branch, try again.");
      }
      setIsError(true);
    }
  };

  return (
    <div>
      {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        />
      )}
      <Popup title={"Add new category"}>
        <CustomForm
          handleSubmit={(e) => handelSubmit(e)}
          button={
            <div>
              <Button
                type="submit"
                disabled={
                  String(name).trim() === "" ||
                  (status == category.status &&
                    String(name).trim() === category.name)
                }
              >
                <p>Update</p>
              </Button>
              <Button onClick={() => closePopup(true)}>
                <p>Close</p>
              </Button>
            </div>
          }
        >
          <CustomTextfield
            label={"Name"}
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder={"category name"}
            required={true}
            span
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={status} onChange={() => setStatus(!status)} />
              }
              label="Status"
            />
          </FormGroup>
        </CustomForm>
      </Popup>
    </div>
  );
}

export default UpdateCategory;

const getTinyInt=(bool)=>{
  if (bool){
    return 1
  }
  return 0
}