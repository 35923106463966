import React, { useEffect, useState } from "react";
import Popup from "../../../Components/Popups/Popup";
import Loading from "../../Widgets/Loading";
import ApiResult from "../../Widgets/ApiResult";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import ShortNote from "../../../Components/ShortNote/ShortNote";
import {
  add_new_users,
  check_username,
  fetch_companies,
  fetch_company_branches,
  update_users,
} from "../../../Services/Admin/admin_services";
import { useSelector } from "react-redux";
import CustomForm from "../../../Components/CustomForm/CustomForm.jsx";
import CustomTextfield from "../../Widgets/CustomTextfield";
import CustomSelector from "../../Widgets/CustomSelector";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { AccountCircle, CheckCircleOutline } from "@mui/icons-material";
import { getRole, getStatus } from "../../../Constants/DataaseValues";

function UpdateUserProfile({ closePopup, user }) {
  const token = useSelector((state) => state.token);
  const userState = useSelector(state=>state.user)

  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [username, setUsername] = useState(user.username);
  const [phone, setPhone] = useState(user.phone);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [branchId, setBranchId] = useState(user.branchId);
  const [role, setRole] = useState(user.roleId);
  //   const [role, setRole] = useState(rolesList.indexOf(user.role));
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error, try again!");
  const [status, setStatus] = useState(user.statusId);
  const [branches, setBranches] = useState([]);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
  const [isResePassword, setIsResetPassword] = useState(false);
  // DEBOUCE STUFF
  // const useSearchStarwarsHero = () =>
  //   useDebouncedSearch((text) => handleCheckUsername(username));

  // const { inputText, setInputText, searchResults } = useSearchStarwarsHero();

  // FETCH BRANCHES
  const getBranches = async () => {
    try {
      setBranches([]);
      setBranchId("");
      //   if (role == 2) setRole(1);

      const branchesData = await fetch_company_branches(token, companyId);
      setBranches(branchesData);
      if (branchesData.length) {
        setBranchId(branchesData[0].id);
      }
    } catch (error) {
      setErrorMsg("Couldn't load company branches, try again.");
      setIsError(true);
    }
  };
  useEffect(() => {
    getBranches();
  }, [companyId]);

  const handelSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const param = {
        id: user.id,
        first_name: firstName,
        last_name: lastName,
        username: username,
        phone: phone,
        branch_id: branchId,
        company_id: companyId,
        role: role,
        password: isResePassword ? "12345678" : null,
        status: status,
      };
      const res = await update_users(token, param, userState.role==3);
      setIsLoading(false);
      setpopuphid(true)
      closePopup(false)
    //   setTimeout(()=>{ closePopup(false)},400)
    } catch (error) {
      setIsLoading(false);

      setErrorMsg("Couldn't add user, try again.");
      setIsError(true);
    }
  };

  // CHECK USERNAME

  const handleCheckUsername = async (username) => {
    // setIsUsernameAvailable(null);
    try {
      if (username === user.username) {
        setIsUsernameAvailable(null);
      } else {
        setTimeout(async () => {
          const usernameAvailablity = await check_username(token, username);
          setIsUsernameAvailable(usernameAvailablity);
        }, 2000);
      }
    } catch (error) {
      setIsLoading(false);

      setErrorMsg("Couldn't check username availiblity, try again.");
      setIsError(true);
    }
  };
  const [popuphid, setpopuphid] = useState(false)
  // DEBOUNC
  return (
    <div>
      {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        />
      )}
      <Popup title={"Update User Profile"} closePopup={popuphid}>
        <CustomForm
          handleSubmit={(e) => handelSubmit(e)}
          style={{ width: "100% !important" }}
          width={"100%"}
          button={
            <div>
              <Button
                type="submit"
                disabled={
                  String(firstName).trim() === "" ||
                  String(lastName).trim() === "" ||
                  String(username).trim() === "" ||
                  String(phone).trim() === "" ||
                  String(companyId).trim() === "" ||
                  String(companyId).trim() === "0" ||
                  String(branchId).trim() === "0" ||
                  String(branchId).trim() === "" ||
                  String(role).trim() === "" ||
                  (String(firstName).trim() === user.firstName &&
                    String(lastName).trim() === user.lastName &&
                    String(username).trim() === user.username &&
                    String(phone).trim() === user.phone &&
                    String(companyId).trim() === String(user.companyId) &&
                    String(branchId).trim() === String(user.branchId) &&
                    String(role).trim() === String(user.roleId) &&
                    String(status).trim() === String(user.statusId) &&
                    !isResePassword) ||
                  role > 3 ||
                  role < 0 ||
                  (String(username).trim() !== user.username &&
                    !isUsernameAvailable)
                  // || !isResePassword
                }
              >
                <p>UPDATE</p>
              </Button>
              <Button onClick={() => {
                closePopup(true)
                // setpopuphid(true)
                // setTimeout(()=>{ closePopup(true)},400)
               }}>
                <p>Close</p>
              </Button>
            </div>
          }
        >
          {
            <>
              <CustomTextfield
                label={"First Name"}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required={true}
              />
              <CustomTextfield
                label={"Last Name"}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required={true}
              />

              <CustomTextfield
                label={
                  isUsernameAvailable == null
                    ? "Username"
                    : isUsernameAvailable == false
                    ? "Username taken"
                    : "Username available"
                }
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setIsUsernameAvailable(null);
                  if (String(e.target.value).trim())
                    handleCheckUsername(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {isUsernameAvailable == null ? (
                        // "@"
                        <AccountCircle />
                      ) : isUsernameAvailable == false ? (
                        <ReportProblemIcon style={{ color: "red" }} />
                      ) : (
                        <CheckCircleOutline style={{ color: "green" }} />
                      )}
                    </InputAdornment>
                  ),
                }}
                required={true}
              />

              <CustomTextfield
                label={"Phone-number"}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required={true}
              />
              <div className="text-field span-2">
                <CustomTextfield
                  label={"Company"}
                  value={user.companyName}
                  disabled={true}
                />
              </div>
              <CustomSelector
                required={true}
                label={"Status"}
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                menuItems={[
                  { id: 0, name: "Suspended" },
                  { id: 1, name: "Active" },
                ]}
              />
              <FormControlLabel
                sx={{ paddingLeft: "50px", paddingTop: "25px" }}
                control={
                  <Checkbox
                    label={"Reset Password"}
                    checked={isResePassword}
                    onChange={() => setIsResetPassword(!isResePassword)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={"Reset Password"}
              />
              <CustomSelector
                required={true}
                label={"Branch"}
                value={branchId}
                onChange={(e) => {
                  setBranchId(e.target.value);
                }}
                menuItems={branches.map((e) => ({ id: e.id, name: e.name }))}
              />
              <CustomSelector
                required={true}
                label={"Role"}
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
                menuItems={[
                  {
                    name: "Admin",
                    id: 2,
                    disabled: companyId !== 1 ? true : false,
                  },
                  {
                    name: "Marketing",
                    id: 3,
                    disabled: companyId !== 1 ? true : false,
                  },
                  { name: "Manager", id: 1 },
                  { name: "Cashier", id: 0 },
                ]}
              />
            </>
          }
        </CustomForm>
      </Popup>
    </div>
  );
}

export default UpdateUserProfile;
