import { left } from "@cloudinary/url-gen/qualifiers/textAlignment";
import { InputLabel, TextField } from "@mui/material";
import React from "react";

function CustomTextfield({
  label,
  onChange,
  value,
  required,
  placeholder,
  InputProps, disabled, type, span, multiline
}) {
  return (
    <div className={span?"text-field span-2":"text-field"  }>
      {/* <InputLabel id="outlined-basic">{label}</InputLabel> */}

      <TextField
      // margin="normal"
        type={type}
        disabled={disabled}
        label={label}
        id="outlined-basic"
        InputProps={InputProps}
        textAlign={left}
        multiline={multiline}
        // placeholder={placeholder ? placeholder : label}
        // borderRadius={"0.7rem !important"}
        required={required}
        sx={{
          backgroundColor: "white",
          // borderRadius: "0.7rem  !important",
          "*": { 
            // textAlign:"right !important"
            // borderRadius: "0.7rem !important"
           },
          
        }}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

export default CustomTextfield;
