import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { Checklist, ElectricMeterRounded, Hotel, LocationCity, MoneyOffCsredOutlined, MoneyOutlined, TableBar, Telegram } from "@mui/icons-material";
import MenuBookIcon from '@mui/icons-material/MenuBook';
export const admin_navbar_links = [
  {
    title: "Companies",
    Icon: RestaurantOutlinedIcon,
    path: "/companies",
  },
  {
    title: "Ads",
    Icon: CampaignOutlinedIcon,
    path: "/ads",
  },
  {
    title: "Users",
    Icon: GroupOutlinedIcon,
    path: "/users",
  },
  {
    title: "Services",
    Icon: CategoryOutlinedIcon,
    path: "/services",
  },
  {
    title: "Roles",
    Icon: AdminPanelSettingsOutlinedIcon,
    path: "/roles",
  },
  {
    title: "Company Activities",
    Icon: WorkHistoryOutlinedIcon,
    path: "/company-activities",
  },
  // {
  //   title: "Menu",
  //   Icon: MenuBookIcon,
  //   path: "/menu",
  // },
];
export const sales_navbar_links = [
  {
    title: "Companies",
    Icon: RestaurantOutlinedIcon,
    path: "/companies",
  },
  {
    title: "Ads",
    Icon: CampaignOutlinedIcon,
    path: "/ads",
  },
  {
    title: "Users",
    Icon: GroupOutlinedIcon,
    path: "/users",
  },  
];
export const manager_navbar_links = [
  {
    title: "Dashoboard",
    Icon: CampaignOutlinedIcon,
    path: "/manager-dashboard",
  },
  {
    title: "Branches",
    Icon: LocationCity,
    path: "/branches",
  },  {
    title: "Items",
    Icon: ElectricMeterRounded,
    path: "/items",
  },
  {
    title: "Categories",
    Icon: CategoryOutlinedIcon,
    path: "/categories",
  }, 
  {
    title: "Services",
    Icon: Hotel,
    path: "/company-services",
  }, 

 
  {
    title: "Payment Method",
    Icon: MoneyOutlined,
    path: "/company-payment",
  },
  {
    title: "Report",
    Icon: WorkHistoryOutlinedIcon,
    path: "/company-activities",
  },
  {
    title: "Telegram Integration",
    Icon: Telegram,
    path: "/telegram",
  },
  // {
  //   title: "Menu",
  //   Icon: MenuBookIcon,
  //   path: "/menu",
  // },
];

export const cashier_navbar_links = [
  {
    title: "Dashoboard",
    Icon: CampaignOutlinedIcon,
    path: "/cashier-dashboard",
  },
  {
    title: "Manage Items",
    Icon: CategoryOutlinedIcon,
    path: "/manage-items",
  }, 
  {
    title: "Orders",
    Icon: LocationCity,
    path: "/cashier-orders",
  },  {
    title: "Handle Orders",
    Icon: Checklist,
    path: "/handle-orders",
  },
  {
    title: "Reservations",
    Icon: TableBar,
    path: "/reservations",
  }, 
 
];
