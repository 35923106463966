import MainBodyStructure from "../../../Components/MainBody/MainBodyStructure";
import { fetch_company_branches } from "../../../Services/Admin/admin_services";
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useSelector } from "react-redux";
import { fetch_all_users } from "../../../Services/Admin/admin_services";
import { getStatus } from "../../../Constants/DataaseValues";
import {
  fetch_company_category,
  fetch_company_items,
} from "../../../Services/Manager/manager_services";
import ApiResult from "../../Widgets/ApiResult";
import AddNewCategory from "./AddNewCategory";
import UpdateCategory from "./UpdateCategory";
import DeleteCategory from "./DeleteCategory";

function Category() {
  const token = useSelector((state) => state.token);
  const company_id = useSelector((state) => state.user.company_id);
  const [category, setCategory] = useState([]);

  //ADD NEW CUSTOMER VARIABLES
  const [isAddCategorySuccessful, setAddCategorySuccessful] = useState(false);
  const [isAddCategoryFormVisible, setIsAddCategoryFormVisible] =
    useState(false);
  const [updatingCategory, setUpdatingCategory] = useState(null);
  const [deletingCategory, setDeletingCategory] = useState(null);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [isDeleteCategorySuccessful, setIsDeleteCategorySuccessful] = useState(false);


  const [isFail, setIsFail] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong");

  const [isEmpty, setIsEmpty] = useState(false);

  const openAddNewForm = async (open, cancel) => {
    if (open) {
      setIsAddCategoryFormVisible(true);
    } else {
      setIsAddCategoryFormVisible(false);
      if (!cancel) {
        setAddCategorySuccessful(true);
      }
    }
  };
  // UPDATE USER PROFIL
  const [isUpdateCategorySuccessful, setIsUpdateCategorySuccessful] =
    useState(false);
  const [isUpdateCategoryFormVisible, setUpdateCategoryFormVisible] =
    useState(false);

  const openUpdateForm = async (open, cancel) => {
    if (open) {
      setUpdateCategoryFormVisible(true);
    } else {
      setUpdateCategoryFormVisible(false);
      if (!cancel) {
        setIsUpdateCategorySuccessful(true);
      }
    }
  };

  //LOAD COMPANIES FROM STATE OR API
  const loadCategory = async () => {
    try {
      const categoryList = await fetch_company_category(token, company_id);
      const categoryModeled = categoryCell(categoryList);
      setCategory(categoryModeled);
      if (categoryList.length == []) {
        console.log("empty list");
        setIsEmpty(true);
      }
      console.log(categoryModeled, category, "poisonwho");
    } catch (err) {
      console.log(err, "error in fetch users function");
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 409 || err.response?.status === 401) {
        setErrorMsg(err.response?.data.msg);
      } else {
        setErrorMsg("Couldn't get categories, try again.");
      }
      setIsFail(true);
    }
  };

  // REFRESH TABLE DATA
  const refreshData = () => {
    setCategory([]);
    loadCategory();
  };
  useEffect(() => {
    loadCategory();
  }, []);

  useEffect(() => {
    if (isAddCategorySuccessful || isUpdateCategorySuccessful || isDeleteCategorySuccessful) {
      setCategory([]);
      loadCategory();
    }
  }, [isAddCategorySuccessful, isUpdateCategorySuccessful, isDeleteCategorySuccessful]);

    // DELETE CONFIRM CLOSE OR OPEN HANDLER
    const openDeleteConfirm = async (open, cancel) => {
        if (open) {
          setIsDeleteConfirmation(true);
        } else {
          setIsDeleteConfirmation(false);
          if (!cancel) {
            setIsDeleteCategorySuccessful(true);
          }
        }
      };
  return (
    <div>
        {isDeleteConfirmation&&
        <DeleteCategory
        closePopup={(close) => openDeleteConfirm(false, close)}
        category={category.filter((e) => e.action.id === deletingCategory)[0].action}
      />}
      {isFail && (
        <ApiResult
          message={errorMsg}
          handleClose={() => {
            setIsFail(false);
          }}
          isSuccess={false}
        ></ApiResult>
      )}
       {isAddCategorySuccessful && (
          <ApiResult
            message={`Category Added Succesfully`}
            handleClose={() => {
              setAddCategorySuccessful(false);
            }}
            isSuccess={true}
          ></ApiResult>
        )}
       {isDeleteCategorySuccessful && (
          <ApiResult
            message={`Category Deleted Succesfully`}
            handleClose={() => {
              setIsDeleteCategorySuccessful(false);
            }}
            isSuccess={true}
          ></ApiResult>
        )}
       {isUpdateCategorySuccessful && (
          <ApiResult
            message={`Category Updated Succesfully`}
            handleClose={() => {
              setIsUpdateCategorySuccessful(false);
            }}
            isSuccess={true}
          ></ApiResult>
        )}
  
        {isUpdateCategoryFormVisible && (
          <UpdateCategory
            closePopup={(close) => openUpdateForm(false, close)}
            category={updatingCategory}
          />
        )}
        {isAddCategoryFormVisible && (
          <AddNewCategory closePopup={(close) => openAddNewForm(false, close)} />
        )} 
      <MainBodyStructure
        isEmpty={isEmpty}
        title={"Categories"}
        subtitle={"Categories used to classify items."}
        buttons={[
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              openAddNewForm(true);
            }}
          >
            <AddIcon /> Add New Category
          </Button>,
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              // paddingLeft: "1rem",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              refreshData();
            }}
          >
            {<RefreshIcon />} REFRESH
          </Button>,
        ]}
        data={{
          header: header((categoryParam) => {
            setUpdatingCategory(categoryParam);

            openUpdateForm(true);
          }, 
          (categoryParam) => {
            console.log(categoryParam, "second function params")
            setDeletingCategory(categoryParam.id);

            openDeleteConfirm(true);
          }
          ),
          data: category,
        }}
        // data={usersz?usersz.length?{header:header, data:categoryCell(usersz)}: {header:header, data:[]}: {header:header, data:[]}}
      />
    </div>
  );
}

export default Category;

const header = (updateFunction, deleteFunction) => {
  return [
    {
      field: "id",
      headerName: "NO.",
      width: 300,
      headerClassName: "super-app-theme--header",
      // width: 75,
      align: "right",
      headerAlign: "right",
      flex: 0.7,
    },
    {
      field: "name",
      headerName: "NAME",
      headerClassName: "super-app-theme--header",
      // width:200,
      flex: 1,

      align: "left",
      padding: "10px",
      headerAlign: "center",
    }, 
    {
      field: "status",
      headerName: "STATUS",
      headerClassName: "super-app-theme--header",
      width: 150,

      align: "left",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <p style={{ color: params.value == "Suspended" ? "red" : "black" }}>
            {params.value}
          </p>
        </>
      ),
    },
    {
      field: "action",
      headerName: "ACTION",
      minWidth: 100,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            style={{
              color: "rgb(62, 62, 216)",
              marginRight: "10px",
              boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",

              padding: "0",
              width: "10px",
            }}
            onClick={() => {
              updateFunction(params.value);
            }}
          >
            <EditNoteOutlinedIcon />
          </Button>

          <Button
              style={{
                color: "coral",
                marginRight: "10px",
                boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",
  
                padding: "0",
                width: "10px",
              }}
              onClick={() => {
                deleteFunction(params.value)
              }}
            >
              <ClearOutlinedIcon />
            </Button>
        </>
      ),
    },
  ];
};

const categoryCell = (category) => {
  const newCategoryModel = [];
  category.map((e) => {
    newCategoryModel.push({
      id: category.indexOf(e) + 1,
      name: e.name, 
      status: getStatus[e.status],
      action: e,
    });
  });
  return newCategoryModel;
};
