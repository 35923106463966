import { Branch } from "./Branch";

export class Company {
  private id: number;
  private name: string;
  private tin: string;
  private image: string;
  private opening_hour: string;
  private closing_hour: string;
  private primary_color: string;
  private secondary_color: string;
  private branches: Array<Branch>;
  private isSalesManaged: boolean; 
  private is_vat: boolean;
  private service_charge: number;

  constructor(
    id: number,
    name: string,
    tin: string,
    image: string,
    opening_hour: string,
    closing_hour: string,
    primary_color: string,
    secondary_color: string,
    branches: Array<Branch>,
    isSalesManaged:boolean, 
    is_vat:boolean,
    service_charrge:number
  ) {
    this.id = id;
    this.name = name;
    this.tin = tin;
    this.image = image;
    this.opening_hour = opening_hour;
    this.closing_hour = closing_hour;
    this.primary_color=primary_color;
    this.secondary_color=secondary_color;
    this.branches = branches;
    this.isSalesManaged = isSalesManaged; 
    this.is_vat = is_vat
    this.service_charge = service_charrge

  }
}
