import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { useSelector } from "react-redux";
import UpdateTelergramIntegrationNumber from "./UpdateTelergramIntegrationNumber";
import { Button } from "@mui/material";
import ApiResult from "../Widgets/ApiResult";

function TelegramIntegration() {
  const [integrationInfo, setIntegrationInfo] = useState([]);
  const [isUpdateFormVisible, setIsUpdateFormVisible] = useState(false);
  const [isSucces, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const token = useSelector((state) => state.token);
  const [updatingBranch, setUpdatingBranch] = useState(null);
  const [isManager, setIsManager]=useState(null)

  const getTelegramIntegration = async () => {
    await axios
      .get("/api/get/telegram-integration", {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        withCredentials: true,
      })
      .then((res) => {
        console.log(res);

        setIntegrationInfo(res.data.msg);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getTelegramIntegration();
  }, []);

  useEffect(() => {
    if (isSucces) {
      getTelegramIntegration();
    }
  }, [isSucces]);
  return (
    <div>
      {isSucces && (
        <ApiResult
          message={"Telegram Info Updated"}
          handleClose={() => {
            setIsSuccess(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isUpdateFormVisible && updatingBranch && (
        <UpdateTelergramIntegrationNumber
          id={updatingBranch}
          isManager={isManager}
          close={(closed) => {
            if (closed) {
              setIsUpdateFormVisible(false);
            } else {
              setIsSuccess(true);
              setIsUpdateFormVisible(false);
            }
          }}
        />
      )}
      <div className="">
        <div
          className="top-stick-section flex justify-between p-8 sticky bg-white z-10 w-full border"
          style={{
            borderBottom: "3px solid  rgb(247, 246, 246)",
            height: "7rem",
          }}
        >
          <div className="screen-title">
            <h4 className="text-black font-bold text-xl">
              Telegram Integration
            </h4>
            <p>Order handling telegram accounts for each branches</p>
          </div>
        </div>
        <div
          className=" overflow-auto items-start p-4 flex flex-wrap gap-2 justify-start"
          style={{ maxHeight: "calc(100dvh - 7rem)" }}
        >
          {integrationInfo?.map((x) => {
            return (
              <div className="flex flex-col gap-2 border border-black-300 rounded-sm p-4">
                <p className="font-bold">{x.name}</p>
                <div className="w-80 flex  justify-between items-center ">
                  <p className="">Manager</p>-
                  <p className="mx-2 font-normal italic">
                    {x.integration
                      ? x.integration?.manager_phone
                        ? x.integration?.manager_phone
                        : "Empty"
                      : "Empty"}
                  </p>
                  <Button
                    style={{
                      background: "rgb(62, 62, 216)",
                      color: "white",
                      // marginRight: "10px",
                      paddingRight: ".8rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                    }}
                    onClick={() => {
                      setIsManager(true);
                      setUpdatingBranch(x.id);
                      setIsUpdateFormVisible(true);
                    }}
                  >
                    Update
                  </Button>
                </div>
                <div className="w-80 flex justify-between items-center">
                  <p className="">Cashier</p>-
                  <p className="mx-2 font-normal italic">
                    {x.integration
                      ? x.integration?.cashier_phone
                        ? x.integration?.cashier_phone
                        : "Empty"
                      : "Empty"}
                  </p>
                  <Button
                    style={{
                      background: "rgb(62, 62, 216)",
                      color: "white",
                      // marginRight: "10px",
                      paddingRight: ".8rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                    }}
                    onClick={() => {
                      setIsManager(false);
                      setUpdatingBranch(x.id);
                      setIsUpdateFormVisible(true);
                    }}
                  >
                    Update
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TelegramIntegration;
