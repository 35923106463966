import React, { useEffect, useState } from "react";
import MainBodyStructure from "../../../Components/MainBody/MainBodyStructure.jsx";
import { Companies } from "../../../Models/Companies";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import AddNewCompany from "./AddNewCompany";
import ApiResult from "../../Widgets/ApiResult";
import { fetch_companies } from "../../../Services/Admin/admin_services";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import UpdateCompanyProfile from "./UpdateCompanyProfile";
import DeleteCompany from "./DeleteCompany";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ListShorter from "Components/ListShorter/ListShorter.jsx";

function CompaniesPage() {
  const [companies, setCompanies] = useState([]);
  const token = useSelector((state) => state.token);
  const [companiesData, setCompaniesData] = useState([]);
  const [isAddFormVisisble, setIsAddFormVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isUpdateCompanySuccess, setIsUpdateCompanySuccess] = useState(false);
  const [isDeleteCompanySuccess, setIsDeleteCompanySuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong");
  const [isReloading, setIsReLoading] = useState(false);
  const [isUpdateFormVisible, setIsUpdateFormVisible] = useState(false);

  const [updatingId, setUpdatingId] = useState("");
  const [deletingId, setDeletingId] = useState("");
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

  const [offset, setOffset] = useState(0);

  const user = useSelector((state) => state.user);
  const [isEmpty, setIsEmpty] = useState(false);
  //LOAD COMPANIES FROM STATE OR API
  const loadCompanies = async () => {
    try {
      setIsEmpty(false);
      const updated_companies = await fetch_companies(
        token,
        offset,
        user.role == 3
      );
      if (!updated_companies.length) {
        setIsEmpty(true);
      }
      const data = companyCell(updated_companies);

      setCompaniesData(data);
      setCompanies(updated_companies);
    } catch (e) {
      console.log(e,44)
      // setCompaniesData(e);
      setCompaniesData([]);
      if (e.response && e.response.request && e.response.request.statusText)
        setErrorMsg(e.response.request.statusText);
      else setErrorMsg("Can not connect!");
      setIsFail(true);
    }
  };
  // SET OFFSET
  const handleSetOffset = (up) => {
    setIsReLoading(true);
    if (up) setOffset(offset + 1);
    else setOffset(offset - 1);
  };

  //RELOAD TABLE DATA FROM API
  const reloadCompanies = async () => {
    setCompaniesData([]);
    loadCompanies();
  };
  useEffect(() => {
    if (isReloading) {
      setCompaniesData([]);
      loadCompanies();
      setIsReLoading(false);
    }
  }, [isReloading]);

  // GET COMPANIES AT START UP
  useEffect(() => {
    loadCompanies();
  }, []);

  // ADD FORM CLOSE OR OPEN HANDLER
  const openForm = async (open, cancel) => {
    if (open) {
      setIsAddFormVisible(true);
    } else {
      setIsAddFormVisible(false);
      if (!cancel) {
        setIsSuccess(true);
      }
    }
  };
  // UPDATE FORM CLOSE OR OPEN HANDLER
  const openUpdateForm = async (open, cancel) => {
    if (open) {
      setIsUpdateFormVisible(true);
    } else {
      setIsUpdateFormVisible(false);
      if (!cancel) {
        setIsUpdateCompanySuccess(true);
      }
    }
  };
  // DELETE CONFIRM CLOSE OR OPEN HANDLER
  const openDeleteConfirm = async (open, cancel) => {
    if (open) {
      setIsDeleteConfirmation(true);
    } else {
      setIsDeleteConfirmation(false);
      if (!cancel) {
        setIsDeleteCompanySuccess(true);
      }
    }
  };

  const openSuccessMsg = (open) => {
    if (open) {
      setIsSuccess(true);
    } else {
      setIsSuccess(false);
    }
  };
  useEffect(() => {
    if (isSuccess || isDeleteCompanySuccess || isUpdateCompanySuccess) {
      setCompaniesData([]);
      loadCompanies();
    }
  }, [isSuccess, isDeleteCompanySuccess, isUpdateCompanySuccess]);

  return (
    <div>
      {isDeleteConfirmation && (
        <DeleteCompany
          closePopup={(close) => openDeleteConfirm(false, close)}
          companyId={deletingId}
          companyName={companies.filter((e) => e.id === deletingId)[0].name}
        />
      )}
      {isUpdateFormVisible && (
        <UpdateCompanyProfile
          isSalesManaged={companies.filter((e) => e.id === updatingId)[0].isSalesManaged}
          closePopup={(close) => openUpdateForm(false, close)}
          id={updatingId}
          name_p={companies.filter((e) => e.id === updatingId)[0].name}
          tin_p={companies.filter((e) => e.id === updatingId)[0].tin}
          open_p={companies.filter((e) => e.id === updatingId)[0].opening_hour}
          close_p={companies.filter((e) => e.id === updatingId)[0].closing_hour}
          primary_color={
            companies.filter((e) => e.id === updatingId)[0].primary_color
          }
          secondary_color={
            companies.filter((e) => e.id === updatingId)[0].secondary_color
          }
          image_fieldValues={
            companies.filter((e) => e.id === updatingId)[0].image
          }
          image_preview={companies.filter((e) => e.id === updatingId)[0].image}
          isPrinting={companies.filter((e) => e.id === updatingId)[0].branches[0]?.isPrinting}
          is_vat={companies.filter((e) => e.id === updatingId)[0].is_vat}
          service_charge={companies.filter((e) => e.id === updatingId)[0].service_charge}
        />
      )}
      {isSuccess && (
        <ApiResult
          message={`Company Added Succesfully`}
          handleClose={() => {
            openSuccessMsg(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isUpdateCompanySuccess && (
        <ApiResult
          message={`Company Updated Succesfully`}
          handleClose={() => {
            setIsUpdateCompanySuccess(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isDeleteCompanySuccess && (
        <ApiResult
          message={`Company Deleted Succesfully`}
          handleClose={() => {
            setIsDeleteCompanySuccess(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isFail && (
        <ApiResult
          message={errorMsg}
          handleClose={() => {
            setIsFail(false);
          }}
          isSuccess={false}
        ></ApiResult>
      )}
      {isAddFormVisisble && (
        <AddNewCompany closePopup={(close) => openForm(false, close)} />
      )}
      <MainBodyStructure
        isEmpty={isEmpty}
        handleSetOffset={(b) => handleSetOffset(b)}
        offset={offset}
        title={"Companies"}
        buttons={[
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              openForm(true);
            }}
          >
            <AddIcon /> Add new company
          </Button>,
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              // paddingLeft: "1rem",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              reloadCompanies();
            }}
          >
            {<RefreshIcon />} REFRESH
          </Button>,
        ]}
        data={{
          header: header([
            () => {
              setIsUpdateFormVisible(true);
            },
            (id) => {
              setUpdatingId(id);
            },
            () => {
              setIsDeleteConfirmation(true);
            },
            (id) => {
              setDeletingId(id);
            },
          ], user.role==3),
          data: companiesData,
        }}
        subtitle={"Currently registered companies."}
      />
    </div>
  );
}

export default CompaniesPage;

const header = (buttonFunctions, isSales) => {
  return [
    {
      field: "id",
      headerName: "NO.",
      width: 300,
      headerClassName: "super-app-theme--header",
      minWidth: 75,
      align: "right",
      headerAlign: "right",
      flex: 0.7,
    },

    {
      field: "Image",
      headerName: " ",
      headerClassName: "super-app-theme--header",
      width: 150,

      align: "center",
      padding: "10px",
      headerAlign: "center",
      renderCell: (params) => (
        <img
          className="table-image"
          //   src={e.image}
          src={
            String(params.value) != ""
              ? params.value
              : "https://res.cloudinary.com/dhe0yelid/image/upload/v1692110703/anbessa_8e7b499497.png"
          }
          alt=""
        />
      ),
    },
    {
      field: "name",
      headerName: "COMPANY NAME",
      minWidth: 300,
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "tin",
      headerName: "TIN",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "branches",
      headerName: "BRANCHES",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          {/* <ul>
            {params.value.map(e=> <li key={e.id}>
               <a> {e.name}</a>
            </li>)}
          </ul> */}

          {params.value && (
            <ListShorter
              list={params.value.branches}
              company={params.value.id}
              show={1}
            />
          )}
        </>
      ),
    },
    // {
    //   field: "opening",
    //   headerName: "OPENING HOUR",
    //   width: 150,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "closing",
      headerName: "CLOSING - OPENING",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 250,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            style={{
              // border: "2px solid rgb(62, 62, 216)",
              color: "rgb(62, 62, 216)",
              marginRight: "10px",
              boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",
              padding: "0",
              width: "10px",
            }}
            onClick={() => {
              buttonFunctions[1](params.value);
              buttonFunctions[0]();
            }}
          >
            <EditNoteOutlinedIcon />
          </Button>
          
          {!isSales&&
            <Button
            style={{
              // border: "2px solid coral",
              color: "coral",
              // boxShadow: "2px 3px 2px 0px rgba(128, 126, 126, 0.4)",
              boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",

              padding: "0",
              width: "10px !important",
            }}
            onClick={() => {
              buttonFunctions[3](params.value);
              buttonFunctions[2]();
            }}
          >
            <ClearOutlinedIcon />
          </Button>}
        </>
      ),
    },
  ];
};

const companyCell = (companies) => {
  var companies_model = [];

  if (companies && companies.length) {
    companies.map((e) => {
      companies_model.push({
        id: companies.indexOf(e) + 1,
        Image: e.image,

        name: e.name,
        tin: e.tin,

        branches: e,
        closing: `${e.opening_hour} - ${e.closing_hour}`,
        action: e.id,
      });
    });
  }
  return companies_model;
};
