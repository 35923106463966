import React, { useState } from "react";
import ItemCard from "../ItemCard";
import { Container } from "@mui/material";
import ItemPopup from "../Widgets/ItemPopup";

function MenuTab({ items }) {
  const [isItemPopUpVisible, setIsItemPopupVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [isOpen, setIsOpen]=useState(false)
  console.log(isItemPopUpVisible)
  return (<>
    {
    (isItemPopUpVisible && selectedItem) &&
     <ItemPopup isOpen={isItemPopUpVisible} item={selectedItem} handleOnClose={()=>setIsItemPopupVisible(false)} />}
    <Container >
      <div className="menu">
        {items.length &&
          items.map((e) => (
            <ItemCard
              name={e.item_name}
              price={Math.min.apply(Math, e.variations.map(f => f.price))}
              image={e.image} 
              onClick={() =>{ 
                setSelectedItem(e)
                setIsItemPopupVisible(true)}} 
            />
          ))} 
      </div>
    </Container>
  </>
  );
}

export default MenuTab;
