import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { URLs } from "../../api/URLs";

function ListShorter({ list, show, company}) {
  const [hidden, setHidden] = useState(true);
  const [real, setReal] = useState([...list]);
  // console.log(list)

  return (
    <div>
      {hidden ? (
        <>
          {[...real].splice(0, show).map((e) => (
            <>
               <a
                target="blank"
                href={`${URLs.MENU_ADDRESS}/menu?company=${company}&amp;branch=${e.id}`}
                style={{
                  color: "#4C9818",
                  fontStyle: "italic",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                {e.name}
                {real.length > 1 && <> . . . </>}
              </a>
            </>
          ))}
          {real.length > 1 && (
            <p
              style={{
                color: "black",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                setHidden(false);
              }}
            >
              See More
            </p>
          )}
        </>
      ) : (
        <>
          {[...real].map((e) => (
            <>
              <a
                target="blank"
                href={`${URLs.MENU_ADDRESS}/menu?company=${company}&amp;branch=${e.id}`}
                style={{
                  color: "#4C9818",
                  fontStyle: "italic",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                {e.name}
              </a>
              <br />
              <br />
            </>
          ))}
          <p
            style={{
              color: "black",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setHidden(true);
            }}
          >
            Show less
          </p>
        </>
      )}
    </div>
  );
}

export default ListShorter;
