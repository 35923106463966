import { add_company_category} from '../../../Services/Manager/manager_services';
import Loading from '../../Widgets/Loading';
import ApiResult from '../../Widgets/ApiResult';
import React, { useState } from "react";
import Popup from "../../../Components/Popups/Popup";
import CustomTextfield from "../../Widgets/CustomTextfield";
import CustomForm from "../../../Components/CustomForm/CustomForm";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { add_new_service } from '../../../Services/Admin/admin_services';

function AddNewService({closePopup}) {
    const token = useSelector((state) => state.token);

    const [name, setName] = useState("");
    const [locationType, setLocationType] = useState("");
  
    const [isLoading, setIsLoading] = useState(false);
  
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("Something went wrong, try again!");
  
    const handelSubmit = async (e) => {
      e.preventDefault();
  
      try {
        setIsLoading(true);
  
        const param = {
          name: name,
          locationType:locationType
        };
        const res = await add_new_service(token, param);
        setIsLoading(false);
        closePopup(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
        if (!err?.response) {
          setErrorMsg("No Server Response");
        } else if (err.response?.status === 409 || err.response?.status === 401) {
          setErrorMsg(err.response?.data.msg);
        } else {
          setErrorMsg("Couldn't add branch, try again.");
  
        }
        setIsError(true);
       
      }
    };  
  
    return (
      <div>
          {isLoading && <Loading />}
        {isError && (
          <ApiResult
            message={`${errorMsg}`}
            handleClose={() => {
              setIsError(false);
            }}
            isSuccess={false}
          />
        )}
        <Popup title={"Add new service"}>
          <CustomForm
            handleSubmit={(e) => handelSubmit(e)}
  
            button={
              <div>
                <Button type="submit"
                disabled={
                  String(name).trim() == ""
                }
                >
                  <p>Add</p>
                </Button>
                <Button onClick={() => closePopup(true)}>
                  <p>Close</p>
                </Button>
              </div>
            }
          >
            <CustomTextfield
              label={"Name"}
              onChange={(e) => setName(e.target.value)}
              value={name}
              placeholder={"category name"}
              required={true}
              span
            />
            <CustomTextfield
              label={"Location Type"}
              onChange={(e) => setLocationType(e.target.value)}
              value={locationType}
              placeholder={"location type"}
              required={true}
              span
            />
             
          </CustomForm>
        </Popup>
      </div>
    );
}

export default AddNewService